// Libs
import { useSelector } from "react-redux";

// Utilities
import getPageFromId from "../utilities/get-page-from-id";

/**
 * @typedef {Object} Page
 * @property {number} dataId - the dataId of the current page
 *
 * @returns {Page}
 */
function useCurrentPage({ pageId }) {
  const pages = useSelector((state) => state.pages.pages);
  let page = getPageFromId(pages, pageId);
  if (!page) return {}; // will prevent crashed due to stuff like page.config being undefined

  return page;
}

export default useCurrentPage;
