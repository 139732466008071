import {
  ADD_FILE,
  BEGIN_UPLOAD_FILE,
  REMOVE_MEDIA,
  RESET_MEDIA_UPLOAD,
  SET_FILE_UPLOAD_PROGRESS_CALLBACK,
  SET_MEDIA_INDEX,
  SET_MEDIA_TOKEN,
  SET_MEDIA_UPLOAD_PROGRESS,
  SET_MEDIA_URL,
  SET_MEDIA_CONTEXT,
  UPLOAD_FILE_SUCCESS,
} from "../actions/actionTypes";
import PQueue from "p-queue";

/**
 * @typedef MediaUploadFile
 * @property {'pending' | 'uploading' | 'uploaded' | 'pre-existing'} state
 * @property {File} file
 * @property {'image'|'video'} type
 *
 * @property {string} [signature] - Fetched from API
 * @property {object} [parameters] - Fetched from API
 * @property {string} [id] - Fetched from API
 *
 * @property {string} [url] - For pre-existing and uploaded files
 *
 * @property {AbortController} aborter - Cancel upload
 * @property {string} cld_delete_token - Delete token from Cloudinary
 */

/**
 * @typedef MediaUploadState
 * @property {MediaUploadFile[]} files
 * @property {string} token
 * @property {PQueue} queue
 */

/** @type MediaUploadState */
const initialState = {
  files: [],
  token: "",
  queue: new PQueue({
    concurrency: 3,
  }),
  context: "NEWS_POST",
};

/**
 * @param {MediaUploadState} state
 * @param {*} action
 * @returns {MediaUploadState}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_MEDIA_UPLOAD: {
      return { ...initialState };
    }
    case SET_MEDIA_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case ADD_FILE: {
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    }
    case REMOVE_MEDIA: {
      return {
        ...state,
        files: state.files.filter((file) => file.id !== action.payload),
      };
    }
    case BEGIN_UPLOAD_FILE: {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.payload) {
            return {
              ...file,
              state: "uploading",
            };
          }
          return file;
        }),
      };
    }
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.payload.id) {
            return {
              ...file,
              state: "uploaded",
              cld_delete_token: action.payload.cld_delete_token,
              url: `https://res.cloudinary.com/toecho/${file.type}/upload/${action.payload.id}.${
                file.type === "image" ? "jpg" : "mp4"
              }`,
            };
          }
          return file;
        }),
      };
    }
    case SET_MEDIA_URL: {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.payload.id) {
            return {
              ...file,
              url: action.payload.url,
            };
          }
          return file;
        }),
      };
    }
    case SET_MEDIA_INDEX: {
      const files = state.files.filter((file) => file.id !== action.payload.file.id);

      files.splice(action.payload.index, 0, action.payload.file);

      return {
        ...state,
        files,
      };
    }
    case SET_MEDIA_UPLOAD_PROGRESS: {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.payload.id) {
            return {
              ...file,
              progress: action.payload.progress,
            };
          }
          return file;
        }),
      };
    }
    case SET_MEDIA_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
