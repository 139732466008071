import React from "react";
import SearchPageBreadcrumb from "../SeachPageBreadcrumb";
import { ChevronRightIcon } from "mdi-react";
import pageNavigatorV2 from "../../../../utilities/page-navigator-v2";
import linkPathCreator from "../../../../utilities/get-link-path-from-page-object";
import pageTransitions from "../../../../config/page-transitions";
import { useSelector } from "react-redux";
import { getExcerptAndHighlightMatch } from "../utils/getExcerptAndHightlightMatch";

const SearchResultsRegistrationForms = ({ result }) => {
  const { data, page } = result;
  const content = result.data.content;

  const { searchTerm } = useSelector((s) => s.searchPage);

  return (
    <div
      className={`list-element`}
      key={`div__registration-form__${result.data.id}`}
      onClick={() => pageNavigatorV2({ path: `${linkPathCreator(page, "absolute")}`, direction: pageTransitions.forward })}
    >
      <div style={{ display: "flex", marginBottom: "0.35rem" }}>
        <SearchPageBreadcrumb page={page} key={`breadcrumb__registration-form__${data.id}}`} />
      </div>

      <p
        className="title"
        dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content: result.data.title, searchTerm })}
      />

      <div className="content" dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content, searchTerm })} />

      <ChevronRightIcon className="navigation-arrow-indicator" />
    </div>
  );
};

export default SearchResultsRegistrationForms;
