// Config
import { NAVIGATION_GRID, NAVIGATION_LIST, CHAT_NAVIGATION_LIST } from "../config/moduleTypes";

/**
 *
 * @param {Array} pages
 * @param {Object} pageToBeMoved
 * @param {Object} destinationParentPage
 * @returns {Array}
 *
 * The function let's you add a selected page to a parent page.
 *
 */

function addPageToParentPage({ pages, pageToBeMoved, destinationParentPage }) {
  if (![NAVIGATION_LIST, NAVIGATION_GRID, CHAT_NAVIGATION_LIST].includes(destinationParentPage.module.trim())) {
    console.warn("destinationParentPage is a folder type page (No changes was made)");
    return pages;
  }

  const newPages = [...pages];

  for (let i = 0; i < newPages.length; i++) {
    if (newPages[i].id === destinationParentPage.id) {
      pageToBeMoved.parentId = destinationParentPage.id;

      if ((Array.isArray(newPages[i].pages) && newPages[i].pages.length === 0) || !Array.isArray(newPages[i].pages)) {
        pageToBeMoved.sortOrder = 0;
        pageToBeMoved.flags.modified = true;
        newPages[i].pages = [pageToBeMoved];
      } else {
        pageToBeMoved.sortOrder = newPages[i].pages.length + 1;
        pageToBeMoved.flags.modified = true;
        newPages[i].pages = [...newPages[i].pages, pageToBeMoved];
      }
    }

    if (Array.isArray(newPages[i].pages) && newPages[i].pages.length > 0) {
      newPages[i].pages = addPageToParentPage({ pages: newPages[i].pages, pageToBeMoved, destinationParentPage });
    }
  }

  return newPages;
}

export default addPageToParentPage;
