import React from "react";
import regexEscape from "./regex-escape";
import { escapeRegExp } from "lodash-es";

const modes = {
  jsx: "JSX",
  markdown: "MARKDOWN",
};

export { modes };

/**
 * Utility function for highlighting matching words in a string
 * ---------------------
 * Arguments
 * @param {string} searchstring Searchstring in which to find and highlight matches
 * @param {string} searchterme term to highlight
 * @param {string} mode the serach mode. Defaults to JSX
 * @param {Object} style style to apply to the matched element
 */
export default (searchstring, searchterm, mode = modes.jsx, style = { fontWeight: 700 }, outerStyle = {}, key = "") => {
  searchstring = String(searchstring);
  searchterm = String(searchterm);

  if (!searchstring || !searchterm) return makeEarlyReturn();

  /* If jsx mode is selected, we can't use html elements in the string
  / therefore we need to return jsx-elements in the right order,
  / with the matching string wrapped in a span element.
  / The reason for this is because we use two different styles one for the outer style and one for the inner style
  */

  const regex = new RegExp(regexEscape(searchterm), "gmi");

  if (mode === modes.jsx) {
    const match = [...searchstring.matchAll(regex)];

    if (!match || match.length < 1) return makeEarlyReturn();

    let matchArray = match.map((element) => {
      const matchStart = element.index;
      const matchEnd = matchStart + searchterm.length;

      if (matchStart === 0 && matchEnd === 0) return makeEarlyReturn();

      return {
        matchStart,
        matchEnd,
      };
    });

    let indexPointer;
    // Return jsx-element (no XSS-vulnabilities since html in the strings would never be parsed)
    return (
      <span key={key} style={outerStyle}>
        {matchArray.map((match, index) => {
          let stringAtMatch = searchstring.slice(match.matchStart, match.matchEnd);

          // Here we take the string before the match and put in the searchterm after, to complete the string.
          let matchString = "";
          if (indexPointer === 0) {
            matchString = searchstring.slice(0, match.matchStart);
            indexPointer = match.matchEnd;
          } else {
            matchString = searchstring.slice(indexPointer, match.matchStart);
            indexPointer = match.matchEnd;
          }

          return (
            <>
              {matchString}
              <span className="match" style={style}>
                {stringAtMatch}
              </span>
              {index === matchArray.length - 1 && searchstring.slice(match.matchEnd)}
            </>
          );
        })}
      </span>
    );
  }
  // If markdown mode is selected, we can use html elemnts in the string
  if (mode === modes.markdown) {
    let matchString = "";

    matchString = replace(searchstring, searchterm, `<span class="match">${searchterm}</span>`);

    return matchString;
  }

  function replace(target, string_to_replace, replacement) {
    let regex = new RegExp(escapeRegExp(string_to_replace), "gmi");
    return target.replace(regex, replacement);
  }

  function makeEarlyReturn() {
    if (mode === modes.jsx) {
      return (
        <span key={key} style={outerStyle}>
          {searchstring}
        </span>
      );
    }

    if (mode === modes.markdown) {
      return searchstring;
    }
  }
};
