import { css } from "emotion";
import { useSelector } from "react-redux";
import InlineSpinner from "../../ui/InlineSpinner";

/**
 * @param {object} props
 * @param {boolean} props.isCheckedIn
 * @param {boolean} props.isImpersonating
 */
function CheckInStatus({ isCheckedIn, isImpersonating = false }) {
  const { isLoading, hasError } = useSelector((state) => state.checkIn.currentCheckIn);

  return (
    <div data-test-id={`status-element-${isCheckedIn ? "on" : "off"}`} className={componentStyle()}>
      <div className={`status-element ${isCheckedIn ? "checked-in" : "checked-out"} ${isLoading ? "loading" : ""}`}>
        {isLoading ? (
          <>
            <InlineSpinner size="16" style={{ marginBottom: "-8px", marginRight: "0.5rem" }} />
            <p>Tjekket status...</p>
          </>
        ) : (
          <>
            <div className="status-dot" />
            {isImpersonating ? (
              <p>{isCheckedIn ? "Brugeren er tjekket ind" : "Brugeren er tjekket ud"} </p>
            ) : (
              <p>{isCheckedIn ? "Du er tjekket ind" : "Du er tjekket ud"} </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const componentStyle = () => css`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @keyframes blink {
    0% {
      opacity: 1;
    }

    40% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    54% {
      opacity: 1;
    }
  }

  .status-element {
    display: inline-flex;
    align-items: center;
    padding: 0.35rem 1.2rem;
    border: 1px solid var(--midGrey);
    border-radius: 1.5rem;

    &.checked-in {
      .status-dot {
        background-color: var(--green);
      }
      p {
        color: var(--green);
      }
    }

    &.checked-out {
      .status-dot {
        background-color: var(--red);
      }
      p {
        color: var(--red);
      }
    }

    &.loading {
      p {
        color: var(--darkGrey);
      }
    }

    .status-dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 10px;
      animation: blink infinite 5s;
    }

    p {
      line-height: 1.5rem;
      font-weight: 700;
    }
  }
`;

export default CheckInStatus;
