import { css } from "emotion";
import { ChevronDownIcon, ChevronUpIcon } from "mdi-react";

function SortingArrow({ sorting, style }) {
  return (
    <div className={`${componentStyle()} sorting-${sorting} `} style={style}>
      <ChevronUpIcon className="chevron-up" />
      <ChevronDownIcon className="chevron-down" />
    </div>
  );
}

const componentStyle = () => css`
  width: 20px;
  height: 26px;
  padding: 0;
  flex-grow: 0;
  display: block;
  position: relative;

  svg {
    opacity: 0.2;
    width: 17px;
    height: 17px;
    position: absolute;
    color: var(--black);
    transition: 200ms ease opacity;
  }
  .chevron-up {
    top: 0;
    left: 0;
  }
  .chevron-down {
    top: 10px;
    left: 0;
  }

  &.sorting-asc {
    .chevron-up {
      opacity: 1;
      color: var(--primary-color);
    }
  }
  &.sorting-desc {
    .chevron-down {
      opacity: 1;
      color: var(--primary-color);
    }
  }
`;

export default SortingArrow;
