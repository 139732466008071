import { EMPTY_TILE } from "../../../../config/moduleTypes";

import {
  chatCheck,
  customGroupCheck,
  defaultCheck,
  externalLinkCheck,
  newsCheck,
  registrationsCheck,
} from "./useDisableCreatePageButton.utils";
import useApplicationLanguages from "../../../../../../hooks/useApplicationLanguages";

const UseDisableCreatePageButton = () => {
  const { applicationLanguages } = useApplicationLanguages();

  const disableButton = (page) => {
    if ([EMPTY_TILE].includes(page.module)) return false;

    return (
      defaultCheck({ page, applicationLanguages }) ||
      newsCheck(page) ||
      externalLinkCheck(page) ||
      registrationsCheck(page) ||
      customGroupCheck(page)
    );
  };

  return {
    disableButton,
  };
};

export default UseDisableCreatePageButton;
