import { css } from "emotion";
import DynamicIcon from "../Icons/DynamicIcon";
import MediaFileUploader from "./MediaFileUploader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken, queueFile } from "../../../actions/mediaUploadActions";
import { addToast } from "../../../actions/uiActions";

const MediaUpload = ({ limits, token }) => {
  const dispatch = useDispatch();

  /** @type { import("../../../reducers/mediaUploadReducer").MediaUploadFile[] } */
  const media = useSelector((state) => state.mediaUpload.files);
  const appConfig = useSelector((state) => state.appConfig);

  const lang = useSelector((state) => state.language.language);

  const onUpload = (e) => {
    /** @type { File[] } */
    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    // Check if any of the files are too big
    const tooBig = files.some((file) => file.size > appConfig.mediaMaxFileSize);

    if (tooBig) {
      dispatch(
        addToast({
          title: lang.errorCouldNotUploadFile,
          content: lang.errorASelectedFileWasTooBig,
          styleType: "error",
        })
      );
      return;
    }

    let imagesCount =
      media.filter((media) => media.file.type.startsWith("image/")).length +
      files.filter((file) => file.type.startsWith("image/")).length;

    let videosCount =
      media.filter((media) => media.file.type.startsWith("video/")).length +
      files.filter((file) => file.type.startsWith("video/")).length;

    if (imagesCount > limits.images) {
      dispatch(
        addToast({
          title: lang.errorCouldNotUploadFile,
          content: lang.youCanOnlyUpload__imagelimit__Images.replace("__imagelimit__", limits.images),
          styleType: "error",
        })
      );
      return;
    }

    if (videosCount > limits.videos) {
      dispatch(
        addToast({
          title: lang.errorCouldNotUploadFile,
          content: youCanOnlyUpload__videolimit__Videos.replace("__Videolimit__", limits.videos),
          styleType: "error",
        })
      );
      return;
    }

    files.forEach((file) => {
      dispatch(queueFile(file));
    });

    e.target.value = null;
  };

  return (
    <div className={componentStyle()}>
      <label className="button-group">
        <div className="icon">
          <DynamicIcon icon="image"></DynamicIcon>
        </div>
        <input type="file" onChange={onUpload} disabled={!token} multiple accept="image/*,video/*" />
      </label>
      {media.filter((media) => media.file.type.startsWith("video/")).length > 0 && <p className="label">{lang.videos}</p>}
      <div className="previews">
        {media
          .filter((media) => media.file.type.startsWith("video/"))
          .map((media) => {
            return <MediaFileUploader media={media} key={media.id} />;
          })}
      </div>
      {media.filter((media) => media.file.type.startsWith("image/")).length > 0 && <p className="label">{lang.images}</p>}
      <div className="previews">
        {media
          .filter((media) => media.file.type.startsWith("image/"))
          .map((media) => {
            return <MediaFileUploader media={media} key={media.id} />;
          })}
      </div>
    </div>
  );
};

const componentStyle = () => css`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 2px;

  .button-group {
    width: 100%;
    height: 40px;

    border: 1px solid var(--midGrey);
    border-radius: 4px;

    display: flex;
    align-items: center;

    font-size: 1rem;
    color: var(--darkGrey);

    background-color: var(--white);

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      border-right: 1px solid var(--midGrey);

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: var(--lightGrey);
    }

    input {
      display: none;
    }
  }

  .previews {
    margin-top: 8px;

    overflow-y: none;
    overflow-x: auto;

    /** Immediate Children */
    > * {
      margin-right: 8px;
    }
  }

  .label {
    margin-top: 1rem;
  }
`;

export default MediaUpload;
