// Libs
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { SwapHorizontalIcon, TrashIcon } from "mdi-react";

// Components
import TextInput from "../../../../components/ui/TextInput";
import RadioGroup from "../../../../components/ui/RadioGroup";
import ButtonRounded from "../../../../components/ui/ButtonRounded";
import ModuleTypeSelector from "./ui/ModuleTypeSelector";
import IconSelector from "./ui/IconSelector";
import NavigationContent from "./navigationContent/NavigationContent";
import InformationBox from "../../../../components/ui/InformationBox";
import TargetGroupSelector from "../../../../components/ui/TargetGroupSelector";
import NewsContent from "./newsContent/NewsContent";
import AnniversaryContent from "./anniversaryContent/AnniversaryContent.jsx";

// Config
import {
  NAVIGATION_GRID,
  NAVIGATION_LIST,
  NEWS_MODULES,
  EXTERNAL_LINK,
  REGISTRATION_MODULES,
  SOCIAL,
  LOCAL_SOCIAL,
  EVENTS,
  CHAT_NAVIGATION_LIST,
  IFRAME,
  CUSTOM_GROUP,
  NATIVE_APP,
  EMPTY_TILE,
  CHAT,
  HIGHSCORE,
  CONTACTS,
  ANNIVERSARY,
} from "../../config/moduleTypes";

// Context
import { PageBuilderContext } from "../../context/PageBuilderContext";

// Style
import { componentStyle } from "./PageContent.style.js";
import RegistrationOptions from "./pageContentsOptions/RegistrationOptions";
import IFrameContent from "./iframeContent/IFrameContent";
import SocialContent from "./socialContent/SocialContent";
import ExternalLinkContent from "./externalLinkContent/ExternalLinkContent";
import useDestroyPage from "../../hooks/useDestroyPage";
import Spinner from "../../../ui/InlineSpinner";
import CustomGroupContent from "./customGroupContent/CustomGroupContent";
import EventContent from "./eventContent/EventContent";
import NativeLinkContent from "./nativeLinkContent/NativeLinkContent";
import HighScoreContent from "./highScoreContent/HighScoreContent";
import ContactsContent from "./contactsContent/ContactsContent";

const PageContent = (props) => {
  const { deletePage, loading: destroyPageLoading } = useDestroyPage();
  const { page, isCreateMode = false, onChangeHandler = () => {}, movePage = () => {} } = props;

  const { title, title_fl, icon, module, moduleGroup, pages: childPages = [], parentId, active, showAtFrontPage } = page;

  const lang = useSelector((state) => state.language.language);

  const shouldDeleteButtonBeDisplayed = () => {
    return (
      !isCreateMode &&
      (![NAVIGATION_LIST, NAVIGATION_GRID, CHAT_NAVIGATION_LIST].includes(module) ||
        ([NAVIGATION_LIST, NAVIGATION_GRID, CHAT_NAVIGATION_LIST].includes(module) &&
          (!page.pages || (Array.isArray(page.pages) && page.pages.length === 0))))
    );
  };

  return (
    <PageBuilderContext.Consumer>
      {({ applicationLanguages }) => (
        <div className={`${componentStyle(isCreateMode)}`}>
          {![module].includes(EMPTY_TILE) && (
            <div className="field-container">
              <p className="title">{lang.pageTitle} *</p>
              <div className="field-wrapper">
                <label>{`${applicationLanguages?.primary?.name || ""} *`}</label>
                <TextInput data-test-id={"title-field"} name="title" onChange={onChangeHandler} value={title || ""} />
              </div>
              {applicationLanguages?.foreign?.name && (
                <div className="field-wrapper">
                  <label>{`${applicationLanguages?.foreign?.name || ""} *`}</label>
                  <TextInput
                    data-test-id={"title-fl-field"}
                    name="title_fl"
                    onChange={onChangeHandler}
                    value={title_fl || ""}
                  />
                </div>
              )}
            </div>
          )}

          {((!isCreateMode && moduleGroup) || isCreateMode) && (
            <div className="field-container">
              <p className="title">{`${lang.moduleType} *`}</p>
              {REGISTRATION_MODULES.includes(module) && (
                <InformationBox className={"information-box"} title={"Obs!"}>
                  {lang.pageBuilderRegistrationDisclaimer}
                </InformationBox>
              )}

              <ModuleTypeSelector
                isCreateMode={isCreateMode}
                usePlaceholder={true}
                className="module-type-selector"
                name="module"
                group={moduleGroup}
                value={module}
                onChange={onChangeHandler}
              />
            </div>
          )}

          {![module].includes(EMPTY_TILE) && (
            <div className="field-container">
              <p className="title">{lang.icon}</p>
              <p className="hint">
                <span>{lang.iconHelpText} </span>
                <span>
                  <a href="https://pictogrammers.com/library/mdi/" rel="noreferrer noopener" target="_blank">
                    https://pictogrammers.com/library/mdi/
                  </a>
                </span>
                <span> ({lang?.opensInANewTab?.toLowerCase()}).</span>
              </p>
              <IconSelector data-test-id={"icon-selector-field"} onChange={onChangeHandler} icon={icon} />
              <p className="hint">{lang.pageBuilderIconDisclaimer}</p>
            </div>
          )}

          <div className="field-container">
            <p className="title">{lang.active}</p>
            <RadioGroup
              onSelect={(value) => onChangeHandler({ target: { name: "active", value: value === 1 ? 1 : 0 } })}
              className="radio-group-selector"
              selectedValue={active ? 1 : 2}
              options={[
                { id: 1, title: lang.yes, value: 1 },
                { id: 2, title: lang.no, value: 0 },
              ]}
            />
          </div>

          <div className="field-container">
            <p className="title">{lang?.targetGroup}</p>
            <div className="field-wrapper">
              <label>{`${lang?.select}  ${lang?.targetGroup?.toLowerCase()}`}</label>
              <TargetGroupSelector
                noUsersSelectedOverwrite={`${lang.all} ${lang.users?.toLowerCase()}`}
                data-test-id={`page-builder__target-group-selector__${page.title}`}
                targetGroup={page.targetGroup}
                onTargetGroup={(targetGroup) => {
                  onChangeHandler({
                    target: {
                      name: "targetGroup",
                      value: {
                        ...targetGroup,
                        modified: true, // Flags this targetGroup to be updated when user presses "save changes"
                      },
                    },
                  });
                }}
              />
            </div>
          </div>

          {![EMPTY_TILE].includes(page.module) && (parentId === 0 || !parentId) && (
            <div className="field-container">
              <p className="title">{lang.showAtFrontpage}</p>
              <RadioGroup
                onSelect={(value) => onChangeHandler({ target: { name: "showAtFrontPage", value: value === 1 ? 1 : 0 } })}
                className="radio-group-selector"
                selectedValue={showAtFrontPage ? 1 : 2}
                options={[
                  { id: 1, title: lang.yes, value: 1 },
                  { id: 2, title: lang.no, value: 0 },
                ]}
              />
            </div>
          )}

          {!isCreateMode && (
            <ButtonRounded className="action-btn" style={{ marginTop: "1.5rem" }} secondary={true} onClick={movePage}>
              <SwapHorizontalIcon style={{ marginRight: "0.25rem" }} />
              <p>{lang.movePage}</p>
            </ButtonRounded>
          )}

          {/* Module specific content */}
          {[NAVIGATION_LIST, NAVIGATION_GRID, CHAT_NAVIGATION_LIST].includes(module) && (
            <NavigationContent
              onChange={onChangeHandler}
              applicationLanguages={applicationLanguages}
              page={page}
              isCreateMode={isCreateMode}
            />
          )}

          {REGISTRATION_MODULES.includes(module) && <RegistrationOptions onChange={onChangeHandler} page={page} />}
          {NEWS_MODULES.includes(module) && (
            <NewsContent onChange={onChangeHandler} page={page} isCreateMode={isCreateMode} />
          )}

          {[EVENTS].includes(module) && (
            <EventContent onChangeHandler={onChangeHandler} page={page} isCreateMode={isCreateMode} />
          )}

          {[EXTERNAL_LINK].includes(module) && <ExternalLinkContent page={page} onChangeHandler={onChangeHandler} />}
          {[SOCIAL, LOCAL_SOCIAL].includes(module) && <SocialContent page={page} onChangeHandler={onChangeHandler} />}
          {[IFRAME].includes(module) && <IFrameContent page={page} onChangeHandler={onChangeHandler} />}
          {[CUSTOM_GROUP].includes(module) && <CustomGroupContent page={page} onChangeHandler={onChangeHandler} />}
          {[NATIVE_APP].includes(module) && <NativeLinkContent page={page} onChangeHandler={onChangeHandler} />}
          {[HIGHSCORE].includes(module) && <HighScoreContent page={page} onChangeHandler={onChangeHandler} />}
          {[CONTACTS].includes(module) && <ContactsContent page={page} onChangeHandler={onChangeHandler} />}
          {[ANNIVERSARY].includes(module) && <AnniversaryContent page={page} onChangeHandler={onChangeHandler} />}

          {/* Destroy module */}
          {shouldDeleteButtonBeDisplayed() && (
            <ButtonRounded
              data-test-id={`destroy-page-btn-${title}`}
              disabled={destroyPageLoading}
              className="action-btn red"
              style={{ marginTop: "1.5rem" }}
              secondary={true}
              onClick={() => deletePage(page)}
            >
              {destroyPageLoading && <Spinner size={20} color={"var(--red)"} style={{ paddingTop: "0.5rem" }} />}
              {!destroyPageLoading && (
                <>
                  <TrashIcon style={{ marginRight: "0.25rem" }} />
                  <p>{lang?.deletePage}</p>
                </>
              )}
            </ButtonRounded>
          )}
        </div>
      )}
    </PageBuilderContext.Consumer>
  );
};

export default memo(PageContent);
