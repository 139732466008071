import { GET_USER_SYNC_LOG, GET_USER_SYNC_LOG_FAILURE, GET_USER_SYNC_LOG_SUCCESS } from "../actions/actionTypes";

const initialState = {
  log: {
    loading: false,
    error: {},
    items: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_SYNC_LOG:
      return {
        ...state,
        log: {
          ...state.log,
          loading: true,
          error: {},
        },
      };
    case GET_USER_SYNC_LOG_SUCCESS:
      return {
        ...state,
        log: {
          ...state.log,
          loading: false,
          items: action.payload.items,
        },
      };
    case GET_USER_SYNC_LOG_FAILURE:
      return {
        ...state,
        log: {
          ...state.log,
          loading: false,
          error: {
            status: action.payload.status,
          },
        },
      };
    default:
      return state;
  }
}
