import { useState } from "react";

export const useLocalStorage = (key, initialValue = {}) => {
  // State to store values
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (!key) return null;

      // Get from localstorage
      const item = window.localStorage.getItem(key);

      // Parse stored value else return initial value
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      console.log(err);
      return initialValue;
    }
  });

  // Return a wrapped version of the setter function that will store the new value to localStorage
  const setValue = (value) => {
    try {
      // Save to new state
      setStoredValue(value);
      // Save to localStorage
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      throw err;
    }
  };

  return [storedValue, setValue];
};
