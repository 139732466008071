import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HistoryEntry from "./HistoryEntry";
import requestUtility from "../../../../../utilities/request-utility";
import ButtonRounded from "../../../../ui/ButtonRounded";
import colors from "../../../../../style/colors";
import InformationBox from "../../../../ui/InformationBox";
import StatusBox from "../../../../ui/StatusBox";
import { InformationOutlineIcon } from "mdi-react";

function CheckInPageHistoryTab({}) {
  const { checkInModuleId } = useSelector((s) => s.checkIn);

  const [checkIns, setCheckIns] = useState({
    loading: false,
    error: false,
    data: [],
    fetchFromDate: dayjs().subtract(7, "days").format("YYYYMMDD"),
    fetchToDate: dayjs().format("YYYYMMDD"),
  });

  function getCheckIns() {
    setCheckIns((s) => ({ ...s, loading: true }));
    requestUtility()(
      `check-in-modules/${checkInModuleId}/check-ins?fromDate=${checkIns.fetchFromDate}&toDate=${checkIns.fetchToDate}`
    ).then(({ data }) =>
      setCheckIns((s) => ({
        ...s,
        loading: false,
        data: [...s.data, ...data],
        fetchFromDate: dayjs(checkIns.fetchFromDate, "YYYYMMDD").subtract(7, "days").format("YYYYMMDD"),
        fetchToDate: dayjs(checkIns.fetchToDate, "YYYYMMDD").subtract(7, "days").format("YYYYMMDD"),
      }))
    );
  }

  function refreshCheckIns() {
    setCheckIns((s) => ({ ...s, loading: true }));

    let toDate = dayjs().format("YYYYMMDD");
    requestUtility()(
      `check-in-modules/${checkInModuleId}/check-ins?fromDate=${checkIns.fetchFromDate}&toDate=${toDate}`
    ).then(({ data }) =>
      setCheckIns((s) => ({
        ...s,
        loading: false,
        data: data,
      }))
    );
  }

  useEffect(() => {
    getCheckIns();
  }, []);

  return (
    <>
      {checkIns.data.length === 0 && (
        <StatusBox
          icon={<InformationOutlineIcon />}
          title="Ingen indtjekninger fundet for denne periode"
          content="Måske skal du hente en uge mere?"
          style={{ margin: "3rem 0 5rem 0" }}
        />
      )}

      {checkIns.data.map((checkIn) => (
        // JSON.stringify(checkIn)
        <HistoryEntry checkIn={checkIn} refreshCheckIns={refreshCheckIns} key={`check-in-entry__${checkIn.id}`} />
      ))}

      <p className="meta" style={{ color: colors.darkGrey, textAlign: "center", margin: "1rem" }}>
        Viser indtjekninger til og med {dayjs(checkIns.fetchToDate, "YYYYMMDD").format("YYYY-MM-DD")}
      </p>

      <ButtonRounded
        style={{ margin: "1rem auto" }}
        secondary={true}
        active={checkIns.loading}
        onClick={!checkIns.loading ? getCheckIns : () => {}}
        data-test-id="check-in-history-load-more-button"
      >
        Hent en uge mere
      </ButtonRounded>
    </>
  );
}

export default CheckInPageHistoryTab;
