// Libs
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";
import { bindActionCreators } from "redux";
import { CSSTransition } from "react-transition-group";
import { useDebouncedCallback } from "use-debounce/lib";
import { css } from "emotion";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import ActionWrapper from "../ui/ActionWrapper";
import RegistrationPosts from "./RegistrationPosts";
import RegistrationMostLikedPosts from "./RegistrationMostLikedPosts";
import RegistrationFormBuilder from "./RegistrationFormBuilder";
import FloatingSearchInput from "../ui/FloatingSearchInput";

// Styles
import { PlusIcon } from "mdi-react";

// Utilities and config
import { feedTypes } from "./config";
import getPageFromId from "../../utilities/get-page-from-id";
import getRegistrationLayout from "../../utilities/get-registration-layout";
import { durations } from "../../config/animations";

// Actions
import { getRegistrationPosts, resetAll, resetFeed, getRegistrationConfiguration } from "../../actions/registrationActions";
import { addToast, hideDialog, hideModalPage, showDialog, showModalPage } from "../../actions/uiActions";

// Hooks
import usePostRenderEffect from "../../hooks/usePostRenderEffect";

const RegistrationOverview = (props) => {
  const dispatch = useDispatch();

  // Page for navigation
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);

  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const registrationConfig = useSelector((state) => state.registration.registrationConfig);
  const LAYOUT_MODE = getRegistrationLayout({ path: match.path });

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // UI Layout
  const [tabs] = useState([lang.new, lang.mostHelpful, lang.mine]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  // Get the registrationId / dataId
  let { dataId: registrationId } = queryString.parse(window.location.search);
  let { registrationTitle: pageOverrideFromQueryParams } = queryString.parse(window.location.search);

  const { all, mostLiked, mine } = useSelector((state) => state.registration);

  // debounce call for searchterm
  const [debouncedGetPosts] = useDebouncedCallback(
    ({ searchTerm, feedType }) =>
      dispatch(
        getRegistrationPosts({
          registrationId,
          feedType: feedType,
          searchTerm: searchTerm,
        })
      ),
    500
  );

  // when seachterm changes update registrations
  usePostRenderEffect(() => {
    let currentFeedType = [feedTypes.all, feedTypes.mostLiked, feedTypes.mine][activeTab];
    dispatch(resetAll());
    debouncedGetPosts({ searchTerm, feedType: currentFeedType });
  }, [searchTerm]);

  function getPostsByActiveTab(activeTab) {
    if (activeTab === 0) props.getRegistrationPosts({ registrationId, feedType: feedTypes.all, searchTerm });
    if (activeTab === 1) props.getRegistrationPosts({ registrationId, feedType: feedTypes.mostLiked, searchTerm });
    if (activeTab === 2) props.getRegistrationPosts({ registrationId, feedType: feedTypes.mine, searchTerm });
  }

  function getPostsLengthByActiveTab(activeTab) {
    if (activeTab === 0) return all.posts.length;
    if (activeTab === 1) return mostLiked.posts.length;
    if (activeTab === 2) return mine.posts.length;
    return 0;
  }

  // Delete the saved registration
  const deleteLocaleRegistration = () => {
    try {
      localStorage.removeItem(`registration-fields-${registrationId}`);
      localStorage.removeItem(`registration-images-${registrationId}`);
      localStorage.removeItem(`registration-description-${registrationId}`);
      localStorage.removeItem(`registration-title-${registrationId}`);
      localStorage.removeItem(`registration-completedText-${registrationId}`);
    } catch (err) {
      throw err;
    }
  };

  const isThereARegistrationFormSaved = () => {
    try {
      let registrationFields = localStorage.getItem(`registration-fields-${registrationId}`);
      registrationFields = JSON.parse(registrationFields);
      if (Array.isArray(registrationFields) && registrationFields.length > 0) {
        setTimeout(() => {
          props.showDialog({
            allowClosing: false,
            title: lang.hello,
            content: lang.continueFromLastCheckDescription,
            primaryActionTitle: lang.yesContinueFromLastCheck,
            primaryAction: () => {
              createNewRegistrationClick(true);
            },
            secondaryAction: () => {
              createNewRegistrationClick(false);
              props.hideDialog();
              deleteLocaleRegistration();
            },
            secondaryActionTitle: lang.createNewForm,
          });
        }, 0);
      } else {
        createNewRegistrationClick(false);
      }
    } catch (err) {
      throw err;
    }
  };

  function refreshContent() {
    let currentFeedType = [feedTypes.all, feedTypes.mostLiked, feedTypes.mine][activeTab];

    dispatch(
      getRegistrationPosts({
        registrationId,
        feedType: currentFeedType,
        offset: 0,
        searchTerm,
      })
    );
  }

  function createNewRegistrationClick(useLocalStorage) {
    dispatch(
      showModalPage({
        content: (
          <RegistrationFormBuilder loadFromLocaleStorage={useLocalStorage} postSubmitCallback={refreshContent} {...props} />
        ),
      })
    );
  }

  // Initial componentDidMount load the posts for the registration
  useEffect(() => {
    dispatch(getRegistrationConfiguration(registrationId));

    return () => {
      dispatch(resetAll());
    };
  }, []);

  useEffect(() => {
    if (getPostsLengthByActiveTab(activeTab) !== 0) return;
    getPostsByActiveTab(activeTab);
  }, [activeTab]);

  usePostRenderEffect(() => {
    if (getPostsLengthByActiveTab(activeTab) === 0) return;

    let currentFeed = document.querySelector(".scroll-view");
    if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight) {
      getPostsByActiveTab(activeTab);
    }
  }, [all.posts, mostLiked.posts, mine.posts]);

  return (
    <Page>
      <TopBar
        title={pageOverrideFromQueryParams || page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper data-test-id="btn-show-add-post-modal" onClick={isThereARegistrationFormSaved}>
            <PlusIcon />
          </ActionWrapper>
        }
      />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />

      <TabView
        tabStyle={{ paddingTop: `50px` }}
        onScrollEnd={() => getPostsByActiveTab(activeTab)}
        activeTabIndex={activeTab}
        tabs={[
          <RegistrationPosts
            {...props}
            searchTerm={searchTerm}
            layoutMode={LAYOUT_MODE}
            feedType={feedTypes.all}
            registrationConfig={registrationConfig}
            page={page}
          />,
          <RegistrationMostLikedPosts
            {...props}
            searchTerm={searchTerm}
            layoutMode={LAYOUT_MODE}
            registrationConfig={registrationConfig}
          />,
          <RegistrationPosts
            {...props}
            searchTerm={searchTerm}
            layoutMode={LAYOUT_MODE}
            feedType={feedTypes.mine}
            registrationConfig={registrationConfig}
            page={page}
          />,
        ]}
      />

      {/* search bar */}
      <div className={searchInput(activeTab)}>
        <CSSTransition
          in={activeTab === 0 || activeTab === 1 || activeTab === 2}
          timeout={500}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="floating-search-input"
        >
          <FloatingSearchInput
            style={{ marginTop: `50px` }}
            dataTestId={"inputField"}
            onChange={(e) => {
              let currentFeedType = [feedTypes.all, feedTypes.mostLiked, feedTypes.mine][activeTab];

              dispatch(resetFeed({ feedType: currentFeedType }));
              setSearchTerm(e.target.value);
            }}
            value={searchTerm}
            placeholder={lang.searchIn + " " + page.title.toLowerCase()}
            onClearSearch={() => {
              {
                dispatch(resetFeed({ feedType: feedTypes.all }));
              }
              setSearchTerm("");
            }}
          />
        </CSSTransition>
      </div>
    </Page>
  );
};

const searchInput = (activeTab) => css`
  .floating-search-input-enter {
    opacity: 0;
    transform: scale(1) ${activeTab === 0 || activeTab === 1 || activeTab === 2 ? `translateX(200px)` : "translateX(-200px)"};
  }

  .floating-search-input-enter-active {
    opacity: 1;
    transform: scale(1) translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .floating-search-input-exit {
    opacity: 1;
    transform: scale(1);
  }
  .floating-search-input-exit-active {
    opacity: 0;
    transform: scale(1) ${activeTab === 0 || activeTab === 1 || activeTab === 2 ? `translateX(200px)` : "translateX(-200px)"};
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideDialog: bindActionCreators(hideDialog, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  getRegistrationPosts: bindActionCreators(getRegistrationPosts, dispatch),
  resetAll: bindActionCreators(resetAll, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationOverview);
