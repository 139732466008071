const readAloudTypes = {
  newsPost: "newsPost",
  newsCheckQuestion: "newsCheckQuestion",
  registrationPost: "registrationPost",
  registrationFormQuestion: "registrationFormQuestion",
  registrationTitleAndDescription: "registrationTitleAndDescription",
  registrationCategoryTitle: "registrationCategoryTitle",
  events: "events",
  socialOrChatPost: "socialOrChatPost",
};

export default readAloudTypes;
