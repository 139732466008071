import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Utilities
import hm from "../../../../utilities/highlight-match";
import getProfilePicture from "../../../../utilities/get-profile-picture-from-user-object";

// Hooks
import useAuthorModal from "../../../../hooks/useAuthorModal";

// Components
import ContactMenuOptions from "../../../contacts/components/ContactsMenuOptions";

const SearchResultContact = (props) => {
  const contact = props.result ? props.result.data : {};
  const authorModal = useAuthorModal();
  const searchTerm = useSelector((s) => s.searchPage.searchTerm);

  return (
    <div
      key={`div__contact-result__${contact.id}`}
      className={`list-element ${componentStyles()}`}
      onClick={() => authorModal(contact.id, `contacts/key-persons/${contact.id}`)}
    >
      {getProfilePicture(contact, 40)}
      <div style={{ marginLeft: "0.5rem", flexGrow: 1 }}>
        <p>{hm(contact.name, searchTerm)}</p>
        <p className="meta">
          {contact.contactPersonTitle && hm(contact.contactPersonTitle, searchTerm)}
          {contact.contactPersonTitle && contact.contactGroup && contact.contactGroup.groupName && ", "}
          {contact.contactGroup && contact.contactGroup.groupName && hm(contact.contactGroup.groupName, searchTerm)}
        </p>
      </div>

      <ContactMenuOptions {...contact} />
    </div>
  );
};

const componentStyles = (props) => css`
  display: flex;
  align-items: center;
`;

export default SearchResultContact;
