import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// Actions
import {
  GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
  GET_APP_CONFIG_FAILURE,
  SET_APP_LANGUAGE,
  CLEAR_APP_CONFIG_LOGIN_TYPES,
} from "./actionTypes";

// Utilities
import getAppName from "../utilities/get-app-name";
import setIcons from "../utilities/set-icons";
import { cacheAppConfigImages } from "../cache/utilities/cacheAppConfigImages";

export function getAppConfig(cb) {
  return async function (dispatch, getState) {
    dispatch({
      type: GET_APP_CONFIG,
    });

    // Get api-config (has the api-endpoint)
    let { data: apiConfig } = await axios(`/api-config.json?version=${uuidv4()}`);

    let token = getState().auth.token;

    axios
      .get(`${apiConfig.url}apps/config/${getAppName()}`, {
        headers: { authorization: token ? `bearer ${token}` : "" },
      })
      .then(({ data }) => {
        // Set document title
        document.title = data.title;

        // Set icons (favicon and touch-icons)
        setIcons(data.icon);

        let appConfig = {
          appId: data.id,
          apiUrl: apiConfig.url,
          ...data,
        };

        delete appConfig.id; // Renamed to appId, rest is spread

        cacheAppConfigImages(appConfig);

        // Optional callback
        if (cb) cb();

        return dispatch({
          type: GET_APP_CONFIG_SUCCESS,
          payload: appConfig,
        });
      })
      .catch(() => {
        return dispatch({
          type: GET_APP_CONFIG_FAILURE,
        });
      });
  };
}

export function setAppLanguage(language) {
  return {
    type: SET_APP_LANGUAGE,
    payload: language,
  };
}

export function clearLoginTypes() {
  return {
    type: CLEAR_APP_CONFIG_LOGIN_TYPES,
  };
}
