// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import tinycolor from "tinycolor2";

// Components
import ActionWrapper from "./ActionWrapper";
import { CommentMultipleOutlineIcon } from "mdi-react";
import colors from "../../style/colors";

function countNestedComments(comments, commentCount) {
  if (commentCount) return commentCount;

  if (!comments) return 0;

  /* Using for-loop for performance-reasons (not the prettiest implementation) */
  let count = 0;
  for (let i = 0; i < comments.length; i++) {
    count++;
    for (let j = 0; j < comments[i].comments.length; j++) {
      count++;
    }
  }
  return count;
}

const CommentButton = (props) => {
  // Props
  const { onClick, commentsVisible, comments, commentCount } = props;

  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { primaryColor } = useSelector((state) => state.appConfig);

  const [commentsCount, setCommentsCount] = useState(countNestedComments(comments, commentCount));
  useEffect(() => {
    setCommentsCount(countNestedComments(comments, commentCount));
  }, [comments, commentCount]);

  return (
    <div className={`${componentStyle(commentsVisible, primaryColor)} ${commentsVisible ? "visible" : "hidden"}`}>
      <ActionWrapper className="comment-container" data-test-id="btn-comments-toggle" onClick={onClick}>
        <p className="meta">
          {commentsCount} {commentsCount === 1 ? lang.comment : lang.comments}
        </p>
        <div className="circle">
          <CommentMultipleOutlineIcon className={commentsVisible} />
        </div>
      </ActionWrapper>
    </div>
  );
};

const componentStyle = (commentsVisible, primaryColor) => css`
  button.comment-container {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    margin: 0 0.5rem;
    color: var(--darkGrey);
    transition: color 220ms ease;

    &:hover {
      cursor: pointer;
      color: ${primaryColor};
    }
  }

  .circle {
    display: flex;
    height: 2.25rem;
    width: 2.25rem;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    svg {
      width: 1rem;
      height: 1rem;
      transition: color 220ms ease;
    }
  }

  &.visible {
    .circle {
      background-color: ${tinycolor(primaryColor).setAlpha(1).toString()};

      &:active {
        background-color: ${tinycolor(primaryColor).setAlpha(0.8).toString()};
        transition: background-color 120ms ease;
      }

      svg {
        color: var(--white);
      }
    }
  }

  &.hidden {
    .circle {
      background-color: ${tinycolor(primaryColor).setAlpha(0.1).toString()};

      &:active {
        background-color: ${tinycolor(primaryColor).setAlpha(0.2).toString()};
        transition: background-color 120ms ease;
      }

      svg {
        color: ${tinycolor(primaryColor).setAlpha(1).toString()};
      }
    }
  }
`;

export default CommentButton;
