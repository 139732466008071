// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import { CheckboxPill } from "./CheckboxPill";

// Hooks
import { useLocalStorage } from "../../../hooks/useLocalStorage";

// Utilities
import { generateIncludesArray } from "./utils/generateIncludesArray";

// Actions
import { getResults, setSearchIncludes } from "../../../actions/searchPageActions";
import { capitalize } from "lodash-es";

const SearchPageSectionFilters = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.language);
  const onlySearchInSubContentFromPageId = useSelector((state) => state.searchPage.onlySearchInSubContentFromPageId);

  // Result type filters
  const [filters, setFilters] = useLocalStorage("last-used-search-section-filters", {
    includeContacts: true,
    includeSocial: true,
    includeNews: true,
    includePages: true,
    includeRegistrations: true,
  });

  useEffect(() => {
    const sectionsIncluded = generateIncludesArray(filters);

    dispatch(setSearchIncludes(sectionsIncluded));
    dispatch(getResults({ forceFetch: true }));
  }, [filters]);

  function onChangeHandler(key, value) {
    let checkedSections = 0;

    for (let filter in filters) {
      if (filters[filter] === true) checkedSections++;
    }

    if (checkedSections === 1 && filters[key] === true) return;

    setFilters({ ...filters, [key]: value });
  }

  return (
    <div className={componentStyle()}>
      {!onlySearchInSubContentFromPageId && (
        <CheckboxPill
          label={lang.contacts}
          onChange={(value) => onChangeHandler("includeContacts", value)}
          value={filters.includeContacts}
        />
      )}
      <CheckboxPill
        label={capitalize(lang.posts)}
        onChange={(value) => onChangeHandler("includeNews", value)}
        value={filters.includeNews}
      />
      <CheckboxPill
        label="Social"
        onChange={(value) => onChangeHandler("includeSocial", value)}
        value={filters.includeSocial}
      />
      <CheckboxPill
        label={capitalize(lang.menuItems)}
        onChange={(value) => onChangeHandler("includePages", value)}
        value={filters.includePages}
      />
      <CheckboxPill
        label={capitalize(lang.registrations)}
        onChange={(value) => {
          onChangeHandler("includeRegistrations", value);
        }}
        value={filters.includeRegistrations}
      />
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 0.5rem;
  border-bottom: 1px var(--midGrey) solid;
`;

export default SearchPageSectionFilters;
