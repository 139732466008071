// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import AnimateHeight from "react-animate-height";

// Styles
import colors from "../../../style/colors";

// Components
import ButtonRounded from "../../ui/ButtonRounded";
import { FileTreeIcon, ChevronDownIcon, ChevronUpIcon, SettingsIcon, PlusIcon } from "mdi-react";
import PageBuilderTree from "./modal/pageBuilderTree/PageBuilderTree";
import PageBuilderHeaderOptions from "./PageBuilderHeaderOptions";
import CreatePage from "./modal/createPage/CreatePage";

// Config
import breakpoints from "../../../config/breakpoints";
import { durations } from "../../../config/animations";

// Actions
import { showOptionsHandler } from "../../../actions/pageBuilderFilterActions";
import { showModalPage } from "../../../actions/uiActions";
import { updatePages } from "../../../actions/pageBuilderActions";

// Context
import { PageBuilderContext } from "../context/PageBuilderContext";
import useApplicationLanguages from "../../../hooks/useApplicationLanguages";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";
import getAppName from "../../../utilities/get-app-name";

const PageBuilderHeader = (props) => {
  const { currentPage = "none", disableCreatePageButton = false } = props;

  const dispatch = useDispatch();

  const { applicationLanguages } = useApplicationLanguages();

  const lang = useSelector((state) => state.language.language);
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const { pagesHasBeenChanged, loadingPageUpdate } = useSelector((state) => state.pageBuilder);
  const { showOptions } = useSelector((state) => state.pageBuilderFilter);

  const showPagesTree = () => {
    dispatch(
      showModalPage({
        title: `${lang.pageBuilder} ${lang.tree}`,
        content: <PageBuilderTree viewOnly={true} pageToBeMoved={currentPage} />,
        useScrollView: true,
      })
    );
  };

  const createPage = ({ parentPageId }) => {
    dispatch(
      showModalPage({
        title: lang?.addNewPage ?? "",
        content: (
          <PageBuilderContext.Provider value={{ applicationLanguages }}>
            <CreatePage parentPageId={parentPageId} />
          </PageBuilderContext.Provider>
        ),
        useScrollView: false,
      })
    );
  };

  const navigateToPageBuilderSettings = () => {
    pageNavigatorV2({ path: `/${getAppName()}/page-builder-settings`, direction: "forward" });
  };

  return (
    <div className={`${componentStyle(primaryColor)} ${props.className ? props.className : ""}`}>
      <div className="page-builder-header-wrapper">
        <div className="main">
          <ButtonRounded
            data-test-id={"show-page-builder-tree-structure-button"}
            secondary={true}
            className="rounded-btn"
            onClick={showPagesTree}
          >
            <FileTreeIcon />
          </ButtonRounded>

          <div className="add-or-update-container">
            <ButtonRounded
              disabled={disableCreatePageButton}
              data-test-id={"create-new-page-button"}
              secondary={true}
              onClick={() => createPage({ parentPageId: currentPage?.id || null })}
            >
              <PlusIcon />
              <p>{lang.addNewPage}</p>
            </ButtonRounded>

            <ButtonRounded
              disabled={!pagesHasBeenChanged}
              active={loadingPageUpdate}
              onClick={() => dispatch(updatePages())}
            >
              <p>{lang.saveChanges}</p>
            </ButtonRounded>
          </div>

          <ButtonRounded secondary={true} className="rounded-btn" onClick={navigateToPageBuilderSettings}>
            <SettingsIcon data-test-id="registration-settings-navigation-icon" />
          </ButtonRounded>
        </div>

        <div className="options">
          <div className="options-btn-wrapper">
            {!showOptions && (
              <button className="more-options" onClick={() => dispatch(showOptionsHandler(true))}>
                <ChevronDownIcon />
                <label>{lang.showFilters}</label>
              </button>
            )}
            {showOptions && (
              <button className="more-options" onClick={() => dispatch(showOptionsHandler(false))}>
                <ChevronUpIcon />
                <label>{lang.hideFilters}</label>
              </button>
            )}
          </div>

          <AnimateHeight height={showOptions ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
            <PageBuilderHeaderOptions />
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  display: flex;
  background-color: var(--white);
  justify-content: center;
  border-bottom: 1px solid var(--midGrey);

  .page-builder-header-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${breakpoints.lg}px;
    padding: 0.75rem 0.75rem;

    .main {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .add-or-update-container {
        display: flex;

        button {
          height: unset;
          font-size: 0.9rem;
          padding: 0.5rem 1rem;

          &:last-of-type {
            margin-left: 0.5rem;
          }

          svg {
            height: 1.25rem;
            width: 1.25rem;
          }
        }
      }

      .rounded-btn {
        height: 2.25rem;
        width: 2.25rem;
        padding: 0;

        svg {
          height: 1.125rem;
          width: 1.125rem;
        }
      }
    }

    .options {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      width: 100%;

      .options-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        button.more-options {
          background-color: inherit;
          cursor: pointer;
          border: transparent;
          display: flex;
          align-items: center;

          svg {
            cursor: inherit;
            fill: ${primaryColor};
          }

          label {
            cursor: inherit;
            font-weight: bold;
            color: ${primaryColor};
            text-align: center;
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export default PageBuilderHeader;
