// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import ButtonRounded from "./ButtonRounded";
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from "mdi-react";

// Styles
import common from "../../style/common";
import colors from "../../style/colors";

const StickyStepper = (props) => {
  const { maxStep, step } = props;

  // language
  const lang = useSelector((state) => state.language.language);

  return (
    <div className={`${componentStyle(props)} ${props.className || ""}`} style={props.style}>
      <div className="button-wrapper">
        {/* Previous button */}
        <ButtonRounded
          disabled={step === 0}
          secondary={true}
          onClick={props.previousStep}
          style={{ opacity: step === 0 ? 0 : 1 }}
        >
          <ArrowLeftIcon
            style={{
              width: "1.25rem",
              height: "1.25rem",
              margin: "0 0.35rem 0 -0.35rem",
            }}
          />
          {lang.previous}
        </ButtonRounded>

        {/* Next or submit button */}
        <ButtonRounded secondary={step !== maxStep} onClick={(e) => props.nextOrSubmit()}>
          {step === maxStep ? (
            <>
              <CheckIcon style={{ width: "1.25rem", height: "1.25rem", margin: "0 0.35rem 0 -0.35rem" }} />
              {lang.done}
            </>
          ) : (
            <>
              {lang.next}
              <ArrowRightIcon style={{ width: "1.25rem", height: "1.25rem", margin: "0 -0.35rem 0 0.35rem" }} />
            </>
          )}
        </ButtonRounded>
      </div>
    </div>
  );
};

const componentStyle = () => css`
  position: fixed;
  align-items: center;
  top: calc(${common.topBarHeight}px + env(safe-area-inset-top));
  left: 0;
  height: ${common.topBarHeight + 4}px;
  width: 100%;
  margin: 0rem 0 1rem 0;
  display: flex;
  justify-content: center;
  background-color: var(--white);
  border-bottom: 1px var(--lightGrey) solid;

  .button-wrapper {
    max-width: 800px;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
  }
`;

export default StickyStepper;
