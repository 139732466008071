import dayjs from "dayjs";
import requestUtility from "../../../../../utilities/request-utility";

export default async function downloadCSVFile(checkInModuleId) {
  const response = await requestUtility()(`check-in-modules/admin/${checkInModuleId}/check-ins/csv-export`, {
    responseType: "blob",
  });

  // Get filename from Content-Disposition header
  const filename = `check-ind-data_${dayjs().format("YYYY-MM-DD_HH-mm")}.csv`;

  // Create download URL and trigger download
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}
