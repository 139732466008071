// Libs
import { css } from "emotion";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// Components
import ScrollView from "../../ui/ScrollView";
import { UserListItem } from "../../ui/users/UserListItem";
import InlineSpinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";
import FloatingSearchInput from "../../ui/FloatingSearchInput";

// Utilities
import req from "../../../utilities/request-utility";
import { searchAndRankUsers } from "../../../utilities/search-and-rank-users";

const CustomGroupContent = ({ groupId }) => {
  const lang = useSelector((state) => state.language.language);

  const initialize = useRef(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!initialize.current) {
      initialize.current = true;
      req()(`activity-groups/${groupId}/users`)
        .then((res) => {
          setUsers(res.data);
          setFilteredUsers(res.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const onSearch = (searchTerm) => {
    setSearchTerm(searchTerm);

    if (users.length === 0) {
      return;
    }

    if (searchTerm === "") {
      setFilteredUsers(users);
      return;
    }

    setFilteredUsers(searchAndRankUsers(users, searchTerm));
  };

  return (
    <div className={componentStyle()}>
      <ScrollView className="custom-group-container">
        {loading && <InlineSpinner className="spinner" />}
        {!loading && error && <StatusBox status="error" className="status-box" />}
        {!loading && !error && filteredUsers.length === 0 && (
          <StatusBox status="error" content={lang.noUsersFound} className="status-box" />
        )}
        {!loading &&
          !error &&
          filteredUsers.length > 0 &&
          filteredUsers.map((user) => (
            <UserListItem className="list-item" key={user.id} user={user} showIconsRight={true} highlight={searchTerm} />
          ))}
        <FloatingSearchInput
          onChange={(e) => {
            onSearch(e.target.value);
          }}
          value={searchTerm}
          onClearSearch={() => {
            onSearch("");
          }}
        />
      </ScrollView>
    </div>
  );
};

const componentStyle = () => css`
  max-height: 100%;

  .custom-group-container {
    max-height: 100%;
    box-sizing: border-box;
    padding-top: 4.35rem;
    padding-bottom: 4.35rem;

    .spinner {
      margin-top: 2rem;
    }

    .status-box {
      margin-top: 2rem;
    }
  }
`;

export default CustomGroupContent;
