// Libs
import React, { useEffect, useState, useRef } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import { PlusIcon } from "mdi-react";
import { matchPath } from "react-router";
import { useHistory } from "react-router";

// Components
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import UserOnlineList from "./components/userOnlineList";
import ChatRoomList from "./components/chatRoomList";
import UserList from "./components/userList";

// Redux
import {
  getChatRooms,
  removeChatRooms,
  removeSocket,
  setSocket,
  setOnlineUsers,
  updateChatRoomInList,
} from "../../actions/liveChatActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

function liveChatOverview(props) {
  const { match } = props;

  const initailize = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const chatRooms = useSelector((state) => state.liveChat.chatRooms);
  const socket = useSelector((state) => state.liveChat.socket);
  const onlineUsers = useSelector((state) => state.liveChat.onlineUsers);
  const { language: lang } = useSelector((state) => state.language);
  const pages = useSelector((state) => state.pages.pages);

  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const [searchTerm, setSearchTerm] = useState("");
  const [onlineUsersChange, setOnlineUsersChange] = useState(null);
  const [updatedChatRoom, setUpdatedChatRRoom] = useState(null);

  // listen for online users
  socket?.on("getUsers", (users) => {
    setOnlineUsersChange(users);
  });

  // listen for update in a chat room
  socket?.on("updateChatRoom", (chatRoom) => {
    setUpdatedChatRRoom(chatRoom);
  });

  useEffect(() => {
    if (!initailize.current) {
      // connect socket
      dispatch(setSocket());
      initailize.current = true;
    }

    return () => {
      if (initailize) {
        // disconnect socket
        dispatch(removeChatRooms());

        //check if rerouted url is a macth
        const urlMatch1 = matchPath(history.location.pathname, {
          path: "/:appname/liveChat/:pageId",
          exact: false,
          strict: false,
        });

        // if not a match disconnet socket
        if (!urlMatch1) dispatch(removeSocket());
      }
    };
  }, []);

  useEffect(() => {
    // set online users
    if (onlineUsersChange) dispatch(setOnlineUsers(onlineUsersChange));

    // update the chat room
    if (updatedChatRoom) dispatch(updateChatRoomInList(updatedChatRoom));
  }, [onlineUsersChange, updatedChatRoom]);

  useEffect(() => {
    // searchterm for chat room list with delay
    const delayTime = setTimeout(() => dispatch(getChatRooms({ searchTerm: searchTerm })), 400);
    return () => clearTimeout(delayTime);
  }, [searchTerm]);

  return (
    <Page>
      <TopBar
        title={page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper
            onClick={() =>
              dispatch(
                showModalPage({
                  useScrollView: false,
                  title: lang.startAConversation,
                  content: <UserList url={match.url} />,
                })
              )
            }
          >
            <PlusIcon />
          </ActionWrapper>
        }
      />
      <div className={componentStyle()}>
        {/** online users */}
        {/* <UserOnlineList userList={onlineUsers} /> */}

        {/** Search for chats */}
        <FloatingSearchInput
          className="search-bar"
          placeholder={lang.typeToSearch}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          onClearSearch={() => setSearchTerm("")}
        />

        {/* chat room list */}
        <ChatRoomList chatRoomList={chatRooms} searchTerm={searchTerm} url={match.url} />
      </div>
    </Page>
  );
}

const componentStyle = () => css`
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .search-bar {
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    width: calc(97% - 0.6rem);
    z-index: 11;
  }
`;

export default liveChatOverview;
