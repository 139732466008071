import React, { useEffect } from "react";
import { css } from "emotion";
import Page from "../../ui/Page";
import TopBar from "../../ui/TopBar";
import UserSyncLogItem from "./UserSyncLogItem";
import breakpoints from "../../../config/breakpoints";
import { useDispatch, useSelector } from "react-redux";
import { getUserSyncLog } from "../../../actions/userSyncActions";
import ScrollView from "../../ui/ScrollView";
import InlineSpinner from "../../ui/InlineSpinner";
import StatusBox from "../../ui/StatusBox";

function UserSyncLog() {
  const dispatch = useDispatch();
  const userSync = useSelector((state) => state.userSync);

  useEffect(() => {
    dispatch(getUserSyncLog());
  }, []);

  return (
    <Page className={style()}>
      <TopBar useDefaultBackButton={true} title="User Sync Log" />
      <ScrollView>
        <div className="content">
          <p className="header">Synkroniseringslog</p>
          <p className="info">
            Her vises de seneste 14 synkroniseringer. Du kan hente en csv-liste over de enkelte brugere ved at klikke på den
            sektion du gerne vil undersøge
          </p>
          <div className="log-items">
            {userSync.log.loading && (
              <div className="meta">
                <InlineSpinner />
              </div>
            )}
            {userSync.log.error.status && userSync.log.error.status !== 401 && (
              <div className="meta">
                <StatusBox
                  action={{
                    callback: () => dispatch(getUserSyncLog()),
                    title: "Prøv igen",
                  }}
                />
              </div>
            )}
            {userSync.log.error.status === 401 && (
              <div className="meta">
                <StatusBox
                  content="Du har ikke adgang til at se denne side"
                  action={{
                    callback: () => dispatch(getUserSyncLog()),
                    title: "Prøv igen",
                  }}
                />
              </div>
            )}
            {userSync.log.items.length === 0 && !userSync.log.loading && !userSync.log.error.status && (
              <StatusBox
                content="Der er ingen synkroniseringer at vise fra de seneste 14 dage"
                action={{
                  callback: () => dispatch(getUserSyncLog()),
                  title: "Prøv igen",
                }}
              />
            )}
            {userSync.log.items.map((item) => (
              <UserSyncLogItem key={item.id} item={item} />
            ))}
          </div>
        </div>
      </ScrollView>
    </Page>
  );
}

const style = () => css`
  background-color: white;

  .content {
    max-width: ${breakpoints.md}px;
    margin: 0 auto;

    padding: 2.5rem;

    .header {
      font-size: 1.35rem;
      font-weight: 600;

      margin-bottom: 0.5rem;
    }

    .info {
      font-size: 1.1rem;
    }

    .log-items {
      margin-top: 1.5rem;

      display: flex;
      flex-direction: column;

      gap: 1rem;
    }

    .meta {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export default UserSyncLog;
