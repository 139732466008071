import { useEffect, useState } from "react";
import Page from "../../../ui/Page";
import TabBar from "../../../ui/TabBar";
import TabView from "../../../ui/TabView";
import TopBar from "../../../ui/TopBar";
import FormTab from "./components/FormTab";
import HistoryTab from "./components/HistoryTab";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import colors from "../../../../style/colors";
import { getCurrentCheckIn, getLastUsedCompanyName, setCheckInModuleId } from "../../../../actions/checkInActions";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../../ui/Notification";

function CheckInPage({ match }) {
  const [activeTab, setActiveTab] = useState(0);
  const page = useCurrentPage({ pageId: match.params.pageId });
  const dispatch = useDispatch();
  const currentCheckIn = useSelector((s) => s.checkIn.currentCheckIn);

  useEffect(() => {
    dispatch(setCheckInModuleId({ checkInModuleId: page.dataId }));
    dispatch(getCurrentCheckIn());
    dispatch(getLastUsedCompanyName());
  }, []);

  return (
    <Page backgroundColor={colors.white}>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <TabBar
        activeTabIndex={activeTab}
        tabs={[
          {
            title: (
              <>
                Tjek ind <Notification style={{ marginLeft: "0.5rem" }} notifications={currentCheckIn.data ? 1 : 0} />
              </>
            ),
            onClick: () => setActiveTab(0),
          },
          { title: "Indtjekninger", onClick: () => setActiveTab(1) },
        ]}
      />

      <TabView activeTabIndex={activeTab} tabs={[<FormTab />, <HistoryTab />]} />
    </Page>
  );
}

export default CheckInPage;
