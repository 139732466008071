import React, { useState } from "react";
import DropDown from "../../../../ui/dropDown/components/DropDown";
import { useSelector } from "react-redux";
import InformationBox from "../../../../ui/InformationBox";

const EventContent = (props) => {
  const { page, onChangeHandler, isCreateMode = false } = props;

  const lang = useSelector((state) => state.language.language);
  const [showAllEvents, setShowAllEvents] = useState(page?.meta?.eventGroupId ? false : true);

  const onShowAllEventsChangeHandler = () => {
    setShowAllEvents(!showAllEvents);
    onChangeHandler({ target: { name: "meta", value: { ...page?.meta, showAllEvents: !showAllEvents } } });
  };

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">{lang?.eventsContent}</p>

        <div className="field-wrapper">
          <label>{`${lang?.whatEventsToDisplay}`}</label>

          <InformationBox className={"information-box"} style={{ margin: "1rem 0" }}>
            {lang.selectedEventDescription}
          </InformationBox>

          <DropDown
            value={showAllEvents ? 1 : 2}
            onChange={onShowAllEventsChangeHandler}
            options={[
              { id: 1, title: lang.allAppsGeneralEvents, value: true },
              { id: 2, title: lang.onlyEventsForSpecificPage, value: false },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default EventContent;
