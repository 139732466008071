import React from "react";
import AnniversaryTypeDropdown from "./ui/AnniversaryTypeDropdown";
import AnniversaryFilterTypeDropdown from "./ui/AnniversaryFilterTypeDropdown";
import TextInput from "../../../../ui/TextInput";
import { useSelector } from "react-redux";

const AnniversaryContent = ({ page, onChangeHandler }) => {
  const lang = useSelector((state) => state.language.language);

  function onChange(e) {
    onChangeHandler({
      target: {
        name: "meta",
        value: { ...page.meta, [e.target.name]: e.target.value },
      },
    });
  }

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <div className="field-wrapper">
          <p className="title">{lang.anniversaryType_title}</p>
          <label>{lang.anniversaryType_description}</label>
          <AnniversaryTypeDropdown
            usePlaceholder={true}
            placeholder={lang?.clickToSelect}
            name={"anniversaryType"}
            onChange={onChange}
            value={page?.meta?.anniversaryType}
          />
        </div>
      </div>
      <div className="field-container">
        <div className="field-wrapper">
          <p className="title">{lang.anniversaryFilterType_title}</p>
          <label>{lang.anniversaryFilterType_description}</label>
          <AnniversaryFilterTypeDropdown
            usePlaceholder={true}
            placeholder={lang?.clickToSelect}
            name={"anniversaryFilterType"}
            onChange={onChange}
            value={page?.meta?.anniversaryFilterType}
          />
        </div>
      </div>
      <div className="field-container">
        <div className="field-wrapper">
          <p className="title">{lang.AnniversaryEmoji_title}</p>
          <p className="hint">
            <span>{lang.AnniversaryEmoji_description} </span>
            <span>
              <a href="https://emojipedia.org/" rel="noreferrer noopener" target="_blank">
                https://emojipedia.org/
              </a>
            </span>
            <span> ({lang?.opensInANewTab?.toLowerCase()}).</span>
          </p>
          <TextInput name="autoGeneratedEmoji" onChange={onChange} value={page?.meta?.autoGeneratedEmoji || ""} />
        </div>
      </div>
    </>
  );
};

export default AnniversaryContent;
