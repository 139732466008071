// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import StatusBox from "../ui/StatusBox";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";
import InlineSpinner from "../ui/InlineSpinner";

// Actions
import { getAnniversaries, resetAll } from "../../actions/anniversaryActions";

// Styles
import { InfoOutlineIcon } from "mdi-react";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

// Utilities and config
import breakpoints from "../../config/breakpoints";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import getPageFromId from "../../utilities/get-page-from-id";
import getUserGroupTitle from "../../utilities/get-user-group-title";
import colors from "../../style/colors";

const Anniversary = (props) => {
  // Anniversary state from redux
  const { loading, error, anniversaries: outerAnniversaries, endOfFeed } = useSelector((state) => state.anniversary);
  const { language: lang } = useSelector((state) => state.language);
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  // Used for showing the user modal
  const authorModal = useAuthorModal();

  // ComponentDidMount - initial load of component
  useEffect(() => {
    props.resetAll();
    requestAnimationFrame(() => props.getAnniversaries({ dataId: page.dataId }));

    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView onScrollEnd={() => props.getAnniversaries({ dataId: page.dataId })}>
        <div className={componentStyle()}>
          {outerAnniversaries.map(({ anniversaries, title }) => (
            <div className="anniversary-container">
              <p className="date-title">{title}</p>
              {anniversaries.map((anniversary) => (
                <ListItem
                  maxWidth={breakpoints.md}
                  clickable={true}
                  onClick={() => authorModal(anniversary?.user?.id)}
                  title={anniversary?.user?.name}
                  middleTitle={anniversary.anniversaryText}
                  subTitle={getUserGroupTitle(anniversary?.user)}
                  content={
                    anniversary?.anniversarySubText ? (
                      <p className="anniversary-subtext">{anniversary?.anniversarySubText}</p>
                    ) : null
                  }
                  imageLeft={getProfilePictureFromUserObject(anniversary.user, undefined, { marginRight: "0.6rem" })}
                />
              ))}
            </div>
          ))}

          {/* Loading */}
          {loading && !error && !endOfFeed && (
            <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
          )}

          {/* End of feed with anniversaries */}
          {outerAnniversaries.length > 0 && endOfFeed && (
            <StatusBox
              style={{ paddingBottom: "5rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noMoreAnniversaries}
              content={lang.reachedEndOfFeed}
            />
          )}

          {/* End of feed without anniversaries */}
          {outerAnniversaries.length === 0 && endOfFeed && (
            <StatusBox
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.thereIsNoAnniversariesHere}
              content={lang.comeBackSoon}
            />
          )}
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  margin: auto;
  padding-bottom: 5rem;
  max-width: ${breakpoints.md}px;

  div.anniversary-container {
    margin-bottom: 2rem;
    p.date-title {
      padding: 0.5rem 1rem;
    }

    .image-left {
      align-self: flex-start;
    }

    .anniversary-subtext {
      white-space: pre-wrap;
      margin-top: 1rem;
      padding: 0.55rem;
      border: 1px #ccc solid;
      border-radius: 3px;
      font-size: 0.95rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getAnniversaries: bindActionCreators(getAnniversaries, dispatch),
  resetAll: bindActionCreators(resetAll, dispatch),
});

export default connect(null, mapDispatchToProps)(Anniversary);
