import { css } from "emotion";
import breakpoints from "../../../config/breakpoints";

function LogItemLine({ label, value, onClick = () => {}, disabled }) {
  return (
    <div className={style()}>
      <a onClick={onClick} disabled={disabled}>
        <span className="label">{label}</span>: <span className="value">{value}</span>
      </a>
    </div>
  );
}

const style = () => css`
  color: var(--black);
  padding: 0.15rem 0;

  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0.525rem 0;
  }

  .value {
    font-weight: bold;
  }

  a:not([disabled]) {
    :hover,
    :active {
      border-bottom: 1px dashed var(--black);
      cursor: pointer;
    }
  }
`;

export default LogItemLine;
