import React from "react";
import { useSelector } from "react-redux";

import SearchPageBreadcrumb from "./../SeachPageBreadcrumb";
import highlightMatch, { modes as highLightModes } from "../../../../utilities/highlight-match";
import getExcerpt from "../../../../utilities/get-excerpt";
import markdownParser from "../../../../utilities/markdown-parser";
import pageNavigatorV2 from "../../../../utilities/page-navigator-v2";
import getLinkPathFromPageObject from "../../../../utilities/get-link-path-from-page-object";
import pageTransitions from "../../../../config/page-transitions";
import { ChevronRightIcon } from "mdi-react";
import { getExcerptAndHighlightMatch } from "../utils/getExcerptAndHightlightMatch";

const SearchResultSocialPost = (props) => {
  const { result } = props;
  const content = props.result.data.content;
  const { searchTerm } = useSelector((s) => s.searchPage);

  function getURL(result) {
    return `${getLinkPathFromPageObject(result.page, "absolute", { suffix: result.data.id })}`;
  }

  return (
    <div
      className={`list-element`}
      key={`div__social-post__${result.data.id}`}
      onClick={() => {
        pageNavigatorV2({ path: getURL(result), direction: pageTransitions.forward });
      }}
    >
      <div style={{ display: "flex", marginBottom: "0.35rem" }}>
        <SearchPageBreadcrumb page={result.page} key={`breadcrumb__news-post__${result.data.id}}`} />
      </div>

      <div className="content" dangerouslySetInnerHTML={getExcerptAndHighlightMatch({ content, searchTerm })} />

      <ChevronRightIcon className="navigation-arrow-indicator" />
    </div>
  );
};

export default SearchResultSocialPost;
