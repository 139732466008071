// Libs
import React from "react";

// Components
import DropDown from "../../../../../../components/ui/dropDown/components/DropDown";
import useSWR from "swr";
import { swrFetcher } from "../../../../../../utilities/swr-fetcher";

const AnniversaryFilterTypeDropdown = (props) => {
  const { data: options, isValidating: loading } = useSWR("admin/pages/configurations/anniversary-filter-types", swrFetcher);

  return (
    <DropDown
      usePlaceholder={props.usePlaceholder}
      placeholder={props.placeholder}
      name={props.name}
      className={props.className}
      onChange={props.onChange}
      options={options}
      value={props.value}
      loading={loading}
    />
  );
};

export default AnniversaryFilterTypeDropdown;
