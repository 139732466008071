import { CheckCircleIcon } from "mdi-react";
import store from "../../../../store";
import hm, { modes } from "../../../../utilities/highlight-match";

export default function getUserSubTitle({ user = {}, searchterm }) {
  const { userGroups, masterGroups, jobTitles, customGroups } = store.getState().targetGroupModal.targetGroup;
  let titleFragments = [];
  let isSelectedIcon = <CheckCircleIcon className="group-selected-icon" />;
  let isSelectedStyles = { fontWeight: 700, color: "var(--primary-color)" };

  if (user?.userGroup) {
    let isSelected = userGroups.some((ug) => ug === user?.userGroup?.id);
    if (isSelected) titleFragments.push(isSelectedIcon);
    titleFragments.push(
      hm(user?.userGroup?.title, searchterm, modes.jsx, undefined, isSelected ? isSelectedStyles : {}, `${user.id}__ug`)
    );
  }

  if (user?.masterGroup) {
    titleFragments.push(", ");
    let isSelected = masterGroups.some((mg) => mg === user?.masterGroup?.id);
    if (isSelected) titleFragments.push(isSelectedIcon);
    titleFragments.push(
      hm(user?.masterGroup?.title, searchterm, modes.jsx, undefined, isSelected ? isSelectedStyles : {}, `${user.id}__mg`)
    );
  }
  if (user?.jobTitle) {
    titleFragments.push(", ");
    let isSelected = jobTitles.some((jt) => jt === user?.jobTitle);
    if (isSelected) titleFragments.push(isSelectedIcon);
    titleFragments.push(
      hm(user?.jobTitle, searchterm, modes.jsx, undefined, isSelected ? isSelectedStyles : {}, `${user.id}__jt`)
    );
  }

  return titleFragments;
}

export function getSimpleUserSubTitle({ user }) {
  let title = "";

  if (user?.userGroup) title += `${user?.userGroup?.title}, `;
  if (user?.masterGroup) title += `${user?.masterGroup?.title}, `;
  if (user?.jobTitle) title += `${user?.jobTitle}, `;

  // Remove trailing comma
  title = title.slice(0, -2);

  return title;
}
