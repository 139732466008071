import Page from "../../../ui/Page";
import TopBar from "../../../ui/TopBar";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import colors from "../../../../style/colors";
import ScrollView from "../../../ui/ScrollView";
import ButtonRounded from "../../../ui/ButtonRounded";
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";
import { css } from "emotion";
import { DownloadIcon } from "mdi-react";
import formatDate from "./utilities/formatDate";
import downloadCSVFile from "./utilities/downloadCSVFile";
import { useState } from "react";
import SortingArrow from "../../components/SortingArrow";

function Table({ table, columns }) {
  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className={`cursor-pointer select-none ${header.column.getIsSorted() ? "bg-gray-100" : ""}`}
                  >
                    <div className="content-wrapper">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <SortingArrow style={{ marginLeft: "1rem" }} sorting={header.column.getIsSorted()} />
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} data-test-id="check-in-admin-table__registration">
            {row.getAllCells().map((cell) => {
              return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
            })}
          </tr>
        ))}
        {table.getRowModel().rows.length === 0 && (
          <tr data-test-id="check-in-admin-table__no_registration">
            <td colSpan={columns.length}>Ingen data, tjek venligst at filtret er korrekt.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function CheckInAdminTable({ match }) {
  const page = useCurrentPage({ pageId: match.params.pageId });
  const { data, isValidating: loading } = useSWR(`check-in-modules/admin/${page.dataId}/check-ins`, swrFetcher);

  const [sorting, setSorting] = useState([
    // Defaults to newest first
    {
      id: "checkInTimestamp",
      desc: true,
    },
  ]);

  const helper = createColumnHelper();

  const columns = [
    helper.accessor("user.name", {
      header: "Bruger",
      sortingFn: "alphanumeric",
    }),
    helper.accessor("checkInTimestamp", {
      header: "Indtjekning",
      cell: (cell) => formatDate(cell.getValue()),
      sortingFn: "datetime",
    }),
    helper.accessor("checkOutTimestamp", {
      header: "Udtjekning",
      cell: (cell) => formatDate(cell.getValue()),
      sortingFn: "datetime",
    }),
    helper.accessor("company", {
      header: "Virksomhed",
      sortingFn: "alphanumeric",
    }),
    helper.accessor("comment", {
      header: "Kommentar",
      sortingFn: "alphanumeric",
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Page backgroundColor={colors.white}>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <ScrollView className={componentStyle()}>
        {!data && loading && <p>Loading...</p>}
        {data && (
          <>
            <div className="action-wrapper">
              <ButtonRounded
                onClick={() => downloadCSVFile(page.dataId)}
                data-test-id="check-in-admin-table__csv-export-button"
              >
                <DownloadIcon /> Hent data som csv
              </ButtonRounded>
            </div>

            <div className="table-wrapper">
              <Table table={table} columns={columns} />
            </div>
          </>
        )}
      </ScrollView>
    </Page>
  );
}

const componentStyle = () => css`
  .action-wrapper,
  table {
    max-width: 1200px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1200px) {
    table {
      border: 1px var(--midGrey) solid;
      border-radius: 8px;
    }
  }

  .action-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.65rem;
  }

  .table-wrapper {
    overflow: auto;
  }

  table {
    color: var(--black);
    width: 100%;
    border-collapse: separate; /* Important! Default is collapse */
    border-spacing: 0; /* Remove gaps between cells */
    overflow: hidden;
    border-top: 1px var(--midGrey) solid;
    margin-bottom: 1rem;
    user-select: text;

    thead {
      background-color: var(--lightGrey);
      padding-top: 0.5rem;
      width: 100%;

      th {
        padding: 0.65rem 0;
        cursor: pointer;

        .content-wrapper {
          padding: 0 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: 20%;
            bottom: 20%;
            width: 1px;
            background: var(--midGrey);
            left: 0;
          }
        }
      }
    }

    // All cells
    td,
    th {
      border-bottom: 1px var(--midGrey) solid;
      text-align: left;
      padding: 0.65rem 1rem;
    }
  }
`;

export default CheckInAdminTable;
