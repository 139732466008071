import React, { useState } from "react";
import { useSelector } from "react-redux";
import DropDown from "../../../../ui/dropDown/components/DropDown";
import DropDownList from "../../../../ui/dropDown/DropDownList";

const ContactsContent = ({ page, onChangeHandler }) => {
  const lang = useSelector((state) => state.language.language);

  const [settings, setSettings] = useState({
    showKeyPersons: page?.meta?.showKeyPersons,
    showUsers: page?.meta?.showUsers,

    usersDisplayFormat: page?.meta?.usersDisplayFormat,
    keyPersonsDisplayFormat: page?.meta?.keyPersonsDisplayFormat,

    shouldDisplayUserGroupForEntry: page?.meta?.shouldDisplayUserGroupForEntry,
    shouldDisplayCompetencesForEntry: page?.meta?.shouldDisplayCompetencesForEntry,
  });

  const onContactsChangeHandler = (name, value) => {
    setSettings((settings) => {
      return { ...settings, [name]: value };
    });

    onChangeHandler({ target: { name: "meta", value: { ...page?.meta, [name]: value } } });
  };

  const {
    showKeyPersons,
    showUsers,
    usersDisplayFormat,
    keyPersonsDisplayFormat,
    shouldDisplayUserGroupForEntry,
    shouldDisplayCompetencesForEntry,
  } = settings;

  return (
    <>
      <div className="line" />
      <div className="field-container">
        <p className="title">{`${lang.contacts} ${lang.settings.toLowerCase()}`}</p>

        <div className="field-wrapper">
          <label>{lang.displayOfKeyPersons}</label>
          <DropDown
            name={"showKeyPersons"}
            value={showKeyPersons ? 1 : 2}
            onChange={(e) => {
              onContactsChangeHandler(e.target.name, e.target.value === "1");
            }}
            options={[
              { id: 1, title: `${lang.show} ${lang?.keyPersonsCustom?.toLowerCase()}`, value: true },
              { id: 2, title: `${lang.hide} ${lang?.keyPersonsCustom?.toLowerCase()}`, value: false },
            ]}
          />
        </div>

        <div className="field-wrapper">
          <label>{lang.displayOfUsers}</label>
          <DropDown
            name={"showUsers"}
            value={showUsers ? 1 : 2}
            onChange={(e) => onContactsChangeHandler(e.target.name, e.target.value === "1")}
            options={[
              { id: 1, title: `${lang.show} ${lang?.users?.toLowerCase()}`, value: true },
              { id: 2, title: `${lang.hide} ${lang?.users?.toLowerCase()}`, value: false },
            ]}
          />
        </div>

        <div className="field-wrapper">
          <label>{lang.displayFormatOfUsers}</label>
          <DropDown
            name={"usersDisplayFormat"}
            value={usersDisplayFormat}
            onChange={(e) => onContactsChangeHandler(e.target.name, e.target.value)}
            options={[
              { id: "all", title: lang.users, value: "all" },
              { id: "userGroup", title: lang.subGroups, value: "all" },
              { id: "masterGroup", title: lang.mainGroups, value: "masterGroup" },
            ]}
          />
        </div>

        <div className="field-wrapper">
          <label>{lang.displayFormatOfKeyPersons}</label>
          <DropDown
            name={"keyPersonsDisplayFormat"}
            value={keyPersonsDisplayFormat}
            onChange={(e) => onContactsChangeHandler(e.target.name, e.target.value)}
            options={[
              { id: "all", title: lang.users, value: "all" },
              { id: "groups", title: lang.keyPersonsCustomGroup, value: "groups" },
            ]}
          />
        </div>

        <div className="field-wrapper">
          <label>{`${lang.shouldUserGroupBeDisplayedForKeyPersons}?`}</label>
          <DropDown
            name={"shouldDisplayUserGroupForEntry"}
            value={shouldDisplayUserGroupForEntry ? 1 : 2}
            onChange={(e) => onContactsChangeHandler(e.target.name, e.target.value === "1")}
            options={[
              { id: 1, title: lang.yes, value: true },
              { id: 2, title: lang.no, value: false },
            ]}
          />
        </div>

        <div className="field-wrapper">
          <label>{`${lang.shouldSkillsBeDisplayedForUsers}?`}</label>
          <DropDown
            name={"shouldDisplayCompetencesForEntry"}
            value={shouldDisplayCompetencesForEntry ? 1 : 2}
            onChange={(e) => onContactsChangeHandler(e.target.name, e.target.value === "1")}
            options={[
              { id: 1, title: lang.yes, value: true },
              { id: 2, title: lang.no, value: false },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default ContactsContent;
