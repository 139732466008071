// Libs
import { ErrorOutlineIcon } from "mdi-react";
import React from "react";

// Utilities and config
import req from "../utilities/request-utility";
import filterAnniversaries from "../utilities/filter-anniversaries";

// Actions
import { addToast } from "./uiActions";

// Action types
import {
  END_OF_ANNIVERSARIES_FEED,
  GET_ANNIVERSARIES,
  GET_ANNIVERSARIES_FAILURE,
  GET_ANNIVERSARIES_SUCCESS,
  RESET_ANNIVERSARY,
} from "./actionTypes";

/** Fetches the anniversaries from api.
 * Appends the anniversaries onto already existing anniversaries.
 * Use resetAll() to empty the feed.
 * @param {Object} anonymous
 * @param {Integer} anonymous.limit
 * @param {Integer} anonymous.offset
 */

export function getAnniversaries({ limit = 10, offset = null, dataId } = {}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().anniversary;

      // Loop to count all the anniversaries
      if (offset === null) {
        offset = 0;
        state.anniversaries.forEach((outerAnniversary) =>
          outerAnniversary.anniversaries.forEach(() => {
            offset++;
          })
        );
      }

      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_ANNIVERSARIES });

      let { data: apiAnniversaries } = await req()(
        `anniversaries/?limit=${limit}&offset=${offset}${dataId ? `&dataId=${dataId}` : ""}`
      );

      // Manipulate the anniversaries to store the ones with the same date in section together
      const { language: lang } = getState().language;
      const { anniversaries: stateAnniversaries } = getState().anniversary;

      // Return the anniversaries array with the new anniversaries attached
      const anniversaries = filterAnniversaries(apiAnniversaries, lang, stateAnniversaries);

      if (apiAnniversaries.length) {
        dispatch({
          type: GET_ANNIVERSARIES_SUCCESS,
          payload: { anniversaries },
        });
      } else {
        dispatch({
          type: END_OF_ANNIVERSARIES_FEED,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ANNIVERSARIES_FAILURE,
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.couldNotFetchAnniversaries,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/**
 * Resets the anniversaries feed
 */
export function resetAll() {
  return async function (dispatch, getState) {
    dispatch({
      type: RESET_ANNIVERSARY,
    });
  };
}
