import { useEffect, useState } from "react";
import Page from "../../../ui/Page";
import TopBar from "../../../ui/TopBar";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import colors from "../../../../style/colors";
import {
  getCurrentCheckIn,
  getLastUsedCompanyName,
  setCheckInModuleId,
  setFormToCurrentCheckIn,
} from "../../../../actions/checkInActions";
import { useDispatch, useSelector } from "react-redux";
import ScrollView from "../../../ui/ScrollView";
import DataSelector from "../../../ui/dataSelector/DataSelector";
import dataIdentifiers from "../../../../config/dataIdentifiers";
import { css } from "emotion";
import breakpoints from "../../../../config/breakpoints";
import StatusBox from "../../../ui/StatusBox";
import { InformationOutlineIcon, LoginIcon, LogoutIcon } from "mdi-react";
import InlineSpinner from "../../../ui/InlineSpinner";
import CheckInStatus from "../../components/CheckInStatus";
import CheckInForm from "../../components/CheckInForm";
import styleTypes from "../../../../config/styleTypes";

function CheckInForOthers({ match }) {
  const page = useCurrentPage({ pageId: match.params.pageId });
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState();
  const currentCheckIn = useSelector((s) => s.checkIn.currentCheckIn);

  useEffect(() => {
    dispatch(setCheckInModuleId({ checkInModuleId: page.dataId }));
  }, []);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(getCurrentCheckIn({ userId: selectedUserId }));
      dispatch(getLastUsedCompanyName({ userId: selectedUserId }));
    }
  }, [selectedUserId]);

  useEffect(() => {
    // Set form to current checkIn
    dispatch(setFormToCurrentCheckIn());
  }, [currentCheckIn]);

  return (
    <Page backgroundColor={colors.white} className={componentStyle()}>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <ScrollView>
        <div className="user-selector">
          <DataSelector
            className="data-select"
            value={selectedUserId}
            onSelect={({ id }) => setSelectedUserId(id)}
            onRemove={() => setSelectedUserId(undefined)}
            dataIdentifier={dataIdentifiers.CURRENT_APP_USERS}
            data-test-id="check-in-for-others__user-selector"
          />
        </div>

        {!selectedUserId && (
          <StatusBox
            icon={<InformationOutlineIcon />}
            title="Vælg en bruger"
            content="Vælg en bruger ovenfor for at komme i gang"
          />
        )}

        {selectedUserId && (
          <>
            <CheckInStatus isCheckedIn={currentCheckIn.data ? true : false} isImpersonating={true} />
            <CheckInForm
              submitButtonStyleType={currentCheckIn.data ? styleTypes.error : styleTypes.ok}
              showCheckoutTime={currentCheckIn.data ? true : false}
              selectedUserId={selectedUserId}
              submitButtonContent={
                currentCheckIn.data ? (
                  <>
                    <LogoutIcon style={{ width: "1.1rem", height: "1.1rem", marginRight: "0.5rem" }} />
                    Tjek ud
                  </>
                ) : (
                  <>
                    <LoginIcon style={{ width: "1.1rem", height: "1.1rem", marginRight: "0.5rem" }} />
                    Tjek ind
                  </>
                )
              }
            />
          </>
        )}
      </ScrollView>
    </Page>
  );
}

const componentStyle = () => css`
  .user-selector {
    padding: 0.65rem;
    border-bottom: 1px var(--midGrey) solid;
    margin-bottom: 1rem;

    .data-select {
      max-width: ${breakpoints.md}px;
      margin: 0 auto;
    }
  }
`;

export default CheckInForOthers;
