// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Config
import { registrationTypes } from "../registration/config";
import breakpoints from "../../config/breakpoints";
import { fileTypes } from "../../config/fileTypes";
import registrationQuestionLayoutHints from "../../config/registrationQuestionLayoutHints";
import readAloudTypes from "../../config/readAloudTypes";
import showReadAloud from "../../utilities/showReadAloud";

// Components
import TextareaInput from "./TextareaInput";
import ImageUploadMultiple from "./ImageUploadMultiple";
import HorizontalRadioGroup from "./HorizontalRadioGroup";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import YesAndNoGroup from "./YesAndNoGroup";
import TimePicker from "./TimePicker";
import DropDownList from "./dropDown/DropDownList";
import DataSelector from "./dataSelector/DataSelector";
import DropDownFormBuilder from "./dropDown/DropDownFormBuilder";
import TrafficLightRadioGroup from "./TrafficLightRadioGroup";
import RadioGroup from "./RadioGroup";
import FileUpload from "./FileUpload";
import ImageCarousel from "./ImageCarousel";
import GeoLocator from "./GeoLocator";
import TextInput from "./TextInput";
import InformationBox from "./InformationBox";
import { InfoOutlineIcon } from "mdi-react";
import ReadAloud from "./ReadAloud";

// Style
import colors from "../../style/colors";

// Utilities
import getAppName from "../../utilities/get-app-name";
import VideoPlayer from "./VideoPlayer";

// Hooks
import useReadAloud from "../../hooks/useReadAloud";
import NumericInput from "./NumericInput";

function getRowsFromLayoutHint({ layoutHint }) {
  switch (layoutHint) {
    case registrationQuestionLayoutHints.inline:
    case registrationQuestionLayoutHints.small:
      return 1;
    case registrationQuestionLayoutHints.default:
      return 4;
    case registrationQuestionLayoutHints.large:
      return 12;
  }
}

function Title({ question, isQuestionValid }) {
  // Handle Information type questions. This can include markdown
  if (question.type === registrationTypes.INFORMATION) {
    return (
      <div
        className={`title ${isQuestionValid ? "show-validate-title" : ""}`}
        dangerouslySetInnerHTML={markdownParser(question.title)}
      />
    );
  }

  if (question.type === registrationTypes.ADMIN_REPLY_QUESTION) return null;

  return <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>;
}

/**
 *
 * @returns {Object} props
 * @param {Object} props.question
 * @param {Object} props.notValidArr - Skriv forklaring
 * @param {Function} props.updateFields
 * @param {Integer} props.questionIndex
 *
 * Element that displays questions based on the question types - it's currently used in the registration and events modules
 */

const FormBuilder = (props) => {
  const { language: lang } = useSelector((state) => state.language);
  const user = useSelector((state) => state.auth.user);

  // Props
  const {
    categoryIndex = null,
    disabledSubmitBtn = null,
    enableSubmitBtn = null,
    question,
    questionIndex,
    showOnly = false,
    showQuestionTitles = true,
    updateFields,
    enableReadAloud,
    eventId,
    currentTranslationLanguage,
    infoText,
    showEventsReadAloud = false,
  } = props;

  function formatFiles(answer) {
    if (!answer) return [];

    // Return array of files
    if (Array.isArray(answer.title)) {
      return answer.title;
    }

    // (To handle legacy code)
    // Format image to fit an array.
    // This only applies to a question of type IMAGE with a single image.
    // This is because the ImageUploadMultiple only could handle image 1 upload before.
    if (answer.title && answer.baseURL) return [{ image: answer.title, baseURL: answer.baseURL }];

    return [];
  }

  const {
    isPlayingAudio: isPlayingAudioEvents,
    isLoadingAudio: isLoadingAudioEvents,
    readAloudContent: readAloudContentEvents,
  } = useReadAloud({
    type: readAloudTypes.events,
    args: { questionId: question.id, eventId },
  });

  const {
    isPlayingAudio: isPlayingAudioRegistrationQuestionTitle,
    isLoadingAudio: isLoadingAudioRegistrationQuestionTitle,
    readAloudContent: readAloudContentRegistrationQuestionTitle,
  } = useReadAloud({
    type: readAloudTypes.registrationFormQuestion,
    args: { questionId: question.id, registrationId: eventId },
  });

  const isQuestionValid = props.notValidArr.some((invalidQuestionId) => invalidQuestionId === question.id) ? true : false;

  const enableReadAloudCheck = showReadAloud({ enableReadAloud, languageCode: currentTranslationLanguage });

  return (
    <div className={componentStyle() + ` ${question.layoutHint}`} key={`form-element-${question.title}`}>
      {registrationTypes[question.type] && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && registrationTypes[question.type] !== registrationTypes.COMMENT && (
            <div className="form-question-wrapper">
              <Title question={question} isQuestionValid={isQuestionValid} />
              {question.type !== registrationTypes.ADMIN_REPLY_QUESTION && enableReadAloudCheck && (
                <ReadAloud
                  isLoadingAudio={isLoadingAudioRegistrationQuestionTitle}
                  isPlayingAudio={isPlayingAudioRegistrationQuestionTitle}
                  onReadContent={() => readAloudContentRegistrationQuestionTitle({ currentTranslationLanguage })}
                />
              )}
            </div>
          )}

          {/* Information box about admin questions. */}
          {/* This will not be shown for admin reply questions as this has its own information box */}
          {question.hideQuestionInApp && question.type !== registrationTypes.ADMIN_REPLY_QUESTION && (
            <InformationBox
              icon={<InfoOutlineIcon />}
              style={{ marginBottom: "0.5rem" }}
              title={lang.pleaseNote}
              description={lang.questionVisibleForAdmins}
            />
          )}

          {showQuestionTitles && <p className="description">{question.description}</p>}
          {Array.isArray(question.images) && question.images.length > 0 && <ImageCarousel images={question.images} />}
          {Array.isArray(question.video) &&
            question.video.length > 0 &&
            question.video.map((video) => <VideoPlayer video={video.video} baseURL={video.baseURL} />)}

          {/** DATA SELECT **/}
          {question.type === registrationTypes.DATA_SELECT && (
            <DataSelector
              value={question.answer && question.answer.title}
              onSelect={(option) => updateFields({ title: option }, questionIndex, categoryIndex)}
              dataIdentifier={question.dataIdentifier}
            />
          )}

          {/** TEXT **/}
          {question.type === registrationTypes.TEXT && (
            <TextareaInput
              rows={getRowsFromLayoutHint({ layoutHint: question.layoutHint })}
              minHeight={"1rem"}
              disabled={showOnly}
              className={`input ${isQuestionValid ? "not-valid" : "title"}`}
              placeholder={lang.writeHere}
              value={question.answer ? question.answer.title : ""}
              onChange={(e) => updateFields({ ...question.answer, title: e.target.value }, questionIndex, categoryIndex)}
            />
          )}

          {/** IMAGE **/}
          {question.type === registrationTypes.IMAGE && (
            <ImageUploadMultiple
              id={`image-upload__form-builder__${question.id}`}
              disabledSubmitBtn={disabledSubmitBtn}
              enableSubmitBtn={enableSubmitBtn}
              className={isQuestionValid ? "not-valid" : "title"}
              uploadedFiles={formatFiles(question.answer)}
              shouldResetFiles={question.answer && !question.answer.title && !question.answer.title && true}
              allowedAmountOfImages={10}
              disableVideoUpload={true}
              showSingleImageMessage={true}
              onFileUpdate={(images) => {
                updateFields(
                  {
                    ...question.answer,
                    title: images.map(({ image = null, baseURL }) => ({ image, baseURL })),
                  },
                  questionIndex,
                  categoryIndex
                );
              }}
            />
          )}

          {/** VIDEO **/}
          {question.type === registrationTypes.VIDEO && (
            <ImageUploadMultiple
              id={`video-only-upload_form-builder__${question.id}`}
              disabledSubmitBtn={disabledSubmitBtn}
              enableSubmitBtn={enableSubmitBtn}
              className={isQuestionValid ? "not-valid" : "title"}
              uploadedFiles={formatFiles(question.answer)}
              shouldResetFiles={question.answer && !question.answer.title && !question.answer.title && true}
              allowedAmountOfVideos={10}
              disableVideoUpload={false}
              disableImageUpload={true}
              showSingleImageMessage={true}
              onFileUpdate={(images) => {
                updateFields(
                  {
                    ...question.answer,
                    title: images.map(({ image, video = null, baseURL }) => ({
                      image: null,
                      video: video,
                      fileType: fileTypes.video,
                      baseURL,
                    })),
                  },
                  questionIndex,
                  categoryIndex
                );
              }}
            />
          )}

          {/** VIDEO AND IMAGE **/}
          {question.type === registrationTypes.IMAGE_AND_VIDEO && (
            <ImageUploadMultiple
              id={`video-only-upload_form-builder__${question.id}`}
              disabledSubmitBtn={disabledSubmitBtn}
              enableSubmitBtn={enableSubmitBtn}
              className={isQuestionValid ? "not-valid" : "title"}
              uploadedFiles={formatFiles(question.answer)}
              shouldResetFiles={question.answer && !question.answer.title && !question.answer.title && true}
              allowedAmountOfImages={10}
              allowedAmountOfVideos={10}
              disableVideoUpload={false}
              disableImageUpload={false}
              showSingleImageMessage={true}
              onFileUpdate={(images) => {
                updateFields(
                  {
                    ...question.answer,
                    title: images.map(({ image = null, video = null, baseURL }) => {
                      if (video) {
                        return { video, baseURL };
                      } else return { image, baseURL };
                    }),
                  },
                  questionIndex,
                  categoryIndex
                );
              }}
            />
          )}

          {/** FILE **/}
          {question.type === registrationTypes.FILE && (
            <FileUpload
              placeholder={(question.answer && question.answer.title && question.answer.title.split("/").slice(-1)[0]) || ""}
              disabledSubmitBtn={disabledSubmitBtn}
              enableSubmitBtn={enableSubmitBtn}
              value={question.answer ? question.answer.title : null}
              className={isQuestionValid ? "not-valid" : "title"}
              removeFile={() => updateFields({ ...question.answer, title: null }, questionIndex, categoryIndex)}
              onFile={(filePath) => updateFields({ ...question.answer, title: filePath }, questionIndex, categoryIndex)}
            />
          )}

          {/** RADIO **/}
          {question.type === registrationTypes.RADIO && (
            <RadioGroup
              disabled={showOnly}
              className={isQuestionValid ? "not-valid" : "title"}
              selectedValue={question.answer && question.answer.title}
              options={question.options}
              onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
            />
          )}

          {/** DROPDOWN OR TABS**/}
          {(question.type === registrationTypes.DROPDOWN || question.type === registrationTypes.TABS) && (
            <DropDownList
              dropDownListContent={question.options}
              localStorageKey={`${getAppName()}-registration-question-${question.id}`}
              className={isQuestionValid ? "not-valid" : "title"}
              selectedContentId={question?.answer?.title || 0}
              onChange={(e) => updateFields({ ...question.answer, title: e.target.value }, questionIndex, categoryIndex)}
              placeholder={lang.pickOneFromFromTheList}
              defaultToLastUsedValue={question.config && question.config.DEFAULT_TO_LAST_USED_VALUE === true}
              enableSearch={question.config && question.config.ENABLE_SEARCH === true}
              showLastUsedValues={question.config && question.config.SHOW_LAST_USED_VALUES === true}
              name="registration-dropdown"
            />
          )}

          {/** DROPDOWN WITH EXTERNAL DATA **/}
          {question.type === registrationTypes.DROPDOWN_WITH_EXTERNAL_DATA && (
            <DropDownList
              dataIdentifier={question.dataIdentifier}
              localStorageKey={`${getAppName()}-registration-question-${question.id}`}
              className={isQuestionValid ? "not-valid" : "title"}
              selectedContentId={question && question.answer && question.answer.value ? question.answer.value.id : 0}
              onChange={(e) => updateFields({ ...question.answer, value: e.target.value }, questionIndex, categoryIndex)}
              placeholder={lang.pickOneFromFromTheList}
              defaultToLastUsedValue={question.config && question.config.DEFAULT_TO_LAST_USED_VALUE === true}
              enableSearch={question.config && question.config.ENABLE_SEARCH === true}
              showLastUsedValues={question.config && question.config.SHOW_LAST_USED_VALUES === true}
              name="registration-dropdown"
            />
          )}

          {/** DROPDOWN FORM BUILDER **/}
          {question.type === registrationTypes.DROPDOWN_FORM_BUILDER && (
            <DropDownFormBuilder
              dataIdentifier={question.dataIdentifier}
              enableSearch={question.config && question.config.ENABLE_SEARCH === true}
              showLastUsedValues={question.config && question.config.SHOW_LAST_USED_VALUES === true}
              localStorageKey={`${getAppName()}-dropdown-form-builder-registration-question-${question.id}`}
              onChange={(values) => updateFields({ ...question.answer, title: values }, questionIndex, categoryIndex)}
              options={question.options}
              config={question.config || null}
              values={question && question.answer ? question.answer.title : []}
            />
          )}

          {/** RADIO TRAFFIC LIGHT **/}
          {question.type === registrationTypes.RADIO_TRAFFIC_LIGHT && (
            <TrafficLightRadioGroup
              disabled={showOnly}
              className={isQuestionValid ? "not-valid" : "title"}
              selectedValue={question.answer && question.answer.title}
              options={question.options}
              onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
            />
          )}

          {/** RADIO HORIZONTAL **/}
          {question.type === registrationTypes.RADIO_HORIZONTAL && (
            <HorizontalRadioGroup
              disabled={showOnly}
              className={isQuestionValid ? "not-valid" : "title"}
              selectedValue={question.answer && question.answer.title}
              options={question.options}
              onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
            />
          )}

          {/** CHECKBOX **/}
          {question.type === registrationTypes.CHECKBOX && (
            <CheckboxGroup
              disabled={showOnly}
              className={isQuestionValid ? "not-valid" : "title"}
              selectedValues={question.answer && question.answer.title}
              options={question.options}
              onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
            />
          )}

          {/** DATE **/}
          {question.type === registrationTypes.DATE && (
            <DatePicker
              disabled={showOnly}
              className={`input ${isQuestionValid ? "not-valid" : "title"}`}
              name="date"
              defaultDate={question.answer ? question.answer.title : ""}
              onDateChange={(date) => updateFields({ ...question.answer, title: date }, questionIndex, categoryIndex)}
            />
          )}

          {/** YES_NO **/}
          {question.type === registrationTypes.YES_NO && (
            <YesAndNoGroup
              disabled={showOnly}
              className={`input ${isQuestionValid ? "not-valid" : "title"}`}
              selectedValue={question.answer && question.answer.title}
              onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
            />
          )}

          {/** TIME **/}
          {question.type === registrationTypes.TIME && (
            <TimePicker
              config={question.config || undefined}
              shouldResetTime={question.answer && !question.answer.title && true}
              value={question.answer && question.answer.title}
              className={`input ${isQuestionValid ? "not-valid" : "title"}`}
              selectedValue={question.answer ? question.answer.title : null}
              onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
            />
          )}

          {/** GEOLOCATION */}
          {question.type === registrationTypes.GEOLOCATION && (
            <GeoLocator
              onGetLocation={(value) =>
                updateFields(
                  {
                    ...question.answer,
                    title: value,
                  },
                  questionIndex,
                  categoryIndex
                )
              }
              initialValue={question.answer && question.answer.title}
            />
          )}

          {/** EMAIL TEXT **/}
          {question.type === registrationTypes.MAIL_TEXT && (
            <TextInput
              value={question?.answer?.title || ""}
              onChange={(e) => updateFields({ ...question.answer, title: e.target.value }, questionIndex, categoryIndex)}
              className={`input ${isQuestionValid ? "not-valid" : "title"}`}
            />
          )}

          {/* NUMERIC */}
          {question.type === registrationTypes.NUMERIC && (
            <NumericInput
              min={question.config?.MIN}
              max={question.config?.MAX}
              value={question?.answer?.title || question.config?.min || ""}
              onChange={(e) => updateFields({ ...question.answer, title: e.target.value }, questionIndex, categoryIndex)}
              className={`input ${isQuestionValid ? "not-valid" : "title"}`}
              defaultValue={question.config?.min || undefined}
            />
          )}

          {infoText && <p className={`info-text ${isQuestionValid ? "error" : ""}`}>{infoText}</p>}
        </div>
      )}

      {/** EXTRA COMMENT **/}
      {question.answer && question.answer.isCommentEnabled && (
        <div style={props.style} className="type-container">
          <label className="title">{lang.commentOptional}</label>
          <TextareaInput
            name="about"
            disabled={false}
            placeholder={lang.typeAnswerHere}
            backgroundColor={colors.ultraLightGrey}
            minHeight={"100px"}
            value={question.answer.answerComment || ""}
            onChange={(e) =>
              updateFields({ ...question.answer, answerComment: e.target.value }, questionIndex, categoryIndex)
            }
          />
        </div>
      )}

      {/** EXTRA IMAGE **/}
      {question.answer && question.answer.isImageEnabled && (
        <div style={props.style} className="type-container">
          <label className="title">Billede (valgfri)</label>
          <ImageUploadMultiple
            id={`image-upload__form-builder__${question.id}`}
            disabledSubmitBtn={disabledSubmitBtn}
            enableSubmitBtn={enableSubmitBtn}
            uploadedFiles={
              question.answer &&
              question.answer.answerImage && [
                { image: question.answer.answerImage, baseURL: question.answer.answerImageBaseURL },
              ]
            }
            shouldResetFiles={question.answer && !question.answer.answerImage && true}
            allowedAmountOfImages={1}
            disableVideoUpload={true}
            showSingleImageMessage={true}
            onFileUpdate={(files) =>
              files.length > 0
                ? updateFields(
                    { ...question.answer, answerImage: files[0].image, answerImageBaseURL: files[0].baseURL },
                    questionIndex,
                    categoryIndex
                  )
                : updateFields(
                    question.answer ? { ...question.answer, answerImage: null, answerImageBaseURL: null } : null,
                    questionIndex,
                    categoryIndex
                  )
            }
          />
        </div>
      )}

      {/** READ ALOUD **/}
      {showEventsReadAloud &&
        showReadAloud({ enableReadAloud: enableReadAloud, languageCode: currentTranslationLanguage }) && (
          <div style={{ display: "flex", alignItems: "baseline", marginTop: "-1.2rem", marginBottom: "1rem" }}>
            <ReadAloud
              isLoadingAudio={isLoadingAudioEvents}
              isPlayingAudio={isPlayingAudioEvents}
              onReadContent={() => readAloudContentEvents({ currentTranslationLanguage })}
            />
          </div>
        )}
    </div>
  );
};

const boxPadding = 0.75;

const componentStyle = () => css`
  div.type-container {
    p.title {
      margin-bottom: 0.5rem;
      white-space: pre-line;
    }

    p.description,
    p.info-text {
      font-size: 0.9rem;
      color: var(--darkGrey);
      margin-bottom: 0.85rem;
      margin-top: -0.15rem;
    }

    p.info-text {
      margin: 0.15rem 0;

      display: flex;
      align-items: center;
      justify-content: end;

      gap: 0.15rem;
    }

    p.info-text.error {
      color: var(--redMedium);
    }

    .not-valid {
      border: 1px solid var(--redMedium);
      border-radius: 5px;
    }

    .show-validate-title {
      color: var(--redMedium);
    }
  }

  .seperator {
    height: 1px;
    background-color: var(--midGrey);
    margin: 1.5rem -${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1.5rem 0;
    }
  }

  video {
    background-color: var(--black);
    width: 100%;
    border-radius: 3px;
    border: 1px var(--lightGrey) solid;
    margin-bottom: 1rem;
    max-height: 50vw;

    &:focus {
      outline: 0;
    }
  }

  /* Inline layout */
  &.${registrationQuestionLayoutHints.inline} {
    .type-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      & > p.title {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .input {
        flex-shrink: 1;
        flex-basis: 50%;
        flex-grow: 1;
        margin-bottom: 0.5rem;
      }
    }
  }

  .form-question-wrapper {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: center;

    p {
      margin-right: 0.75rem;
      margin-bottom: 0 !important;
    }
  }
`;

export default FormBuilder;
