// Libs
import React, { useEffect, useState, Fragment } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import { AccountCircleIcon, ChevronDownIcon, WindowCloseIcon } from "mdi-react";
import DataSelectorModal from "./DataSelectorModal";

// Styles
import colors from "../../../style/colors";

// Utilities
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import functionChecker from "../../../utilities/function-checker";
import { getActiveFromPrimaryColor, getHoverFromPrimaryColor } from "../../../utilities/color-modifiers";

// Actions
import { hideModalPage, showModalPage } from "../../../actions/uiActions";

/**
 * @param {Object} props
 * @param {ReactElement} props.value
 * // props.value should have object-keys:
 * {
 *   id:Integer,
 *   title:String,
 *   subTitle:String,
 *   image: {
 *     baseURL:String,
 *     image:String
 *   }
 * }
 * @param {function} props.onSelect
 * @param {function} props.onRemove
 * @param {string} props.dataIdentifier
 * @param {ReactElement} props.placeholder
 */

const DataSelector = (props) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);

  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const { language: lang } = useSelector((state) => state.language);

  useEffect(() => {
    if (props.value) setSelected(props.value);
  }, []);

  function onSelect(option) {
    setSelected(option);
    if (props.onSelect && functionChecker(props.onSelect)) props.onSelect(option);
    dispatch(hideModalPage());
  }

  function onRemove(e) {
    e.stopPropagation();
    if (props.onRemove && functionChecker(props.onRemove)) props.onRemove();
    setSelected(null);
  }

  return (
    <div
      className={`${componentStyle(props, { primaryColor })} ${props.className || ""}`}
      style={props.style}
      data-test-id={props["data-test-id"]}
      onClick={() =>
        dispatch(
          showModalPage({
            title: selected ? `${lang.chosen}: ${selected.title}` : lang.pickOne,
            content: <DataSelectorModal {...props} selected={selected} onSelect={onSelect} />,
          })
        )
      }
    >
      {selected && (
        <Fragment>
          {selected.image && (
            <div className="image-wrapper">
              {getProfilePictureFromUserObject({ profilePicture: selected.image }, undefined)}
            </div>
          )}
          <div className="meta-wrapper">
            <p className="meta">{selected.title}</p>
            <p className="sub-meta">{selected.subTitle}</p>
          </div>
          <WindowCloseIcon onClick={onRemove} className="right-icon" />
        </Fragment>
      )}

      {!selected && (
        <Fragment>
          <div className="image-wrapper empty">
            <AccountCircleIcon />
          </div>
          <p className="placeholder">{props.placeholder || lang.pressToSelectUser}</p>
          {/* <ChevronDownIcon className="right-icon" /> */}
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = (props, options = {}) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--midGrey);
  align-items: center;
  border-radius: 3px;
  transition: background-color 120ms ease;
  cursor: pointer;

  &:hover {
    background-color: ${getHoverFromPrimaryColor(options.primaryColor)};
    /* background-color: var(--lightGrey); */
  }

  &:active {
    background-color: ${getActiveFromPrimaryColor(options.primaryColor)};
    /* background-color: var(--midLightGrey); */
    border-color: ${options.primaryColor};
  }

  .image-wrapper {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-wrapper.empty {
    border-right: 1px solid var(--midGrey);
    margin-right: 0.5rem;

    svg {
      fill: var(--darkGrey);
    }
  }

  div.meta-wrapper {
    flex-grow: 1;

    p {
      margin: 0 2rem 0 0;

      &.meta {
        font-size: 1rem;
        color: var(--black);
      }

      &.sub-meta {
        font-size: 0.85rem;
        color: var(--darkGrey);
      }
    }
  }

  p.placeholder {
    color: var(--darkGrey);
  }

  svg.right-icon {
    position: absolute;
    right: 7px;
    fill: var(--darkGrey);
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export default DataSelector;
