import dayjs from "dayjs";
import { addToast } from "./uiActions";
import requestUtility from "../utilities/request-utility";
import { CHECK_IN_MODULE } from "./actionTypes";
import getCurrentMinutesFlooredToNearestFive from "../components/checkIn/utilities/getCurrentMinutesFlooredToNearestFive";
import styleTypes from "../config/styleTypes";

export function checkIn({ userId } = {}) {
  return async function (dispatch, getState) {
    const { checkInModuleId, checkInForm } = getState().checkIn;
    const { formData } = checkInForm;

    let queryParams = "?";
    if (userId) queryParams += `userId=${userId}`;

    // Prepare data for submit-format:
    const formattedFormData = {
      checkInTimestamp: formData.checkInDate.replace(/-/gi, "") + formData.checkInHour + formData.checkInMinute,
      comment: formData.comment,
      company: formData.company,
    };

    dispatch({ type: CHECK_IN_MODULE.CHECK_IN });
    try {
      await requestUtility().post(`check-in-modules/${checkInModuleId}/check-ins${queryParams}`, formattedFormData);
      dispatch({ type: CHECK_IN_MODULE.CHECK_IN_SUCCESS });
      getCurrentCheckIn({ userId })(dispatch, getState);
    } catch (err) {
      dispatch({ type: CHECK_IN_MODULE.CHECK_IN_FAILURE });
      addToast({
        duration: 10000,
        title: "Noget gik galt",
        content: err?.response?.data?.message,
        styleType: styleTypes.error,
      })(dispatch, getState);
    }
  };
}

export function checkOut({ userId } = {}) {
  return async function (dispatch, getState) {
    const { checkInModuleId, currentCheckIn, checkInForm } = getState().checkIn;
    const { formData } = checkInForm;

    let queryParams = "?";
    if (userId) queryParams += `userId=${userId}`;

    // Prepare data for submit-format:
    const formattedFormData = {
      checkInTimestamp:
        formData.checkInDate.replace(/-/gi, "") + formData.checkInHour + formData.checkInMinute.toString().padStart(2, "0"), // Minute can sometimes be a number, for some reason...
      checkOutTimestamp:
        formData.checkOutDate.replace(/-/gi, "") +
        formData.checkOutHour +
        formData.checkOutMinute.toString().padStart(2, "0"), // Minute can sometimes be a number, for some reason...
      comment: formData.comment,
      company: formData.company,
    };

    dispatch({ type: CHECK_IN_MODULE.CHECK_OUT });
    try {
      await requestUtility().patch(
        `check-in-modules/${checkInModuleId}/check-ins/${currentCheckIn.data.id}${queryParams}`,
        formattedFormData
      );
      dispatch({ type: CHECK_IN_MODULE.CHECK_OUT_SUCCESS });
      getCurrentCheckIn({ userId })(dispatch, getState);
    } catch (err) {
      dispatch({ type: CHECK_IN_MODULE.CHECK_OUT_FAILURE });
      addToast({
        duration: 10000,
        title: "Noget gik galt",
        content: err.response.data.message,
        styleType: styleTypes.error,
      })(dispatch, getState);
    }
  };
}

export function setCheckInModuleId({ checkInModuleId }) {
  return async function (dispatch) {
    dispatch({
      type: CHECK_IN_MODULE.SET_CHECK_IN_MODULE_ID,
      payload: checkInModuleId,
    });
  };
}

export function getCurrentCheckIn({ userId } = {}) {
  return async function (dispatch, getState) {
    const { checkInModuleId } = getState().checkIn;
    let queryParams = "?";
    if (userId) queryParams += `userId=${userId}`;

    try {
      dispatch({ type: CHECK_IN_MODULE.GET_CURRENT_CHECK_IN });
      const { data } = await requestUtility()(`check-in-modules/${checkInModuleId}/check-ins/active-check-in${queryParams}`);

      dispatch({
        type: CHECK_IN_MODULE.GET_CURRENT_CHECK_IN_SUCCESS,
        payload: data ? data : undefined,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: CHECK_IN_MODULE.GET_CURRENT_CHECK_IN_FAILURE });
    }
  };
}

export function getLastUsedCompanyName({ userId } = {}) {
  return async function (dispatch, getState) {
    const { checkInModuleId } = getState().checkIn;

    let queryParams = "?";
    if (userId) queryParams += `userId=${userId}`;

    try {
      const { data } = await requestUtility()(`check-in-modules/${checkInModuleId}/last-used-company-name${queryParams}`);
      dispatch({
        type: CHECK_IN_MODULE.GET_LAST_USED_COMPANY_SUCCESS,
        payload: data.company,
      });
    } catch (err) {
      console.log(err);
    }
  };
}

/**
 * @param {Object} anon
 * @param {string} anon.name Name of the form field to update (the key in the formData object)
 * @param {string} anon.value The value of the form field
 */
export function updateFormData({ name, value }) {
  return async function (dispatch, getState) {
    dispatch({
      type: CHECK_IN_MODULE.UPDATE_FORM_DATA,
      payload: { name, value },
    });
  };
}

export function setFormToCurrentCheckIn() {
  return async function (dispatch, getState) {
    const { currentCheckIn, lastUsedCompanyName } = getState().checkIn;

    // If there is a checkIn then the form should be set to what was checked in
    if (currentCheckIn.data) {
      const parsedCheckIn = dayjs(currentCheckIn.data.checkInTimestamp, "YYYYMMDDHHmm");
      // Put current checkin into the dates and show checkOut
      updateFormData({ name: "checkInDate", value: parsedCheckIn.format("YYYY-MM-DD") })(dispatch, getState);
      updateFormData({ name: "checkInHour", value: parsedCheckIn.format("HH") })(dispatch, getState);
      updateFormData({ name: "checkInMinute", value: parsedCheckIn.format("mm") })(dispatch, getState);
      updateFormData({ name: "checkOutDate", value: dayjs().format("YYYY-MM-DD") })(dispatch, getState);
      updateFormData({ name: "checkOutHour", value: dayjs().format("HH") })(dispatch, getState);
      updateFormData({ name: "checkOutMinute", value: getCurrentMinutesFlooredToNearestFive() })(dispatch, getState);
      updateFormData({ name: "company", value: currentCheckIn.data?.company })(dispatch, getState);
      updateFormData({ name: "comment", value: currentCheckIn.data?.comment })(dispatch, getState);

      // If there isn't a checkIn then the form should be set to sensible defaults
    } else {
      // Set good defaults and hide checkout
      updateFormData({ name: "checkInDate", value: dayjs().format("YYYY-MM-DD") })(dispatch, getState);
      updateFormData({ name: "checkInHour", value: dayjs().format("HH") })(dispatch, getState);
      updateFormData({ name: "checkInMinute", value: getCurrentMinutesFlooredToNearestFive() })(dispatch, getState);
      updateFormData({ name: "company", value: lastUsedCompanyName })(dispatch, getState);
      updateFormData({ name: "comment", value: "" })(dispatch, getState);
    }
  };
}
