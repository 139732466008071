import { css } from "emotion";
import LogItemLine from "./LogLine";
import breakpoints from "../../../config/breakpoints";
import { format, parse } from "date-fns";
import req from "../../../utilities/request-utility";
import { useDispatch } from "react-redux";
import { addToast } from "../../../actions/uiActions";
import getAppName from "../../../utilities/get-app-name";

/**
 * @typedef {Object} UserSyncLogItemProps
 * @property {Object} id
 * @property {string} date
 * @property {Object} users
 * @property {string} users.id
 * @property {number} users.created
 * @property {number} users.updated
 * @property {number} users.deactivated
 * @property {number} users.reactivated
 * @property {number} users.invalid
 * @property {number} users.total
 */

/**
 * @param {Object} props
 * @param {UserSyncLogItemProps} props.item
 * @returns
 */
function UserSyncLogItem({ item }) {
  async function downloadExport(from) {
    const response = await req()(from);

    if (response.status !== 200) {
      addToast({
        title: "Export failed",
        content: "Could not download export",
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 5000,
      });
      return;
    }

    // Since the download request needs to be authenticated, we can't simply use a link to download the file.
    // There is no simple method to initiate a file download from JavaScript, so we need a workaround.
    const href = URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = href;

    // Get file name from content-disposition header
    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition.split("filename=")[1];

    link.setAttribute("download", fileName);
    link.click();

    // Free up the object URL
    URL.revokeObjectURL(href);
  }

  return (
    <div className={style()}>
      <p className="item-header">{format(parse(item.date, "yyyyMMddHHmmss", 0), "yyyy/MM/dd HH:mm")}</p>
      <div className="item-columns">
        <div>
          <LogItemLine
            label="Nye brugere"
            value={item.users.created}
            disabled={item.users.created === 0}
            onClick={() => downloadExport(`/user-sync/log/${item.id}/created/export`)}
          ></LogItemLine>
          <LogItemLine
            label="Deaktiverede brugere"
            value={item.users.deactivated}
            disabled={item.users.deactivated === 0}
            onClick={() => downloadExport(`/user-sync/log/${item.id}/deactivated/export`)}
          ></LogItemLine>
          <LogItemLine
            label="Opdaterede brugere"
            value={item.users.updated}
            disabled={item.users.updated === 0}
            onClick={() => downloadExport(`/user-sync/log/${item.id}/updated/export`)}
          ></LogItemLine>
        </div>
        <div>
          <LogItemLine
            label="Ugyldie brugere"
            value={item.users.invalid}
            disabled={item.users.invalid === 0}
            onClick={() => downloadExport(`/user-sync/log/${item.id}/invalid/export`)}
          ></LogItemLine>
          <LogItemLine
            label="Reaktiverede brugere"
            value={item.users.reactivated}
            disabled={item.users.reactivated === 0}
            onClick={() => downloadExport(`/user-sync/log/${item.id}/reactivated/export`)}
          ></LogItemLine>
          <LogItemLine label="Total antal brugere" value={item.users.total} disabled></LogItemLine>
        </div>
      </div>
    </div>
  );
}

const style = () => css`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  border: 1px solid var(--midGrey);
  border-radius: 0.25rem;

  padding: 1rem;

  .item-header {
    font-size: 1.09rem;
    font-weight: bold;

    @media screen and (max-width: ${breakpoints.md}px) {
      margin-bottom: 0.5rem;
    }
  }

  .item-columns {
    display: flex;
    gap: 1rem;

    > div {
      flex: 1;

      display: flex;
      flex-direction: column;

      font-size: 1rem;
    }
  }
`;

export default UserSyncLogItem;
