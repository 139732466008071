// Libs
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";
import { CSSTransition } from "react-transition-group";
import { useDebouncedCallback } from "use-debounce/lib";
import { css } from "emotion";

// Components
import TopBar from "../../ui/TopBar";
import Page from "../../ui/Page";
import TabBar from "../../ui/TabBar";
import TabView from "../../ui/TabView";
import ActionWrapper from "../../ui/ActionWrapper";
import RegistrationPosts from "../RegistrationPosts";
import RegistrationFormBuilder from "../RegistrationFormBuilder";
import FloatingSearchInput from "../../ui/FloatingSearchInput";

// Styles
import { PlusIcon } from "mdi-react";

// Utilities and config
import { feedTypes } from "../config";
import getPageFromId from "../../../utilities/get-page-from-id";
import getRegistrationLayout from "../../../utilities/get-registration-layout";
import { durations } from "../../../config/animations";

// Actions
import {
  resetAll,
  getRegistrationTabPosts,
  getRegistrationConfiguration,
  getRegistrationTabs,
  setCurrentTab,
  resetTabFeed,
} from "../../../actions/registrationActions";
import { hideDialog, showDialog, showModalPage } from "../../../actions/uiActions";

// Hooks
import usePostRenderEffect from "../../../hooks/usePostRenderEffect";

const RegistrationFlowOverview = (props) => {
  const dispatch = useDispatch();

  // Page for navigation
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);

  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const LAYOUT_MODE = getRegistrationLayout({ path: match.path });

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // UI Layout
  const [searchTerm, setSearchTerm] = useState("");

  // Get the registrationId / dataId
  const { dataId: registrationId } = queryString.parse(window.location.search);
  let { registrationTitle: pageOverrideFromQueryParams } = queryString.parse(window.location.search);

  const registrationConfig = useSelector((state) => state.registration.registrationConfig);

  const { processflow } = useSelector((state) => state.registration);
  const { currentTab } = processflow;

  // Initial componentDidMount load the posts for the registration
  useEffect(() => {
    dispatch(getRegistrationConfiguration(registrationId));
    dispatch(getRegistrationTabs(registrationId));

    return () => {
      dispatch(resetAll());
    };
  }, []);

  usePostRenderEffect(() => {
    if (!currentTab.tab) {
      return;
    }

    let currentFeed = document.querySelector(".scroll-view");
    if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight) {
      getPostsByActiveTab();
    }
  }, [processflow.tabs]);

  // when searchterm changes update registrations
  usePostRenderEffect(() => {
    debouncedGetPosts({ searchTerm, feedType: feedTypes.processflow });
  }, [searchTerm]);

  // debounce call for searchterm
  const [debouncedGetPosts] = useDebouncedCallback(({ searchTerm, feedType }) => {
    if (!processflow.loading) {
      dispatch(resetTabFeed(currentTab.tab));
      dispatch(
        getRegistrationTabPosts({
          registrationId,
          feedType: feedType,
          searchTerm: searchTerm,
          tab: currentTab.tab,
        })
      );
    }
  }, 500);

  function getPostsByActiveTab() {
    dispatch(
      getRegistrationTabPosts({
        registrationId,
        feedType: feedTypes.processflow,
        searchTerm,
        tab: currentTab.tab,
      })
    );
  }

  // Delete the saved registration
  const deleteLocaleRegistration = () => {
    try {
      localStorage.removeItem(`registration-fields-${registrationId}`);
      localStorage.removeItem(`registration-images-${registrationId}`);
      localStorage.removeItem(`registration-description-${registrationId}`);
      localStorage.removeItem(`registration-title-${registrationId}`);
      localStorage.removeItem(`registration-completedText-${registrationId}`);
    } catch (err) {
      throw err;
    }
  };

  const isThereARegistrationFormSaved = () => {
    try {
      let registrationFields = localStorage.getItem(`registration-fields-${registrationId}`);
      registrationFields = JSON.parse(registrationFields);
      if (Array.isArray(registrationFields) && registrationFields.length > 0) {
        setTimeout(() => {
          dispatch(
            showDialog({
              allowClosing: false,
              title: lang.hello,
              content: lang.continueFromLastCheckDescription,
              primaryActionTitle: lang.yesContinueFromLastCheck,
              primaryAction: () => {
                createNewRegistrationClick(true);
              },
              secondaryAction: () => {
                createNewRegistrationClick(false);
                dispatch(hideDialog());
                deleteLocaleRegistration();
              },
              secondaryActionTitle: lang.createNewForm,
            })
          );
        }, 0);
      } else {
        createNewRegistrationClick(false);
      }
    } catch (err) {
      throw err;
    }
  };

  function createNewRegistrationClick(useLocalStorage) {
    dispatch(
      showModalPage({
        content: (
          <RegistrationFormBuilder loadFromLocaleStorage={useLocalStorage} feedType={feedTypes.processflow} {...props} />
        ),
      })
    );
  }

  let renderTabs = useMemo(() => {
    return Object.keys(processflow.tabs).map(() => (
      <RegistrationPosts
        {...props}
        searchTerm={searchTerm}
        layoutMode={LAYOUT_MODE}
        feedType={feedTypes.processflow}
        registrationConfig={registrationConfig}
        page={page}
      />
    ));
  }, [currentTab, searchTerm]);

  return (
    <Page>
      <TopBar
        title={pageOverrideFromQueryParams || page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper data-test-id="btn-show-add-post-modal" onClick={isThereARegistrationFormSaved}>
            <PlusIcon />
          </ActionWrapper>
        }
      />

      <TabBar
        activeTabIndex={currentTab.index}
        tabs={Object.keys(processflow.tabs).map((tab, tabIndex) => ({
          title: processflow.tabs[tab].title,
          onClick: () => {
            dispatch(resetTabFeed(currentTab.tab));
            dispatch(setCurrentTab(tab, processflow.tabs[tab].title, tabIndex));
          },
        }))}
      />

      <TabView
        tabStyle={{ paddingTop: `50px` }}
        onScrollEnd={() => getPostsByActiveTab(currentTab)}
        activeTabIndex={currentTab.index}
        tabs={Object.keys(processflow.tabs).length === 0 || currentTab.tab.title === "" ? [<></>] : renderTabs}
      />

      {/* search bar */}
      <div className={searchInput(currentTab.tab)}>
        <CSSTransition
          in={currentTab.index >= 0 && currentTab.index < Object.keys(processflow.tabs).length}
          timeout={500}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="floating-search-input"
        >
          <FloatingSearchInput
            style={{ marginTop: `50px` }}
            dataTestId={"inputField"}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm}
            placeholder={lang.searchIn + " " + page.title.toLowerCase()}
            onClearSearch={() => {
              setSearchTerm("");
            }}
          />
        </CSSTransition>
      </div>
    </Page>
  );
};

const searchInput = (activeTab) => css`
  .floating-search-input-enter {
    opacity: 0;
    transform: scale(1) ${activeTab === 0 || activeTab === 1 || activeTab === 2 ? `translateX(200px)` : "translateX(-200px)"};
  }

  .floating-search-input-enter-active {
    opacity: 1;
    transform: scale(1) translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .floating-search-input-exit {
    opacity: 1;
    transform: scale(1);
  }
  .floating-search-input-exit-active {
    opacity: 0;
    transform: scale(1) ${activeTab === 0 || activeTab === 1 || activeTab === 2 ? `translateX(200px)` : "translateX(-200px)"};
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
`;

export default RegistrationFlowOverview;
