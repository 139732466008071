import getExcerpt from "../../../../utilities/get-excerpt";
import highlightMatch, { modes as highLightModes } from "../../../../utilities/highlight-match";
import markdownParser from "../../../../utilities/markdown-parser";

export function getExcerptAndHighlightMatch({ content, searchTerm }) {
  let excerpt = getExcerpt({ content, searchTerm, maxLength: 100 });
  excerpt = highlightMatch(excerpt, searchTerm, highLightModes.markdown);
  excerpt = markdownParser(excerpt, {
    config: { ALLOWED_TAGS: ["p", "span"] },
  });
  return excerpt;
}
