// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import * as queryString from "query-string";
import { bindActionCreators } from "redux";
import { useDebouncedCallback } from "use-debounce/lib";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import ActionWrapper from "../ui/ActionWrapper";
import RegistrationPosts from "./RegistrationPosts";
import RegistrationFormBuilder from "./RegistrationFormBuilder";
import ScrollView from "../ui/ScrollView";
import FloatingSearchInput from "../ui/FloatingSearchInput";

// Styles
import { PlusIcon } from "mdi-react";

// Utilities and config
import { feedTypes, localStorageAction, postLayoutModes } from "./config";
import getPageFromId from "../../utilities/get-page-from-id";
import registrationLocalStorageHandler from "../../utilities/use-registration-local-storage";

// Actions
import { getRegistrationPosts, resetAll, resetFeed, getRegistrationConfiguration } from "../../actions/registrationActions";
import { addToast, hideDialog, hideModalPage, showDialog, showModalPage } from "../../actions/uiActions";

// Hooks
import usePostRenderEffect from "../../hooks/usePostRenderEffect";
import { css } from "emotion";

function isAccordionLayout(matchObject) {
  if (matchObject.path.match(/my-registration-accordion/gi)) {
    return true;
  } else {
    return false;
  }
}

const RegistrationOverview = (props) => {
  const dispatch = useDispatch();
  // Page for navigation
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const [searchTerm, setSearchTerm] = useState("");

  const LAYOUT_MODE = isAccordionLayout(match) ? postLayoutModes.accordion : postLayoutModes.post;

  const { posts, endOfFeed, error } = useSelector((state) => state.registration[feedTypes.mine]);

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // Local storage variable
  const [doesLocalStorageExist, setDoesLocalStorageExist] = useState(false);

  // Get the registrationId / dataId
  let { dataId: registrationId } = queryString.parse(window.location.search);

  // debounce call for searchterm
  const [debouncedGetPosts] = useDebouncedCallback(
    ({ searchTerm }) =>
      dispatch(
        getRegistrationPosts({
          registrationId,
          feedType: feedTypes.mine,
          searchTerm: searchTerm,
        })
      ),
    500
  );

  // when seachterm changes update registrations
  usePostRenderEffect(() => {
    dispatch(resetAll());
    debouncedGetPosts({ searchTerm });
  }, [searchTerm]);

  const [debounceGetPosts] = useDebouncedCallback(
    () => props.getRegistrationPosts({ registrationId, feedType: feedTypes.mine, searchTerm }),
    250
  );

  usePostRenderEffect(() => {
    if (posts.length === 0 || endOfFeed || error) return;

    let currentFeed = document.querySelector(".my-registrations-container");
    if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight) {
      debounceGetPosts();
    }
  }, [posts]);

  // Initial componentDidMount load the posts for the registration
  useEffect(() => {
    props.resetAll();
    dispatch(getRegistrationConfiguration(registrationId));
    requestAnimationFrame(() => {
      props.getRegistrationPosts({ registrationId, feedType: feedTypes.mine, searchTerm });
      isThereARegistrationFormSaved();
    });
    // eslint-disable-next-line
  }, []);

  // Delete the saved registration
  const deleteLocaleRegistration = () => {
    try {
      registrationLocalStorageHandler(localStorageAction.delete, { id: registrationId });
    } catch (err) {
      throw err;
    }
  };

  const isThereARegistrationFormSaved = () => {
    try {
      let registrationFields = localStorage.getItem(`registration-fields-${registrationId}`);
      registrationFields = JSON.parse(registrationFields);
      if (Array.isArray(registrationFields) && registrationFields.length > 0) {
        setDoesLocalStorageExist(true);
      } else {
        setDoesLocalStorageExist(false);
      }
    } catch (err) {
      throw err;
    }
  };

  function refreshContent() {
    props.getRegistrationPosts({ registrationId, feedType: feedTypes.mine, offset: 0, searchTerm });
  }

  function onCreateNewRegistrationClick() {
    if (doesLocalStorageExist) {
      props.showDialog({
        title: lang.hello,
        content: lang.continueFromLastCheckDescription,
        primaryActionTitle: lang.yesContinueFromLastCheck,
        primaryAction: () => {
          props.showModalPage({
            title: `${page.title}`,
            closeCallback: () => {
              isThereARegistrationFormSaved();
              props.hideModalPage();
            },
            content: <RegistrationFormBuilder loadFromLocaleStorage={true} postSubmitCallback={refreshContent} />,
            useScrollView: false,
          });
        },
        secondaryAction: () => {
          props.showModalPage({
            title: `${page.title}`,
            closeCallback: () => {
              isThereARegistrationFormSaved();
              props.hideModalPage();
            },
            content: <RegistrationFormBuilder loadFromLocaleStorage={false} postSubmitCallback={refreshContent} />,
            useScrollView: false,
          });
          props.hideDialog();
          deleteLocaleRegistration();
        },
        secondaryActionTitle: lang.createNewForm,
      });
    } else {
      props.showModalPage({
        title: `${page.title}`,
        closeCallback: () => {
          isThereARegistrationFormSaved();
          props.hideModalPage();
        },
        content: <RegistrationFormBuilder loadFromLocaleStorage={false} postSubmitCallback={refreshContent} />,
        useScrollView: false,
      });
    }
  }

  return (
    <Page className={componentStyle()}>
      <TopBar
        title={page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper data-test-id="btn-show-add-post-modal" onClick={onCreateNewRegistrationClick}>
            <PlusIcon />
          </ActionWrapper>
        }
      />

      <ScrollView
        className="my-registrations-container"
        onScrollEnd={() => props.getRegistrationPosts({ registrationId, feedType: feedTypes.mine, searchTerm })}
      >
        <RegistrationPosts
          className="registration-posts"
          searchTerm={searchTerm}
          layoutMode={LAYOUT_MODE}
          feedType={feedTypes.mine}
          page={page}
        />

        {/* search bar */}
        <FloatingSearchInput
          className="search-input"
          dataTestId={"inputField"}
          onChange={(e) => {
            {
              dispatch(resetFeed({ feedType: feedTypes.all }));
            }
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          placeholder={lang.searchIn + " " + page.title.toLowerCase()}
          onClearSearch={() => {
            {
              dispatch(resetFeed({ feedType: feedTypes.all }));
            }
            setSearchTerm("");
          }}
        />
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  .my-registrations-container {
    .registration-posts {
      margin-top: 46px;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideDialog: bindActionCreators(hideDialog, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  getRegistrationPosts: bindActionCreators(getRegistrationPosts, dispatch),
  resetAll: bindActionCreators(resetAll, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationOverview);
