import {
  GET_NEWS_POSTS,
  GET_NEWS_POSTS_FAILURE,
  GET_NEWS_POSTS_SUCCESS,
  SET_END_OF_NEWS_FEED,
  REFETCH_NEWS_POST_SUCCESS,
  REFETCH_NEWS_POST_COMMENTS,
  REFETCH_NEWS_POST_COMMENTS_FAILURE,
  REFETCH_NEWS_POST_COMMENTS_SUCCESS,
  REFETCH_NEWS_POST_LIKES_SUCCESS,
  LIKE_NEWS_POST,
  LIKE_NEWS_POST_FAILURE,
  LIKE_NEWS_POST_SUCCESS,
  UNLIKE_NEWS_POST,
  UNLIKE_NEWS_POST_FAILURE,
  UNLIKE_NEWS_POST_SUCCESS,
  ADD_NEWS_POST_COMMENT,
  ADD_NEWS_POST_COMMENT_SUCCESS,
  ADD_NEWS_POST_COMMENT_FAILURE,
  RESET_NEWS_FEEDS,
  RESET_NEWS_FEED,
  READ_NEWS_POST,
  READ_NEWS_POST_SUCCESS,
  READ_NEWS_POST_FAILURE,
  SET_POST_AS_DELETED,
  UNSET_POST_AS_DELETED,
  REMOVE_POST_FROM_FEED,
  SET_UNREAD_NEWS_POSTS_COUNT,
  GET_FRONTEND_ADMIN_MAPPINGS_SUCCESS,
  RESET_FRONTEND_ADMIN_MAPPINGS,
  UPDATE_NEWS_POST_PIN_ON_FEED,
} from "../actions/actionTypes";
import req from "../utilities/request-utility";
import { durations } from "../config/animations";
import smoothScrollToBottom from "../utilities/smooth-scroll-to-bottom";
import { feedTypes } from "../components/news/config";
import { addToast } from "./uiActions";
import { getPages } from "./pagesActions";
import { CakeVariantIcon, ErrorOutlineIcon, PinIcon } from "mdi-react";
import React from "react";
import { REFETCH_NEWS_POST_COMMENTS_LIKES } from "./actionTypes";
import styleTypes from "../config/styleTypes";

const DEFAULT_POST_LIMIT = 5;

/**
 * Fetches posts from api.
 * Appends posts onto already existing posts.
 */
export function getPosts({
  subTypeId,
  feedType,
  limit = DEFAULT_POST_LIMIT,
  postId = null,
  includeFilesFromLinkCollection = false,
  sort = null,
}) {
  return async function (dispatch, getState) {
    try {
      let state = getState().news[feedType];

      // Only apply this for "default" limit
      if (state.posts.length === 0 && limit === DEFAULT_POST_LIMIT) limit = 2;

      /* Prevents duplicated results and spares api for duplicated requests */
      if (state.loading || state.error || state.endOfFeed) {
        return;
      }

      dispatch({ type: GET_NEWS_POSTS, payload: { feedType } });

      if (postId === null) {
        getPostsForFeeds();
      } else {
        getPostForSinglePostView();
      }

      if (!sort) sort = "";

      async function getPostsForFeeds() {
        let url = `news/${subTypeId}`;
        url += `?limit=${limit}`;
        url += `&offset=${getState().news[feedType].posts.length}`;
        url += `&${feedType}=1`;
        url += `&includeFilesFromLinkCollection=${includeFilesFromLinkCollection ? 1 : 0}`;
        url += `&sort=${sort}`;

        let { data: posts } = await req()(url);
        handlePosts(posts);
      }

      async function getPostForSinglePostView() {
        try {
          let { data: post } = await req()(`news/${subTypeId}/${postId}`);
          handlePosts([post]);
        } catch (err) {
          errorLoadingNewsPosts(dispatch, getState, feedType);
        }
      }

      function handlePosts(posts) {
        if (posts.length) {
          dispatch({
            type: GET_NEWS_POSTS_SUCCESS,
            payload: { posts, feedType },
          });
        } else {
          dispatch({
            type: SET_END_OF_NEWS_FEED,
            payload: { feedType },
          });
        }
      }
    } catch (err) {
      errorLoadingNewsPosts(dispatch, getState, feedType);
    }
  };
}

function errorLoadingNewsPosts(dispatch, getState, feedType) {
  dispatch({ type: GET_NEWS_POSTS_FAILURE, payload: { feedType } });
  const { language: lang } = getState().language;
  addToast({
    title: lang.errorGeneral,
    content: lang.errorCouldNotGetPosts,
    icon: <ErrorOutlineIcon />,
    styleType: "error",
    duration: 20000,
  })(dispatch, getState);
}

export function updateUnreadCount({ subTypeId }) {
  return function (dispatch) {
    req()(`news/${subTypeId}/posts-count`).then(({ data }) => {
      dispatch({
        type: SET_UNREAD_NEWS_POSTS_COUNT,
        payload: data.unreadCount,
      });
    });
  };
}

/** Resets feeds be removing all posts */
export function resetFeeds() {
  return {
    type: RESET_NEWS_FEEDS,
  };
}

export function resetFeed(feedType) {
  return async function (dispatch, getState) {
    dispatch({
      type: RESET_NEWS_FEED,
      payload: feedType,
    });
  };
}

/** Likes post and autoupdate it afterwards
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 *
 */
export function likePost({ subTypeId, postId, feedType }) {
  return async function (dispatch, getState) {
    /**
     * When post is liked
     *
     * 1:  A request is fired to the api. Meanwhile the post's id is added to the list of postsWithLoadingLikes
     * 2a: The like is succesfully created. The post's id is removed from the list, and a new request is made
     *     to refetch the current posts likes and update the post with these
     *
     * 2b: The like fails. The posts id is removed from the list and an error toast is displayed
     *
     */
    dispatch({ type: LIKE_NEWS_POST, payload: postId });

    try {
      await req().put(`news/${subTypeId}/${postId}/like`);
      // dispatch({ type: LIKE_NEWS_POST_SUCCESS, payload: postId });
      refreshPostLikes({ feedType, subTypeId, postId })(dispatch);
    } catch {
      dispatch({ type: LIKE_NEWS_POST_FAILURE, payload: postId });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Removes like from post, and updates it's likes in the redux-store
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 *
 */
export function unlikePost({ subTypeId, postId, feedType }) {
  return async function (dispatch, getState) {
    dispatch({ type: UNLIKE_NEWS_POST, payload: postId });
    try {
      await req().delete(`news/${subTypeId}/${postId}/like`);

      refreshPostLikes({ feedType, subTypeId, postId })(dispatch);
    } catch (err) {
      console.log("Err: ", err);
      dispatch({ type: UNLIKE_NEWS_POST_FAILURE, payload: postId });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Fetches a fresh post from the api and replaces the current post with it
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 */
export function refreshPost({ subTypeId, postId, feedType }) {
  return async function (dispatch) {
    try {
      let { data: post } = await req()(`news/${subTypeId}/${postId}`);

      dispatch({
        type: REFETCH_NEWS_POST_SUCCESS,
        payload: {
          post,
          feedType,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
}

/** Fetches a fresh set of likes from the api and replaces the current ones
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 */
export function refreshPostLikes({ subTypeId, postId, feedType }) {
  return async function (dispatch) {
    try {
      let { data: post } = await req()(`news/${subTypeId}/${postId}/`);

      dispatch({
        type: REFETCH_NEWS_POST_LIKES_SUCCESS,
        payload: {
          post,
          feedType,
          postId,
        },
      });

      // These two do the exact same thing... They probably should have been named "REMOVE_POST_ID_FROM_LOADING_LIKES_LIST"
      dispatch({ type: LIKE_NEWS_POST_SUCCESS, payload: postId });
      dispatch({ type: UNLIKE_NEWS_POST_SUCCESS, payload: postId });
    } catch (err) {
      console.log(err);
    }
  };
}

/**
 * Fetches the latest likes for a given comment of a news post
 * @param subTypeId {Integer}
 * @param newsId {Integer}
 * @param feedType {String}
 * @param commentId {Integer}
 * @param callback {Function}
 */

export function refreshPostCommentLikes({ subTypeId, newsId, feedType, commentId, callback = null }) {
  return async function (dispatch) {
    try {
      let { data: likes } = await req()(`news/${subTypeId}/news/${newsId}/comments/${commentId}/likes`);

      dispatch({
        type: REFETCH_NEWS_POST_COMMENTS_LIKES,
        payload: {
          likes,
          feedType,
          newsId: newsId,
          commentId: commentId,
        },
      });
      // Invokes callback if specified
      if (callback) callback(null);
    } catch (err) {
      if (callback) callback(true);
    }
  };
}

/** Marks the post as read.
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 */
export function readPost({ subTypeId, postId, feedType, callback }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: READ_NEWS_POST,
        payload: postId,
      });

      const lang = getState().language.language;
      const { data: pointResult } = await req().put(`news/${subTypeId}/${postId}/read`);

      /* This shorthand makes the next lines a lot more readable :-) */
      function addToastShortHand(content) {
        addToast({
          styleType: "success",
          title: lang.wellDone,
          content,
          icon: <CakeVariantIcon />,
          duration: 10000,
        })(dispatch, getState);
      }

      // Normal points and no bonuspoints
      if (pointResult.points > 0 && !pointResult.bonusPoints) {
        addToastShortHand(lang.youveGained__placeholder__points.replace(/{{placeholder}}/g, pointResult.points));

        // Reading the day of publication
      } else if (pointResult.points > 0 && pointResult.bonusPoints === 5) {
        addToastShortHand(
          lang.youveGained__placeholder__pointsAnd5BonusPoints.replace(/{{placeholder}}/g, pointResult.points)
        );

        // Reading the day after publication
      } else if (pointResult.points > 0 && pointResult.bonusPoints === 4) {
        addToastShortHand(
          lang.youveGained__placeholder__pointsAnd4BonusPoints.replace(/{{placeholder}}/g, pointResult.points)
        );

        // reading on day 2, 3 or 4
      } else if (pointResult.points > 0 && pointResult.bonusPoints > 0 && pointResult.bonusPoints < 4) {
        addToastShortHand(
          lang.youveGained__placeholderPoints__pointsAnd__placeholderBonusPoints__BonusPointsForReadingWithin__placeholderDays__
            .replace(/{{placeholderPoints}}/g, pointResult.points)
            .replace(/{{placeholderBonusPoints}}/g, pointResult.bonusPoints)
            .replace(/{{placeholderDays}}/g, pointResult.daysSincePublication)
        );
      }

      dispatch({
        type: READ_NEWS_POST_SUCCESS,
        payload: postId,
      });

      // No matter what update the amount of unread news in the tab:
      updateUnreadCount({ subTypeId })(dispatch, getState);

      // Update pages so notification count goes down when you go back
      getPages()(dispatch, getState);

      if (callback) callback();

      // If feedType is all refresh post in all-tab, remove post in unread-tab and reset read-tab
      if (feedType === feedTypes.all) {
        refreshPost({ subTypeId, postId, feedType: feedTypes.all })(dispatch, getState);
        resetFeed(feedTypes.read)(dispatch, getState);
        resetFeed(feedTypes.unread)(dispatch, getState);
      }

      // If feedType is unread, refresh post in all tab
      if (feedType === feedTypes.unread) {
        refreshPost({ subTypeId, postId, feedType: feedTypes.all })(dispatch, getState);
        removePostFromFeed({ postId, feedType: feedTypes.unread, subTypeId })(dispatch, getState);
      }
    } catch (err) {
      dispatch({
        type: READ_NEWS_POST_FAILURE,
        payload: postId,
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Removes post from feed. This happens in 3 steps:
 * 1. The post-id is added to the list of deleted posts. This triggers a
 *    deletion-animation in the feed
 * 2. When the animation ends, the post is actually removed from the feed
 * 3. Then the post-id is removed from the array of deleted posts
 * 4. More posts is fetched to make sure the feed isn't empty
 *
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 */
export function removePostFromFeed({ postId, feedType, subTypeId }) {
  return async function (dispatch, getState) {
    // Adds the post to the list of deleted posts. (This will animate it out)
    dispatch({
      type: SET_POST_AS_DELETED,
      payload: postId,
    });

    // Wait for animation duration (duration.slow)
    setTimeout(() => {
      // Remove post from feed. This is important as the api-offset will be
      // one-off if the now read post is still included in the list of posts
      dispatch({
        type: REMOVE_POST_FROM_FEED,
        payload: { postId, feedType },
      });

      // Remove the postId from the array of deleted posts. This doesn't really make a
      // big difference. Its just to keep the array from growing
      dispatch({ type: UNSET_POST_AS_DELETED, payload: postId });

      // Refetch posts. This makes sure that the feed wont be empty before all posts
      // actually have been read
      getPosts({ subTypeId, feedType })(dispatch, getState);
    }, durations.slow + 10);
  };
}

/** Add a comment to the post.
 * @param {Object} anonymous
 * @param {integer} anonymous.subTypeId
 * @param {integer} anonymous.postId
 * @param {integer} anonymous.feedType
 */
export function addComment({ subTypeId, postId, feedType, comment, commentsContainer, parentCommentId }) {
  return async function (dispatch, getState) {
    // 1. Add the postId to the list of posts with comments being added
    dispatch({ type: ADD_NEWS_POST_COMMENT, payload: postId });

    try {
      // 2. Add the comment with the api
      await req().post(`news/${subTypeId}/${postId}/comments`, {
        comment: comment.content,
        image: comment.image,
        parentCommentId: parentCommentId,
      });
      dispatch({ type: ADD_NEWS_POST_COMMENT_SUCCESS, payload: postId });

      // 3. Wait for step 2 and refetch comments for the post
      refreshPostComments({ subTypeId, postId, feedType, commentsContainer })(dispatch);
      refreshPost({ subTypeId, postId, feedType, commentsContainer })(dispatch);
    } catch (err) {
      dispatch({ type: ADD_NEWS_POST_COMMENT_FAILURE, payload: postId });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function refreshPostComments({ subTypeId, postId, feedType, commentsContainer }) {
  return async function (dispatch) {
    try {
      // The timer prevents double scrolling if network is faster than 300ms
      // First scroll is scrolling to spinner, Second scroll is scrolling to comment
      let timer = setTimeout(() => smoothScrollToBottom(commentsContainer ? commentsContainer.current : null), 300);
      dispatch({ type: REFETCH_NEWS_POST_COMMENTS, payload: postId });
      let { data: comments } = await req()(`news/${subTypeId}/${postId}/comments?limit=999999`);
      dispatch({
        type: REFETCH_NEWS_POST_COMMENTS_SUCCESS,
        payload: { postId, comments, feedType },
      });

      // Smooth scroll to bottom
      clearTimeout(timer);
      smoothScrollToBottom(commentsContainer ? commentsContainer.current : null);
    } catch {
      dispatch({ type: REFETCH_NEWS_POST_COMMENTS_FAILURE, payload: postId });
    }
  };
}

export function getFrontendAdminMapping() {
  return async function (dispatch, getState) {
    try {
      const enableFrontendAdmin = getState().appConfig.enableFrontendAdmin || false;
      const currentFrontendAdminMappings = getState().news.frontendAdminMappings;

      if (!enableFrontendAdmin) {
        dispatch({
          type: RESET_FRONTEND_ADMIN_MAPPINGS,
        });
        return;
      }

      if (currentFrontendAdminMappings.length > 0) return;

      const { data: newsUserGroupIds } = await req()(`news/sub-types/frontend-admin-mappings`);

      dispatch({
        type: GET_FRONTEND_ADMIN_MAPPINGS_SUCCESS,
        payload: newsUserGroupIds,
      });
    } catch (error) {
      dispatch({
        type: RESET_FRONTEND_ADMIN_MAPPINGS,
      });
    }
  };
}

export function pinPostOnFeed(postId, feedType) {
  return function (dispatch) {
    // Adds the post to the list of deleted posts. (This will animate it out)
    dispatch({
      type: SET_POST_AS_DELETED,
      payload: postId,
    });

    setTimeout(
      () =>
        dispatch({
          type: UPDATE_NEWS_POST_PIN_ON_FEED,
          payload: { postId, feedType },
        }),
      600 // MAGIC NUMBER WARNING: This number has to be longer than the removal animation time
    );
  };
}

export function pinPost({ postId, subTypeId, feedType }) {
  return async function (dispatch, getState) {
    const { language: lang } = getState().language;

    // set post to be pinned
    await req()
      .put(`news/${subTypeId}/${postId}/pin`)
      .then(() => {
        pinPostOnFeed(postId, feedType)(dispatch, getState);

        addToast({
          title: lang.success,
          content: lang.postIsPinned,
          icon: <PinIcon style={{ transform: "rotate(45deg)" }} />,
          styleType: styleTypes.ok,
          duration: 5000,
        })(dispatch, getState);
      })
      .catch(() => {
        addToast({
          title: lang.error,
          content: lang.postCouldNotBePinned,
          icon: <PinIcon style={{ transform: "rotate(45deg)" }} />,
          styleType: styleTypes.error,
          duration: 5000,
        })(dispatch, getState);
      });
  };
}

export function unpinPost({ postId, subTypeId }) {
  return async function (dispatch, getState) {
    const { language: lang } = getState().language;

    // delete pin from post
    await req()
      .delete(`news/${subTypeId}/${postId}/pin`)
      .then(() => {
        resetFeed(feedTypes.all)(dispatch, getState); // Reset the feed so it is ready for update of ui
        getPosts({ subTypeId, feedType: feedTypes.all })(dispatch, getState); // Get the new News with pin updated
        addToast({
          title: lang.success,
          content: lang.postIsUnpinned,
          icon: <PinIcon style={{ transform: "rotate(45deg)" }} />,
          styleType: styleTypes.ok,
          duration: 5000,
        })(dispatch, getState);
      })
      .catch(() => {
        addToast({
          title: lang.error,
          content: lang.postCouldNotBeUnpinned,
          icon: <PinIcon style={{ transform: "rotate(45deg)" }} />,
          styleType: styleTypes.error,
          duration: 5000,
        })(dispatch, getState);
      });
  };
}
