// ActionTypes
import { CHECK_IN_MODULE } from "../actions/actionTypes";

const initialState = {
  checkInModuleId: undefined,
  lastUsedCompanyName: "",
  currentCheckIn: {
    isLoading: false,
    hasError: false,
    data: {},
  },
  checkInForm: {
    isSubmitting: false,
    submissionFaild: false,
    formData: {
      checkInDate: "",
      checkInHour: "",
      checkInMinute: "",
      checkOutDate: "",
      checkOutHour: "",
      checkOutMinute: "",
      comment: "",
      company: "",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_IN_MODULE.CHECK_IN:
    case CHECK_IN_MODULE.CHECK_OUT:
      return {
        ...state,
        checkInForm: {
          ...state.checkInForm,
          isSubmitting: true,
        },
      };
    case CHECK_IN_MODULE.CHECK_IN_SUCCESS:
    case CHECK_IN_MODULE.CHECK_OUT_SUCCESS:
      return {
        ...state,
        checkInForm: {
          ...state.checkInForm,
          isSubmitting: false,
        },
      };
    case CHECK_IN_MODULE.CHECK_IN_FAILURE:
    case CHECK_IN_MODULE.CHECK_OUT_FAILURE:
      return {
        ...state,
        checkInForm: {
          ...state.checkInForm,
          isSubmitting: false,
          submissionFaild: true,
        },
      };

    case CHECK_IN_MODULE.SET_CHECK_IN_MODULE_ID:
      return {
        ...state,
        checkInModuleId: action.payload,
      };

    case CHECK_IN_MODULE.GET_LAST_USED_COMPANY_SUCCESS:
      return {
        ...state,
        lastUsedCompanyName: action.payload,
        checkInForm: {
          ...state.checkInForm,
          formData: {
            ...state.checkInForm.formData,
            company: action.payload,
          },
        },
      };

    case CHECK_IN_MODULE.GET_CURRENT_CHECK_IN:
      return {
        ...state,
        currentCheckIn: {
          ...state.currentCheckIn,
          isLoading: true,
        },
      };

    case CHECK_IN_MODULE.GET_CURRENT_CHECK_IN_SUCCESS:
      return {
        ...state,
        currentCheckIn: {
          ...state.currentCheckIn,
          isLoading: false,
          hasError: false,
          data: action.payload,
        },
      };

    case CHECK_IN_MODULE.GET_CURRENT_CHECK_IN_FAILURE:
      return {
        ...state,
        currentCheckIn: {
          ...state.currentCheckIn,
          isLoading: false,
          hasError: true,
        },
      };

    case CHECK_IN_MODULE.UPDATE_FORM_DATA:
      return {
        ...state,
        checkInForm: {
          ...state.checkInForm,
          formData: {
            ...state.checkInForm.formData,
            [action.payload.name]: action.payload.value,
          },
        },
      };

    default:
      return state;
  }
}
