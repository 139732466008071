import React, { useState } from "react";
import { css } from "emotion";
import PropTypes from "prop-types";
import InlineSpinner from "./InlineSpinner";
import colors from "../../style/colors";
import styleTypes from "../../config/styleTypes";

function getColorModifier(styleType) {
  switch (styleType) {
    case styleTypes.ok:
      return "green";
    case styleTypes.neutral:
      return "blue";
    case styleTypes.error:
      return "red";
    default:
      return "";
  }
}

/** Generic button component to be used across our application.
 * @param {*} props
 * @param {string} props.styleType - The type of button. Supported types: `neutral|error|secondary`
 * @param {boolean} props.disabled - Makes the button disabled
 * @param {boolean} props.active - Shows a spinner inside the button
 * @param {function} props.onClick - Main event handler
 * @param {string} props['data-test-id'] - Test id
 * @param {string} props.title - DEPRECATION WARNING! May be removed! See examples. Title of the button
 * @param {Boolean} props.secondary - defaults to false. To render as primary or secondary button
 *
 * Previously we used props to control title and icons but have since opted in for using
 * the children prop, making it way more flexible.
 *
 * @example
 * ```jsx staticx
 * // Current usage
 * <Button>
 *   <TickIcon />
 *   Terminate
 * </Button>
 *
 *
 * // LEGACY USAGE! DONT DO THIS GOING FORWARD!
 * <Button
 *   iconLeft={<TickIcon style={{ marginRight: "0.5rem" }} />}
 *   title={"Terminate"}
 * />
 * ```
 */
const ButtonRounded = ({
  secondary = false,
  styleType,
  className,
  size,
  disabled,
  active,
  onClick,
  title,
  children,
  style,
  type = "button",
  "data-test-id": dataTestId,
}) => {
  const colorModifier = getColorModifier(styleType);

  return (
    <button
      className={`${componentStyles()} ${className || ""} size-${size || "default"} ${
        secondary ? "secondary" : "primary"
      } ${colorModifier}`}
      style={style}
      disabled={disabled ? disabled : false}
      onClick={!active ? onClick : null}
      data-test-id={dataTestId}
      type={type}
    >
      {/* Main content OR spinner */}
      {active ? (
        <InlineSpinner
          color={secondary === true ? "var(--primary-color)" : colors.white}
          size={size && size === "small" ? 14 : 24}
          style={{ marginTop: "-4px", marginBottom: "-10px" }}
        />
      ) : (
        <>
          {title || ""}
          {children && typeof children === "function" && children()}
          {children && typeof children === "object" && children}
          {children && typeof children === "string" && children}
        </>
      )}
    </button>
  );
};

const componentStyles = () => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0 1.5rem;
  transition: background-color 120ms ease;
  cursor: pointer;
  font-weight: 700;
  --outline: 0px;

  &.size-small {
    font-size: 0.95rem;
    height: 2rem;
    border-radius: 1rem;
    padding: 0 1.2rem;
  }

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: initial;
  }

  &.primary {
    background-color: var(--primary-color);
    color: var(--white);

    &:hover {
      background-color: var(--primary-color_lighten-5);
    }
    &:active {
      background-color: var(--primary-color_darken-5);
    }
    &:focus {
      outline: var(--outline, 0px);
    }

    &.green {
      background-color: var(--green);

      &:hover {
        background-color: var(--green-color_lighten-5);
      }
      &:active {
        background-color: var(--green-color_darken-5);
      }
    }

    &.red {
      background-color: var(--red);

      &:hover {
        background-color: var(--red-color_lighten-5);
      }
      &:active {
        background-color: var(--red-color_darken-5);
      }
    }
  }

  &.secondary {
    background-color: var(--primary-color_alpha-01);
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color_alpha-005);
    }
    &:active {
      background-color: var(--primary-color_alpha-015);
    }
    &:focus {
      outline: var(--outline, 0px);
    }

    &.green {
      background-color: var(--green-color_alpha-01);
      color: var(--green);

      &:hover {
        background-color: var(--green-color_alpha-005);
      }
      &:active {
        background-color: var(--green-color_alpha-015);
      }
    }

    &.red {
      background-color: var(--red-color_alpha-01);
      color: var(--red);

      &:hover {
        background-color: var(--red-color_alpha-005);
      }
      &:active {
        background-color: var(--red-color_alpha-015);
      }
    }
  }

  &:disabled {
    background-color: var(--lightGrey);
    color: ${colors.midDarkGrey};

    &:hover {
      cursor: not-allowed;
      background-color: var(--lightGrey);
    }
    &:active {
      background-color: var(--lightGrey);
    }
    &:focus {
      outline: var(--outline, 0px);
    }
  }
`;

export default ButtonRounded;

ButtonRounded.propTypes = {
  /** The id to use for testing. Is written as data-test-id="prop" to the DOM */
  "data-test-id": PropTypes.string,
  /** Describes what type of button to use, primary,secondary etc. */
  buttonType: PropTypes.string,
  /** Used for displaying neutral/error/Ok events on the button */
  styleType: PropTypes.string,
  /** Used for handling submitting/creation, for example set this =true when waiting for response on network request */
  active: PropTypes.bool,
  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,
  /** The function which is called when the button is clicked */
  onClick: PropTypes.func,
};
