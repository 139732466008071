import req from "../utilities/request-utility";
import { GET_USER_SYNC_LOG, GET_USER_SYNC_LOG_FAILURE, GET_USER_SYNC_LOG_SUCCESS } from "./actionTypes";

export function getUserSyncLog() {
  return async function (dispatch, getState) {
    const state = getState().userSync;

    dispatch({
      type: GET_USER_SYNC_LOG,
    });

    try {
      const { data: items } = await req()("user-sync/log/stats");

      dispatch({
        type: GET_USER_SYNC_LOG_SUCCESS,
        payload: {
          items,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_USER_SYNC_LOG_FAILURE,
        payload: {
          status: error.response.status,
        },
      });
      throw error;
    }
  };
}
