import React, { useEffect, useState } from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
import { format } from "date-fns";

// Components
import ActionWrapper from "../ui/ActionWrapper";

// Styles
import colors from "../../style/colors";
import Notification from "../ui/Notification";

const dayStates = {
  past: "past",
  today: "today",
  future: "future",
};

const ChristmasCalendarDoor = (props) => {
  // destructure props
  const { active, onClick, dayNumber, christmasMonth, alreadyAnswered } = props;
  const [dayState, setDayState] = useState("");

  useEffect(() => {
    // Check if we're in the christmas month
    const currentMonth = parseInt(format(new Date(), "MM"));
    if (christmasMonth !== currentMonth) return setDayState(dayStates.past);

    let today = format(new Date(), "d");
    if (dayNumber < today) {
      setDayState(dayStates.past);
    } else if (Number(dayNumber) === Number(today)) {
      setDayState(dayStates.today);
    } else {
      setDayState(dayStates.future);
    }
    // eslint-disable-next-line
  }, []);

  const notificationStyle =
    dayState === dayStates.today
      ? {
          position: "absolute",
          top: "calc(50% - 34px)",
          left: "calc(50% + 19px)",
        }
      : {
          position: "absolute",
          top: "calc(50% - 30px)",
          left: "calc(50% + 9px)",
        };

  return (
    <ActionWrapper
      className={`${componentStyle()} ${active ? "active" : "disabled"} ${dayState}`}
      onClick={() => (dayState === dayStates.today || dayState === dayStates.past ? onClick() : null)}
      style={{ borderRadius: 0 }}
    >
      <p>{props.dayNumber}</p>
      {active && !alreadyAnswered && <Notification notifications={1} style={notificationStyle} />}
    </ActionWrapper>
  );
};

const componentStyle = () => css`
  position: relative;
  width: 25%;
  height: calc((100vh - 50px) / 6);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  border-right: 1px solid ${tinycolor(colors.white).setAlpha(0.2).toRgbString()};
  background-color: transparent;
  cursor: pointer;
  border-collapse: collapse;

  &:nth-child(4n) {
    border-right: none;
  }

  &.disabled {
    touch-action: none;
    pointer-events: none;
    opacity: 0.5;
  }

  p {
    color: var(--white);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1.5rem;
    font-weight: 600;
  }

  &.${dayStates.today} {
    p {
      font-size: 3rem;
    }
  }

  &.${dayStates.past} {
    p {
      /* font-size: 3rem; */
    }
  }

  &.${dayStates.future} {
    p {
      opacity: 0.6;
      /* font-size: 3rem; */
    }
  }
`;

export default ChristmasCalendarDoor;
