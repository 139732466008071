// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce/lib";
import { CSSTransition } from "react-transition-group";
import { css } from "emotion";

// Actions
import { clearContacts, getContacts, setPhoneBookFiltersValue } from "../../../actions/contactsActions";

// Components
import ScrollView from "../../ui/ScrollView";
import ListItem from "../../ui/ListItem";
import ContactsMenuOptions from "./ContactsMenuOptions";
import { ContactsListItemSkeletonGroup } from "./skeleton/ContactsListItemSkeleton";
import FloatingSearchInput from "../../ui/FloatingSearchInput";
import StatusBox from "../../ui/StatusBox";
import ContactListContent from "./ContactListContent";

// Utilities
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import { getMiddleTitle } from "../utilities/getMiddleTitle";
import { getSubTitle } from "../utilities/getSubTitle";
import highlightMatch from "../../../utilities/highlight-match";

// Config
import contactsTypes from "../config/contactsTypes";
import breakpoints from "../../../config/breakpoints";
import contactTypes from "../config/contactsTypes";

// Styles
import colors from "../../../style/colors";
import { FeatureSearchOutlineIcon } from "mdi-react";

// Hooks
import useAuthorModal from "../../../hooks/useAuthorModal";
import usePostRenderEffect from "../../../hooks/usePostRenderEffect";

/**
 * @param {Object} props
 * @param {Boolean} props.isASubGroup - Helps deactivate the animation on the searchBar when the component acts as a overview of a specific group of contacts
 * @param {Object} props.mainPage - Provides the config and id and the mainPage.
 * The mainPage.config helps determine what information should be visible of users (ListItem-level)
 * The mainPage.id helps hiding information about the users before they reach the app (API-level)
 * @param {String} props.contactType - Determines what kind of contacts should be fetched -> (phoneBook or keyPersons)
 * @param {String} props.groupType - What are the groupType -> all, masterGroup, useGroup, groups (KeyPersons).
 * This also determines the layout:
 *  all -> all contacts
 *  masterGroup, userGroup and group -> group titles
 * @param {boolean} props.tabBarIsIncluded - Helps position the searchBar height by the tabBars position
 */

export function ContactsList({
  isASubGroup = false,
  mainPage,
  contactType = contactsTypes.phoneBook,
  groupType = null,
  tabBarIsIncluded = false,
  groupId = null,
}) {
  const dispatch = useDispatch();
  const authorModal = useAuthorModal();

  const { contacts, loading, error, endOfFeed } = useSelector((state) => state.contact[contactType]);
  const { activeTab, filters } = useSelector((state) => state.contact);
  const { language: lang } = useSelector((state) => state.language);
  const appConfig = useSelector((state) => state.appConfig);
  const [searchTerm, setSearchTerm] = useState("");

  const [debouncedGetContacts] = useDebouncedCallback(({ searchTerm }) => {
    dispatch(clearContacts({ contactType }));
    dispatch(
      getContacts({
        contactType: contactsTypes[contactType],
        searchTerm,
        groupType,
        groupId,
      })
    );
  }, 250);

  useEffect(() => {
    if (filters.selectedAppId) getInitialContacts();
  }, [filters.selectedAppId]);

  usePostRenderEffect(() => {
    debouncedGetContacts({ searchTerm });
  }, [searchTerm]);

  usePostRenderEffect(() => {
    if (contacts.length === 0 || searchTerm !== "") return;

    const identifier = `#${contactType}_identifier`;
    let currentFeed = document.querySelector(identifier);

    if (currentFeed && currentFeed.scrollHeight - currentFeed.scrollTop <= currentFeed.clientHeight + 100) {
      dispatch(
        getContacts({
          contactType: contactsTypes[contactType],
          searchTerm,
          groupType,
          groupId,
        })
      );
    }
  }, [contacts]);

  function showAuthorModal(contact) {
    if (contactType === contactsTypes.phoneBook) authorModal(contact.id);
    if (contactType === contactTypes.keyPerson) authorModal(contact.id, `contacts/key-persons/${contact.id}`);
  }

  function getInitialContacts() {
    /* current fix, not happy about it, but the contact module is overcomplicated, 
       so "this is the way" for now until we get some more time to rewrite the contact module.
    */
    if (filters.selectedAppId === null && contactType === contactTypes.keyPerson) {
      dispatch(setPhoneBookFiltersValue({ key: "selectedAppId", value: appConfig.appId }));
    }
    dispatch(clearContacts({ contactType }));
    dispatch(
      getContacts({
        contactType: contactsTypes[contactType],
        searchTerm,
        groupType,
        groupId,
      })
    );
  }

  if (error) {
    return (
      <div style={{ marginTop: "0.5rem" }}>
        <StatusBox />
      </div>
    );
  }

  return (
    <div className={componentStyle()}>
      <CSSTransition
        in={activeTab === 0 || activeTab === 1}
        timeout={900}
        mountOnEnter={true}
        unmountOnExit={true}
        classNames="floating-search-input"
      >
        <FloatingSearchInput
          style={{ top: `${tabBarIsIncluded ? 10 : 60}px` }}
          className="search-contacts-input"
          dataTestId={"inputField"}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          placeholder={lang.searchForContacts}
          onClearSearch={() => {
            setSearchTerm("");
          }}
        />
      </CSSTransition>

      <ScrollView
        id={`${contactType}_identifier`}
        className="contact-container"
        onScrollEnd={() =>
          dispatch(
            getContacts({
              contactType: contactsTypes[contactType],
              searchTerm,
              groupType,
              groupId,
            })
          )
        }
      >
        {endOfFeed && !loading && contacts.length === 0 && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
            style={{ margin: "2rem auto" }}
          />
        )}

        {loading && contacts.length === 0 && <ContactsListItemSkeletonGroup />}

        {contacts.map((contact) => (
          <ListItem
            // dataTestId={getDataTestIdForListItem({ contactType })}
            className="list-item"
            style={{ padding: "0.625rem" }}
            maxWidth={breakpoints.lg}
            key={contact.id}
            clickable={true}
            onClick={() => showAuthorModal(contact)}
            middleTitleStyle={{ fontSize: "0.8125rem", color: colors.black }}
            imageLeft={getProfilePicture(contact, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
            title={highlightMatch(contact.name, searchTerm)}
            // dataTestIdTitle={getDataTestIdForListItem({ contactType, type: dataTestTypes.title })}
            middleTitle={highlightMatch(getMiddleTitle({ contact, appConfig: appConfig }), searchTerm)}
            // dataTestIdmiddleTitle={getDataTestIdForListItem({ contactType, type: dataTestTypes.middleTitle })}
            subTitle={highlightMatch(
              getSubTitle({ contact, config: mainPage.config ? mainPage.config : null, appConfig: appConfig }),
              searchTerm
            )}
            // dataTestIdSubTitle={getDataTestIdForListItem({ contactType, type: dataTestTypes.subTitle })}
            content={<ContactListContent config={mainPage.config} contact={contact} searchTerm={searchTerm} />}
            iconRight={<ContactsMenuOptions {...contact} />}
          />
        ))}
      </ScrollView>
    </div>
  );
}

const componentStyle = () => css`
  height: 100%;
  overflow-x: auto;

  .search-contacts-input {
    position: absolute;
    top: 10px;
    z-index: 3;
  }

  .list-item {
    margin-bottom: -2px;

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .skills {
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact-container {
    height: 100%;
    padding-top: 4.35rem;
  }
`;
