// Libs
import React from "react";

// Components
import DropDown from "../../../../ui/dropDown/components/DropDown";

// Utilities
import {
  EVENTS,
  CHAT,
  NAVIGATION_GRID,
  NAVIGATION_LIST,
  NEWSFEED_SINGLE,
  NEWSFEED_TABS,
  REGISTRATION_MODULES,
  CONTACTS,
} from "../../../config/moduleTypes";
import useSWR from "swr";
import { swrFetcher } from "../../../../../utilities/swr-fetcher";

const ModuleTypeSelector = (props) => {
  let URL = `admin/pages/module-types`;
  if (!props.isCreateMode && props.group) URL += `?group=${props.group}`;

  const { data: sections, isValidating: loading } = useSWR(URL, swrFetcher);

  function onChangeHandler(e) {
    const module = e.target.value;

    // Only reset meta if the module changed isn't compatible with the previous module
    if (
      !(REGISTRATION_MODULES.includes(props.value) && REGISTRATION_MODULES.includes(module)) &&
      !([NAVIGATION_LIST, NAVIGATION_GRID].includes(props.value) && [NAVIGATION_LIST, NAVIGATION_GRID].includes(module)) &&
      !([NEWSFEED_TABS, NEWSFEED_SINGLE].includes(props.value) && [NEWSFEED_TABS, NEWSFEED_SINGLE].includes(module))
    ) {
      props.onChange({ target: { name: "meta", value: {} } });
    }

    if (CHAT === module) {
      props.onChange({ target: { name: "noTargetGroup", value: false } });
    }

    if (EVENTS === module) {
      props.onChange({ target: { name: "meta", value: { showAllEvents: true } } });
    }

    if (CONTACTS === module) {
      props.onChange({
        target: {
          name: "meta",
          value: {
            showKeyPersons: true,
            showUsers: true,
            usersDisplayFormat: "all",
            keyPersonsDisplayFormat: "all",
            shouldDisplayUserGroupForEntry: true,
            shouldDisplayCompetencesForEntry: false,
          },
        },
      });
    }

    props.onChange(e);
  }

  return (
    <DropDown
      data-test-id={"module-type-selector"}
      usePlaceholder={props.usePlaceholder}
      name={props.name}
      className={props.className}
      onChange={onChangeHandler}
      sections={sections}
      value={props.value}
      loading={loading}
      groupBy={"sections"}
    />
  );
};

export default ModuleTypeSelector;
