import markDownParser from "../../../../utilities/markdown-parser";
import z from "zod";

export const formatRegistrationToForm = (registration) => {
  return {
    title: registration?.title ?? "",
    title_fl: registration?.title_fl ?? "",
    description: registration?.description ?? "",
    description_fl: registration?.description_fl ?? "",
    autoPublish: registration?.autoPublish ?? false,
    questionAsAnswerTitleId: registration?.questionAsAnswerTitleId ?? null,
    mailGroupId: registration?.mailGroupId ?? null,
  };
};

export const registrationValidationSchema = z.object({
  title: z.string().min(1, "Dette er et påkrævet felt"),
  title_fl: z.string().optional(),
  description: z.string(),
  description_fl: z.string().optional(),
  autoPublish: z.boolean(),
  questionAsAnswerTitleId: z.number().nullable(),
  mailGroupId: z.number().nullable(),
});
