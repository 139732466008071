import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";
import { useDebouncedCallback } from "use-debounce";
import queryString from "query-string";
import { FeatureSearchOutlineIcon, CloseIcon, SearchIcon } from "mdi-react";

// Components
import ScrollView from "../ScrollView";
import InlineSpinner from "../InlineSpinner";
import SearchResultNewsPost from "./results/SearchResultNewsPost";
import SearchResultUser from "./results/SearchResultUser";
import SearchResultContact from "./results/SearchResultContact";
import SearchResultPage from "./results/SearchResultPage";
import SearchResultSocialPost from "./results/SearchResultSocialPost";
import SearchResultsRegistrationForm from "./results/SearchResultsRegistrationForm";
import SearchResultsRegistrationPost from "./results/SearchResultsRegistrationPost";
import FloatingSearchInput from "../FloatingSearchInput";
import Page from "../Page";
import TopBar from "../TopBar";
import ActionWrapper from "../ActionWrapper";
import StatusBox from "../StatusBox";

// Style
import breakpoints from "../../../config/breakpoints";

// Actions
import { setSearchTerm, clearAll, getResults, setSearchInSubPageId } from "../../../actions/searchPageActions";

// Utilities
import isElementScrollable from "../../../utilities/is-element-scrollable";
import pageNavigatorV2, { pageTransitions } from "../../../utilities/page-navigator-v2";
import SearchPageSectionFilters from "./SearchPageSectionFilters";

//CONFIG
import { filterTypes } from "./config/filterTypes";
import InformationBox from "../InformationBox";
import ButtonRounded from "../ButtonRounded";
import useCurrentPage from "../../../hooks/useCurrentPage";
import getAppName from "../../../utilities/get-app-name";

const SearchModalMain = (props) => {
  const dispatch = useDispatch();
  const { results, searchTerm, loading, error, endOfFeed, onlySearchInSubContentFromPageId } = useSelector(
    (s) => s.searchPage
  );

  const lang = useSelector((state) => state.language.language);
  const [debouncedGetResults] = useDebouncedCallback(getMoreResults, 500);
  const localSearchPage = useCurrentPage({ pageId: props?.match?.params?.pageId });

  useEffect(() => {
    let { scrollTo } = queryString.parse(window.location.search);
    let el = document.getElementById("el-search-page-scroll-view");
    if (!el) return;

    dispatch(setSearchInSubPageId({ pageId: props?.match?.params?.pageId }));

    el.scrollTo(0, scrollTo);
  }, []);

  useEffect(() => {
    debouncedGetResults();

    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    // If scrollview is not scrollable after new results try with more
    if (results.length > 0 && !isElementScrollable(document.getElementById("el-search-page-scroll-view"))) {
      getMoreResults();
    }
    // eslint-disable-next-line
  }, [results]);

  function getMoreResults() {
    if (!loading && !error) {
      dispatch(getResults());
    }
  }

  function getResultComponentByType(result) {
    switch (result.type) {
      // Contacts are what we call keypersons, where users are the other users of the app, and in that sense also contacts.
      case filterTypes.user:
        return <SearchResultUser result={result} key={`user-result__${result.data.id}`} />;
      case filterTypes.contact:
        return <SearchResultContact result={result} key={`contact-result__${result.data.id}`} />;
      case filterTypes.socialPost:
        return <SearchResultSocialPost result={result} key={`social-post__${result.data.id}__${result.page.id}`} />;
      case filterTypes.newsPost:
        return <SearchResultNewsPost result={result} key={`news-post__${result.data.id}}__${result.page.id}`} />;
      case filterTypes.page:
        return <SearchResultPage result={result} key={`page__${result.page.id}`} />;
      case filterTypes.registrationForm:
        return (
          <SearchResultsRegistrationForm result={result} key={`registration_form__${result.data.id}}__${result.page.id}`} />
        );
      case filterTypes.registrationPost:
        return (
          <SearchResultsRegistrationPost result={result} key={`registration_post__${result.data.id}}__${result.page.id}`} />
        );

      default:
        return null;
    }
  }

  return (
    <Page>
      <TopBar
        title={lang.search}
        actionLeft={
          <ActionWrapper onClick={() => pageNavigatorV2({ mode: "pop", direction: pageTransitions.modalPageDown })}>
            <CloseIcon />
          </ActionWrapper>
        }
      />
      <div className={componentStyles()}>
        <FloatingSearchInput
          style={{ left: 0, position: "relative", padding: "1.5rem", top: "2px", margin: "auto" }}
          searching={loading}
          value={searchTerm}
          onClearSearch={() => dispatch(clearAll())}
          onChange={(e) => dispatch(setSearchTerm(e.target.value))}
          autoFocusOnRender={true}
        />

        <SearchPageSectionFilters />

        {/* content */}
        <ScrollView onScrollEnd={getMoreResults} className="scroll-view" id="el-search-page-scroll-view">
          {onlySearchInSubContentFromPageId && (
            <InformationBox
              style={{
                maxWidth: `${breakpoints.lg}px`,
                margin: "1rem auto",
                width: "calc(100% - 2rem)",
                backgroundColor: "var(--white)",
              }}
              title={lang.localSearch}
              icon={<FeatureSearchOutlineIcon />}
            >
              <p>{lang.infobox_localsearch_content__withPlaceholder.replace("{{placeholder}}", localSearchPage.title)}</p>
              <ButtonRounded
                size="small"
                secondary={true}
                style={{ marginTop: "0.5rem" }}
                onClick={() => pageNavigatorV2({ path: `/${getAppName()}/search`, direction: pageTransitions.modalPageUp })}
              >
                {lang.searchInTheEntireApp}
              </ButtonRounded>
            </InformationBox>
          )}

          {results.map((result) => getResultComponentByType(result))}
          {/* Loading */}
          {loading && <InlineSpinner style={{ margin: "2rem auto" }} />}

          {/* Status box */}
          {endOfFeed && (
            <StatusBox
              icon={<FeatureSearchOutlineIcon />}
              title={results.length > 0 ? lang.noMoreResults : lang.noResults}
              content={results.length > 0 ? lang.endOfResults : lang.trySearchingForSomethingElse}
              style={{ margin: "2rem auto" }}
            />
          )}

          {/* Status box */}
          {!loading && !error && !searchTerm && results.length === 0 && !endOfFeed && (
            <StatusBox
              icon={<FeatureSearchOutlineIcon />}
              title={lang.startTypingToSearch}
              content={lang.searchPossibilities}
              style={{ margin: "2rem auto" }}
            />
          )}
        </ScrollView>
      </div>
    </Page>
  );
};

const componentStyles = (props) => css`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  max-height: calc(100% - 50px);
  display: flex;
  flex-direction: column;

  .scroll-view {
    /* Page height minus searchbar and filters */
    max-height: 100%;

    @media screen and (min-width: ${breakpoints.lg}px) {
      padding-top: 0.5rem;
    }
  }

  .list-element {
    background-color: var(--white);
    border-top: 1px var(--midGrey) solid;
    border-bottom: 1px var(--midGrey) solid;
    padding: 1rem;
    margin-top: -1px;
    position: relative;
    cursor: pointer;
    transition: background-color 120ms ease;

    @media screen and (min-width: ${breakpoints.lg}px) {
      max-width: ${breakpoints.lg}px;
      margin: -1px auto 0px auto;
      border: 1px var(--midGrey) solid;

      &:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-of-type(1) {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &:hover {
      background-color: var(--ultraLightGrey);
      transition: background-color 50ms ease;

      .left-side-gradient {
        opacity: 0;
      }
    }

    &:active {
      background-color: var(--lightGrey);
      transition: background-color 80ms ease;
    }

    svg.navigation-arrow-indicator {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem;
      height: 1.25rem;
      color: var(--darkGrey);
    }

    p.title {
      font-size: 1.15rem;
      margin-bottom: 0.45rem;

      span.match {
        font-weight: bold;
      }
    }

    div.content {
      color: var(--black);
      overflow: hidden;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span.match {
        font-weight: bold;
      }
    }
  }
`;

export default SearchModalMain;
