// Libs
import React, { Fragment, useEffect } from "react";
import { css } from "emotion";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import ListItem from "../ui/ListItem";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Config and utilities
import breakpoints from "../../config/breakpoints";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import getUserGroupTitle from "../../utilities/get-user-group-title";

// Icons
import { AlertOctagramIcon, InfoOutlineIcon, TrashCanOutlineIcon } from "mdi-react";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

// Actions
import { showContextMenu, showDialog, showModalPage, updateModalPage } from "../../actions/uiActions";
import { deleteAttendEvent, getEventAttendees } from "../../actions/eventActions";

import colors from "../../style/colors";
import DynamicIcon from "../ui/Icons/DynamicIcon";
import ContextMenuButton from "../ui/ContextMenuButton";
import styleTypes from "../../config/styleTypes";
import isUserAdmin from "../../utilities/is-user-admin";

function AttendeeListItemDetail({ detail }) {
  return (
    <div className={detailStyle()}>
      {detail.icon && <DynamicIcon icon={detail.icon} />}
      {detail.text}
    </div>
  );
}

const detailStyle = () => css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  padding: 0.25rem 0.5rem;

  border-radius: 5px;

  color: ${colors.darkGrey};
  font-size: 0.75rem;
`;

/**
 * Shows a list of users that are attending the event
 *
 * @returns {*}
 * @constructor
 */
const EventAttendeesList = (props) => {
  // Language
  const { language: lang } = useSelector((state) => state.language);

  // Event from redux store
  const { selectedEvent: event, loadingAttendees, errorAttendees } = useSelector((state) => state.event);

  // To get the users information when clicked on the row
  const authorModal = useAuthorModal(props.showModalPage, props.updateModalPage);

  const dispatch = useDispatch();

  // ComponentDidMount - gets the attendees
  useEffect(() => {
    props.getEventAttendees({ eventId: event.id });
    // eslint-disable-next-line
  }, []);

  function onContextMenuOpen(e, attendeeId) {
    e.stopPropagation();

    const items = [
      {
        icon: <TrashCanOutlineIcon />,
        title: lang.eventRemoveParticipantDialogTitle,
        callback: () => {
          dispatch(
            showDialog({
              title: lang.eventRemoveParticipantDialogTitle,
              content: lang.eventConfirmRemoveAttendee,
              primaryActionTitle: lang.eventRemoveParticipantDialogConfirmButtonText,
              primaryAction: () => {
                dispatch(deleteAttendEvent({ eventId: event.id, attendeeId }));
              },
              secondaryActionTitle: lang.cancel,
              styleType: styleTypes.error,
              icon: <AlertOctagramIcon />,
            })
          );
        },
      },
    ];

    dispatch(showContextMenu(items));
  }

  return (
    <div className={scrollViewStyle()}>
      {/* Loading */}
      {loadingAttendees && !errorAttendees && <Spinner className="inline-spinner" />}
      {/* Error */}
      {!loadingAttendees && errorAttendees && <StatusBox />}
      {/* No attendees */}
      {!loadingAttendees && !errorAttendees && event.attendees && event.attendees.length === 0 && (
        <StatusBox className="status-box" icon={<InfoOutlineIcon />} title={lang.noAttendeesYet} content={" "} />
      )}
      {/* Attendees content*/}
      {!loadingAttendees && !errorAttendees && event.attendees && event.attendees.length > 0 && (
        <Fragment>
          <div className="top-spacing-container" />
          {event.attendees.map((attendee) => (
            <ListItem
              key={`attendee-event-${attendee.user.id}`}
              maxWidth={breakpoints.md}
              dataTestIdTitle={"eventAttendeeName"}
              clickable={true}
              onClick={() => authorModal(attendee.user.id)}
              imageLeft={getProfilePicture(attendee.user, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
              title={attendee.user.name}
              subTitle={getUserGroupTitle(attendee.user)}
              contentRight={
                <div className="action-right">
                  {attendee.detail !== undefined && <AttendeeListItemDetail detail={attendee.detail} />}
                  {isUserAdmin() && <ContextMenuButton onClick={(e) => onContextMenuOpen(e, attendee.user.id)} />}
                </div>
              }
            />
          ))}
        </Fragment>
      )}
    </div>
  );
};

const scrollViewStyle = () => css`
  div.top-spacing-container {
    height: 1rem;
  }

  .status-box {
    margin: 1rem 0;
  }

  .inline-spinner {
    margin: 1rem 0;
  }

  .action-right {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getEventAttendees: bindActionCreators(getEventAttendees, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(EventAttendeesList);
