// Libs
import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";
// Utilities and config
import getPageFromId from "../../utilities/get-page-from-id";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import breakpoints from "../../config/breakpoints";
import { eventTypes } from "./config";

// Styles
import { InfoOutlineIcon } from "mdi-react";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import StatusBox from "../ui/StatusBox";
import EventListItem from "./EventListItem";
import InlineSpinner from "../ui/InlineSpinner";

// Actions
import { getEvents, resetEvents } from "../../actions/eventActions";
import * as queryString from "query-string";

/**
 * The entry component for events. There is 2 tabs, where the 'all'-tab shows all events, and the 'attending'-tab shows
 * the events you're attending
 *
 * @param props
 * @param props.getEvents({eventType: 'all'}) - gets all the events
 * @param props.getEvents({eventType: 'attending'}) - gets all the events you're attending
 * @param props.resetEvents() - removes all the events from the redux store
 * @returns {*}
 * @constructor
 */

const EventOverview = (props) => {
  // Page setup
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  let { dataId: groupId } = queryString.parse(window.location.search) || undefined;

  // Language
  const { language: lang } = useSelector((state) => state.language);

  // UI Layout
  const tabs = [lang.firstTabInEventOverview, lang.attending];
  const [activeTab, setActiveTab] = useState(0);

  // All events
  const {
    error: allError,
    events: allEvents,
    loading: allLoading,
    endOfFeed: allEndOfFeed,
  } = useSelector((state) => state.event.all);

  // Attending events
  const {
    error: attendingError,
    events: attendingEvents,
    loading: attendingLoading,
    endOfFeed: attendingEndOffFeed,
  } = useSelector((state) => state.event.attending);

  // Gets the events
  useEffect(() => {
    props.resetEvents();
    requestAnimationFrame(() => {
      props.getEvents({ eventType: eventTypes.all, groupId });

      // These endpoints are a little heavy so lets fetch one at a time
      setTimeout(() => {
        props.getEvents({ eventType: eventTypes.attending, groupId });
      }, 1500);
    });
    // eslint-disable-next-line
  }, []);

  // Check if scrollView is completed
  useLayoutEffect(() => {
    isScrollViewFilled();
  }, [allEvents]);

  // load more events when scrolled to bottom of window
  const currentTabOnScrollEvent = () => {
    if (activeTab === 0) props.getEvents({ eventType: eventTypes.all, groupId });
    if (activeTab === 1) props.getEvents({ eventType: eventTypes.attending, groupId });
  };

  function isScrollViewFilled() {
    setTimeout(() => {
      let currentFeed = document.querySelector(".scroll-view.tab");
      if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight) currentTabOnScrollEvent();
    }, 100); // wait for render... I know this is a bit hacky but the action doesn't have to fire immedietly
  }

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => setActiveTab(tabIndex),
        }))}
      />

      <TabView
        activeTabIndex={activeTab}
        onScrollEnd={() => currentTabOnScrollEvent()}
        tabs={[
          <div className={componentStyle()}>
            {/* ALL EVENTS */}
            <div className="top-spacing-container" />
            {/* All events - Content */}
            {allEvents.map((event) => (
              <EventListItem
                key={`event-overview-all-${event.id}`}
                onClick={() => {
                  pageNavigatorV2({ path: `${match.url}/${event.id}`, direction: "forward", pageId: page.id });
                  props.resetEvents();
                }}
                event={event}
                maxWidth={breakpoints.md}
                clickable={true}
              />
            ))}

            {/* All events - Loading */}
            {allLoading && !allError && <InlineSpinner className="inline-spinner" title={lang.loading} />}

            {/* All events - Error */}
            {!allLoading && allError && <StatusBox />}

            {/* All events - End of feed without events */}
            {!allLoading && !allError && allEndOfFeed && allEvents.length === 0 && (
              <StatusBox
                className="status-box"
                icon={<InfoOutlineIcon />}
                title={lang.noMoreEvents}
                content={lang.comeBackSoon}
              />
            )}
            {/* All events - End of feed with events */}
            {!allLoading && !allError && allEndOfFeed && allEvents.length > 0 && (
              <StatusBox
                className="status-box"
                icon={<InfoOutlineIcon />}
                title={lang.noMoreEvents}
                content={lang.reachedEndOfFeed}
              />
            )}
          </div>,
          <div className={componentStyle()}>
            {/* ATTENDING EVENTS */}
            <div className="top-spacing-container" />
            {/* Attending events - Content */}
            {attendingEvents.map((event) => (
              <EventListItem
                key={`event-overview-attending-${event.id}`}
                onClick={() => {
                  pageNavigatorV2({ path: `${match.url}/${event.id}`, direction: "forward", pageId: page.id });
                  props.resetEvents();
                }}
                event={event}
                maxWidth={breakpoints.md}
                clickable={true}
              />
            ))}

            {/* Attending events - Loading */}
            {attendingLoading && !attendingError && <InlineSpinner className="inline-spinner" title={lang.loading} />}

            {/* Attending events - Error */}
            {!attendingLoading && attendingError && <StatusBox />}

            {/* Attending events - End of feed without events */}
            {!attendingLoading && !attendingError && attendingEndOffFeed && attendingEvents.length === 0 && (
              <StatusBox
                className="status-box"
                icon={<InfoOutlineIcon />}
                title={lang.notAttendingAnyEvents}
                content={lang.findEventsInOtherTab}
              />
            )}
            {/* Attending events - End of feed with events */}
            {!attendingLoading && !attendingError && attendingEndOffFeed && attendingEvents.length > 0 && (
              <StatusBox
                className="status-box"
                icon={<InfoOutlineIcon />}
                title={lang.noMoreEvents}
                content={lang.reachedEndOfFeed}
              />
            )}
          </div>,
        ]}
      />
    </Page>
  );
};

const componentStyle = () => css`
  .top-spacing-container {
    height: 1rem;
  }

  .status-box {
    margin: 1rem 0;
  }

  .inline-spinner {
    margin: 1rem 0;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  resetEvents: bindActionCreators(resetEvents, dispatch),
  getEvents: bindActionCreators(getEvents, dispatch),
});

export default connect(null, mapDispatchToProps)(EventOverview);
