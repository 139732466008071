// Libs
import React, { Fragment, useEffect, useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Components
import { ErrorOutlineIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import FormBuilder from "../ui/FormBuilder";
import Button from "../ui/Button";
import Translator from "../ui/Translator";

// Utilities and config
import breakpoints from "../../config/breakpoints";
import { attendingType } from "./config";
import translationTypes from "../../config/translationTypes";

// Actions
import { addToast, hideModalPage } from "../../actions/uiActions";
import { attendEvent, resetEvent } from "../../actions/eventActions";

// Hooks
import useTranslation from "../../hooks/useTranslation";

// Icons
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import DynamicIcon from "../ui/Icons/DynamicIcon";

const boxPadding = 0.75;

/**
 * Will be shown when the user needs to answer some questions before they can attend the event.
 * This is here the user answers the event questions with exception for the mainQuestion, which is if
 * the user will attend the event. That question is answered when attend the event.
 *
 * @param props
 * @param props.attendEvent({eventId, fields}) -> the fields are the answered questions
 * @returns {*}
 * @constructor
 */

const EventFormBuilder = (props) => {
  const dispatch = useDispatch();

  // Event from redux store
  const { selectedEvent: event } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);
  const { language: lang } = useSelector((state) => state.language);
  const appConfig = useSelector((state) => state.appConfig);

  // Questions and answers for the event
  const [questions, setQuestions] = useState(event.questions);
  const [notValidArr, setNotValidArr] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Answer the questions
  function updateFields(value, index) {
    const answer = "answer";
    setQuestions([
      ...questions.map((question, questionIndex) => (questionIndex === index ? { ...question, [answer]: value } : question)),
    ]);
  }

  function getIdsForEmptyRequiredQuestions() {
    return questions
      .filter((question) => question.isRequired === 1 && question.answer && !question.answer.title)
      .map((question) => question.id);
  }

  // Submit answer
  function submitAnswer() {
    let idsOfQuestionsNotFilled = getIdsForEmptyRequiredQuestions();

    setNotValidArr(idsOfQuestionsNotFilled);

    if (idsOfQuestionsNotFilled.length !== 0) {
      return dispatch(
        addToast({
          title: lang.missingFields,
          content: lang.fillAllFields,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }

    if (event.config?.EVENT_ATTENDEE_COUNT_STRATEGY === "UNIT" && event.config?.EVENT_ATTENDEE_COUNT_UNIT_QUESTION_ID) {
      const question = questions.find((question) => question.id === event.config.EVENT_ATTENDEE_COUNT_UNIT_QUESTION_ID);

      if (question?.answer?.title > event.seatsAvailable - event.numberOfAttendees) {
        setNotValidArr([event.config.EVENT_ATTENDEE_COUNT_UNIT_QUESTION_ID]);
        return dispatch(
          addToast({
            title: lang.eventTooManyAttendeesError,
            content: lang.eventCouldNotRegisterMoreAttendeesThanTheAmountOfRemainingSeatsError,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
      }
    }

    // Add the mainQuestion to the questions
    questions.push({ ...event.mainQuestion, answer: { title: attendingType.YES } });
    dispatch(hideModalPage());
    dispatch(attendEvent({ eventId: event.id, fields: questions, attendeeId: user.id }));
  }

  useEffect(() => {
    if (questions && notValidArr.length !== 0) setNotValidArr(getIdsForEmptyRequiredQuestions());
  }, [questions]);

  const {
    translateContent,
    isTranslated,
    isTranslating,
    currentTranslationLanguage,
    title: translatedTitle,
    content: translatedQuestions,
  } = useTranslation({
    type: translationTypes.events,
    args: { eventId: event.id },
  });

  useEffect(() => {
    if (translatedQuestions) setQuestions([...translatedQuestions]);
  }, [translatedQuestions]);

  return (
    <ScrollView className={componentStyle()}>
      <div className="container">
        {appConfig.enableTranslation && (
          <div style={{ display: "flex", alignItems: "right", justifyContent: "end" }}>
            <Translator
              style={{ marginTop: "0.5rem", marginLeft: "0.125rem", marginBottom: "0.5rem" }}
              isTranslated={isTranslated}
              isTranslating={isTranslating}
              onTranslate={translateContent}
              secondaryStyle={true}
            />
          </div>
        )}
        {questions && (
          <Fragment>
            {questions.map((question, questionIndex) => (
              <FormBuilder
                showEventsReadAloud={true}
                disabledSubmitBtn={() => setButtonDisabled(true)}
                enableSubmitBtn={() => setButtonDisabled(false)}
                key={`event-form-question-${questionIndex}`}
                style={{ marginBottom: "1.5rem" }}
                question={question}
                notValidArr={notValidArr}
                questionIndex={questionIndex}
                updateFields={updateFields}
                enableReadAloud={appConfig.enableReadAloud}
                eventId={event.id}
                currentTranslationLanguage={currentTranslationLanguage}
                infoText={
                  event.config?.EVENT_ATTENDEE_COUNT_UNIT_QUESTION_ID == question.id && (
                    <>{lang.eventSeatsRemaining.replace("{{remaining}}", event.seatsAvailable - event.numberOfAttendees)}</>
                  )
                }
              />
            ))}
          </Fragment>
        )}

        <Button disabled={buttonDisabled} className="primary-btn" onClick={() => submitAnswer()}>
          {lang.confirmAttendance}
        </Button>
        <Button buttonType={"secondary"} onClick={() => dispatch(hideModalPage())}>
          {lang.cancel}
        </Button>
      </div>
    </ScrollView>
  );
};

const componentStyle = () => css`
  background-color: var(--white);
  width: 100%;
  height: 100%;
  overflow: auto;

  .primary-btn {
    margin: 2rem 0 0.5rem 0;
  }

  .container {
    background-color: var(--white);
    max-width: ${breakpoints.md}px;
    margin: auto;
    padding: ${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      padding-top: ${boxPadding * 2}rem;
    }
  }
`;

export default EventFormBuilder;
