/**
 * For performance improvement, likes may not be available everywhere.
 * Therefore isLikedbyUser is provided when likes isn't available.
 * If likes isn't provided fall back is isLikedByUser
 */

export default function ({ user, likes, isLikedByUser }) {
  if (typeof isLikedByUser === "boolean" && likes.length === 0) return isLikedByUser;

  if (!likes || likes.length === 0) return false;

  if (likes.some((like) => like && like.id === user.id)) {
    return true;
  } else {
    return false;
  }
}
