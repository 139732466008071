// Libs
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// Utilities
import req from "../utilities/request-utility";

// Actions
import { addToast } from "../actions/uiActions";

// Config
import readAloudTypes from "../config/readAloudTypes";

const useReadAloud = ({ type = readAloudTypes.newsPost, args = {} }) => {
  const dispatch = useDispatch();

  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const audioElement = useRef();

  function readAloudContent({ currentTranslationLanguage }) {
    if (isLoadingAudio) return;

    if (!isPlayingAudio) {
      setIsLoadingAudio(true);
      audioElement.current = new Audio();

      if (type === readAloudTypes.newsPost) {
        const { postId, subTypeId } = args;
        readAloud({ url: `news/${subTypeId}/${postId}/audio/${currentTranslationLanguage}` });
      }

      if (type === readAloudTypes.newsCheckQuestion) {
        const { quizId, questionId, orderOfAnswers } = args;
        readAloud({
          url: `quiz/${quizId}/questions/${questionId}/audio/${currentTranslationLanguage}?orderOfAnswers=${orderOfAnswers}`,
        });
      }
      if (type === readAloudTypes.registrationPost) {
        const { registrationId, postId } = args;
        readAloud({ url: `registration/${registrationId}/${postId}/audio/${currentTranslationLanguage}` });
      }

      if (type === readAloudTypes.events) {
        const { eventId, questionId } = args;
        readAloud({ url: `events/${eventId}/questions/${questionId}/audio/${currentTranslationLanguage}` });
      }

      if (type === readAloudTypes.socialOrChatPost) {
        const { dataId, postId } = args;
        readAloud({ url: `social/${dataId}/posts/${postId}/audio/${currentTranslationLanguage}` });
      }

      if (type === readAloudTypes.registrationFormQuestion) {
        const { registrationId, questionId } = args;
        readAloud({ url: `registration/${registrationId}/questions/${questionId}/audio/${currentTranslationLanguage}` });
      }

      if (type === readAloudTypes.registrationTitleAndDescription) {
        const { registrationId, includeDescription } = args;

        readAloud({
          url: `registration/${registrationId}/audio/${currentTranslationLanguage}?includeDescription=${includeDescription}`,
        });
      }

      if (type === readAloudTypes.registrationCategoryTitle) {
        const { registrationId, categoryId } = args;

        readAloud({
          url: `registration/${registrationId}/categories/${categoryId}/audio/${currentTranslationLanguage}`,
        });
      }
    } else {
      audioElement.current.pause();
      setIsPlayingAudio(false);
    }
  }

  function readAloud({ url }) {
    req()(url)
      .then(({ data }) => {
        audioElement.current.setAttribute("src", data.file);
        audioElement.current.load();

        // Add error listener to get detailed error info
        audioElement.current.addEventListener("error", (e) => {
          console.error("Audio error:", audioElement.current.error);
          console.error("Error code:", audioElement.current.error.code);
          console.error("Error message:", audioElement.current.error.message);
        });

        audioElement.current.play();
        audioElement.current.addEventListener("canplay", () => {
          audioElement.current.play();
          setIsLoadingAudio(false);
          setIsPlayingAudio(true);
        });
        audioElement.current.addEventListener("ended", () => {
          setIsLoadingAudio(false);
          setIsPlayingAudio(false);
        });
      })
      .catch((error) => {
        setIsLoadingAudio(false);
        setIsPlayingAudio(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  useEffect(() => {
    return () => {
      if (audioElement && audioElement.current) {
        try {
          audioElement.current.pause();
          audioElement.current.remove();
          audioElement.current = undefined;
        } catch {}
      }
    };
  }, []);

  return {
    isPlayingAudio,
    isLoadingAudio,
    readAloudContent,
  };
};

export default useReadAloud;
