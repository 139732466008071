import { filterTypes } from "../config/filterTypes";

export const generateIncludesArray = ({
  includeContacts,
  includeNews,
  includeSocial,
  includePages,
  includeRegistrations,
}) => {
  const includes = [];

  // For the user, contacts contains both users and keypersons (keypersons we here refer to as contacts)
  if (includeContacts) includes.push(filterTypes.contact, filterTypes.user);
  if (includeSocial) includes.push(filterTypes.socialPost);
  if (includeNews) includes.push(filterTypes.newsPost);
  if (includePages) includes.push(filterTypes.page);
  if (includeRegistrations) includes.push(filterTypes.registrationForm, filterTypes.registrationPost);

  return includes;
};
